var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('apexchart', {
    attrs: {
      "type": _vm.chart,
      "height": _vm.height,
      "options": _vm.options,
      "series": _vm.series
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }