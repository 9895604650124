import API from '../plugins/http';
import Utils from '../plugins/util';

const DiscountLog = {
  namespaced: true,
  state: {
    totalRows: 0,
    isLoading: false,
    isError: false,
    items: [],
    item: {},
    errorMessage: '',
    successMessage: '',
  },
  getters: {},
  actions: {
    async fetchDiscountLog({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `discounts-offer-log?page=${props.page}&limit=${props.limit}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchDiscountLogById({ commit }, id) {
      try {
        commit('setLoading', true);
        const response = await API.get(`discounts-offer-log/${id}`);
        const data = response.data.data;
        commit('setItem', data);
        commit('setLoading', false);
        return data;
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        commit('setError', true);
        if (response.status == 404) {
          commit('setErrorMessage', 'offer not found');
        } else {
          commit('setErrorMessage', 'Internal Server Error');
        }
      }
    },
    async searchDiscountLog({ commit }, props) {
      commit('setError', false);
      try {
        const q = Utils.encodeUrlWithBase64(props.q);
        const currentPage = props?.page ?? 1;
        const currentLimit = props?.limit ?? 20;
        const by = props?.by ?? 'DESC';
        const objEndpointSearchMode = {
          "1": '/search/',
          "2": '/discount-name/search/',
        };
        const response = await API.get(`discounts-offer-log${objEndpointSearchMode[props.mode]}${q}?page=${currentPage}&limit=${currentLimit}&by=${by}`);
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
  },
  mutations: {
    setItems(state, data) {
      state.items = data;
    },
    setItem(state, data) {
      state.item = data;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
  },
};

export default DiscountLog;
