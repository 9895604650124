import API from '../plugins/http';

const Currency = {
  namespaced: true,
  state: {
    totalRows: 0,
    isLoading: false,
    isError: false,
    items: [],
    item: {},
    errorMessage: '',
    successMessage: '',
  },
  getters: {
  },
  actions: {
    async fetchCurrency({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `autocomplete-currencies?page=${props.page}&limit=${props.limit}`
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchCurrencyById({ commit }, props) {
      try {
        const response = await API.get(
          `autocomplete-currencies/${props.id}`
        );
        commit('setItems', [response.data.data]);
        commit('setItem', response.data.data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        const response = error.response;
        commit("setLoading", false);
        commit("setError", true);
        if (response.status == 404) {
          commit('setErrorMessage', 'currency not found');
        } else {
          commit('setErrorMessage', 'Internal Server Error');
        }

      }
    },
    async searchCurrency({ commit }, props) {
      commit('setError', false);
      try {
        const response = await API.get(
          `autocomplete-currencies/search/${props.q}`
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async AutocompleteSearch({ commit }, state) {
      try {
        commit('setLoading', true);
        commit('setItems', []);
        const response = await API.get(`autocomplete-currencies/search/${state.q}`);
        commit('setItems', response.data.data);
        commit('setRows', response.data.data);
        commit('setLoading', false);
        return response;
      } catch (error) {
        let response = error.response;
        commit('setError', true);
        if (response.status == 403) {
          let err = response.data.message;
          commit('setErrorMessage', err);
        } else {
          commit('setErrorMessage', error.response);
        }
        return error;
      }
    },
    async postCurrency({ commit }, payload) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.post(`autocomplete-currencies`, payload);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success Created');
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
    async updateCurrency({ commit }, payload) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.put(`autocomplete-currencies/${payload.id}`, payload.field);
        if (response.status == 201) {
          commit('setSuccessMessage', "Success Updated");
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
      } catch (error) {
        console.log(error.message);
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setItems(state, data) {
      state.items = data;
    },
    setItem(state, data) {
      state.item = data;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
  },
};

export default Currency;
