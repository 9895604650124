const FinanceReportPath = {
  path: '/finance-report',
  name: 'financeReport',
  component: () => import('../views/finance/report.vue'),
};

const FinanceReportDetailPath = {
  path: '/finance-report/:id',
  name: 'financeReportDetail',
  component: () => import('../views/finance/detail.vue'),
};

const ReportCampaignPath = {
  path: '/reports/campaign',
  name: 'reportCampaign',
  component: () => import('../views/reports/campaign.vue'),
};

const ReportSingleSalesPath = {
  path: '/reports/single-sales',
  name: 'reportSingleSales',
  component: () => import('../views/reports/singleVendor.vue'),
};
const ReportSingleSummaryPath = {
  path: '/reports/single-summary',
  name: 'reportSingleSummary',
  component: () => import('../views/reports/singleSummary.vue'),
};
const ReportBuffetsPath = {
  path: '/reports/buffets',
  name: 'reportBuffets',
  component: () => import('../views/reports/buffet.vue'),
};
const ReportBuffetsSummaryPath = {
  path: '/reports/buffets-summary',
  name: 'reportBuffetsSummary',
  component: () => import('../views/reports/BuffetSummary.vue'),
};

const ReportSalesPath = {
  path: '/reports/sales',
  name: 'reportSales',
  component: () => import('../views/reports/sales.vue'),
};
const ReportSalesByPaymentPath = {
  path: '/reports/sales-by-payment',
  name: 'reportSalesByPayment',
  component: () => import('../views/reports/payment.vue'),
};
const ReportSubscriptionSummaryPath = {
  path: '/reports/subscription-summary',
  name: 'reportSubscriptionSummary',
  component: () => import('../views/reports/subscriptionSummary.vue'),
};
const SalesSubscriptionByvendorPath = {
  path: '/reports/subscribe-by-vendor',
  name: 'salesSubscriptionByvendor',
  component: () => import('../views/reports/SubscriptionByvendor.vue'),
};
const ReportBundleSummaryPath = {
  path: '/reports/bundle-summary',
  name: 'reportBundleSummary',
  component: () => import('../views/reports/BundleSummary.vue'),
};
const DiscountUsagePath = {
  path: '/reports/discount-usage',
  name: 'discountUsage',
  component: () => import('../views/discounts/usage.vue'),
};

const ReportContentPath = {
  path: '/content-report',
  name: 'contentReport',
  component: () => import('../views/reports/content.vue'),
};

const PayloadSo365Path = {
  path: '/reports/so-365',
  name: 'So365',
  component: () => import('../views/monitoring/generateSo365.vue'),
};

const PayloadPo365Path = {
  path: '/reports/po-365',
  name: 'Po365',
  component: () => import('../views/monitoring/generatePo365.vue'),
};

const ReportInvoicePath = {
  path: '/invoice-report',
  name: 'invoiceReport',
  component: () => import('../views/reports/invoice.vue'),
};

const ReportInvoiceSinglePath = {
  path: '/invoice-report/:id',
  name: 'invoiceReportSingle',
  component: () => import('../views/reports/invoiceSingle.vue'),
};

const ReportInvoiceSingleDetailPath = {
  path: '/invoice-report/:id/detail/:detailId',
  name: 'invoiceReportSingleDetail',
  component: () => import('../views/reports/invoiceSingleDetail.vue'),
};

module.exports = {
  FinanceReportPath,
  FinanceReportDetailPath,
  ReportCampaignPath,
  ReportSingleSalesPath,
  ReportBuffetsPath,
  ReportBuffetsSummaryPath,
  ReportSingleSummaryPath,
  ReportSalesPath,
  ReportSalesByPaymentPath,
  ReportSubscriptionSummaryPath,
  SalesSubscriptionByvendorPath,
  ReportBundleSummaryPath,
  DiscountUsagePath,
  ReportContentPath,
  PayloadSo365Path,
  PayloadPo365Path,
  ReportInvoicePath,
  ReportInvoiceSinglePath,
  ReportInvoiceSingleDetailPath,
};
