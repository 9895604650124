import API from '../plugins/http';
const reviews = {
  namespaced: true,
  state: {
    items: [],
    revenue: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
  },
  getters: {
    reviews: (state) => state.items,
    revenue: (state) => state.revenue,
  },
  actions: {
    async fetchReviews({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `review?&page=${state.page}&limit=${state.limit}&visible=${state.visible}`,
        );

        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setRevenue(state, item) {
      state.revenue = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
  },
};

export default reviews;
