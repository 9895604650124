import API from '../plugins/http';
import Utils from '../plugins/util';

const AccessCompany = {
  namespaced: true,
  state: {
    totalRows: 0,
    isLoading: false,
    isError: false,
    items: [],
    item: {},
    errorMessage: '',
    successMessage: '',
  },
  getters: {
  },
  actions: {
    async fetchCompany({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `access-company?page=${props.page}&limit=${props.limit}`
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchCompanyById({ commit }, id) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `access-company/${id}`
        );
        const data = response.data.data;
        commit("setItem", data);
        commit("setLoading", false);
        return data;
      } catch (error) {
        const response = error.response;
        commit("setLoading", false);
        commit("setError", true);
        if (response.status == 404) {
          commit('setErrorMessage', 'offer not found');
        } else {
          commit('setErrorMessage', 'Internal Server Error');
        }

      }
    },
    async searchCompany({ commit }, props) {
      commit('setError', false);
      try {
        const q = Utils.encodeUrlWithBase64(props.q);
        const currentPage = props?.page ?? 1;
        const currentLimit = props?.limit ?? 20;
        const by = props?.by ?? 'DESC';
        const response = await API.get(
          `access-company/search/${q}?page=${currentPage}&limit=${currentLimit}&by=${by}`
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async postCompany({ commit }, payload) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.post(`access-company/`, payload);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success Created');
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
    async deleteCompany({ commit }, payload) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.delete(`access-company/${payload.id}`);
        if (response.status == 201) {
          commit('setSuccessMessage', "Success Deleted");
        }
        commit('setLoading', false);
      } catch (error) {
        console.log(error.message);
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setItems(state, data) {
      state.items = data;
    },
    setItem(state, data) {
      state.item = data;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
  },
};

export default AccessCompany;
