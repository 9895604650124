import API from '../plugins/http';
const category = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    isError: false,
    refresh: false,
  },
  getters: {
    category: (state) => state.items,
  },
  actions: {
    async fetchAll({ commit }, props) {
      try {
        commit('setError', false);
        commit('setLoading', true);
        const response = await API.get(`categories`, { params: props });
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async search({ commit }, props) {
      try {
        commit('setError', false);
        commit('setLoading', true);
        const response = await API.get(`categories?name=${props.q}`);
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchCategoryById({ commit }, state) {
      try {
        const response = await API.get(`categories/${state.id}`);
        commit('setItems', [response.data.data]);
        commit('setItem', response.data.data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },
    async editCategory({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.put('category/' + payload.id, payload);
        if (response.status == 200) {
          commit(
            'setSuccessMessage',
            'Category Bisac Has Been Successfully Updated',
          );
        }
      } catch (error) {
        let errMsg = '';
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
      }
    },
    async createCategory({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.post('category', payload);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Category Has Been Successfully Created');
        }
      } catch (error) {
        let errMsg = [];
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
      }
    },

    async editStatus({ commit }, payload) {
      commit('setError', false);
      try {
        return API.put('category/status/update-status', payload);
      } catch (error) {
        commit('setError', true);
        return error;
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRefresh(state, data) {
      state.refresh = data;
    },
  },
};

export default category;
