import API from "../plugins/http";

const ManageOtp = {
  namespaced: true,
  state: {
    items: [],
    isLoading: false,
    isError: false,
    errorMessage: null,
    successMessage:null
  },
  actions: {
    
    async fetchManageOtp({ commit },) {
      try {
        commit("setLoading", false);
        const response = await API.get('config');
        return response
          } catch (error) {
            console.log(error)
      }
    },
    async updateConfigOtp({ commit },state) {
        commit("setLoading", true);
        const payload = {
            OTP_TIME_LIMIT: state.OTP_TIME_LIMIT,
            MAX_DEVICE_LIMIT: state.MAX_DEVICE_LIMIT,
            OTP_RESEND_LIMIT: state.OTP_RESEND_LIMIT,
            DEAUTH_DEVICE_LIMIT: state.DEAUTH_DEVICE_LIMIT
        }
        console.log('payload',payload)
        try {
          commit("setLoading", false);
          const response = await API.put(`config`,payload);
          console.log('respon',response)
          return response
            } catch (error) {
              console.log(error)
        }
      },
    
//     PUT http://localhost:3001/config
// payload
// {
//   "OTP_TIME_LIMIT": 2,
//   "MAX_DEVICE_LIMIT": 2,
//   "OTP_RESEND_LIMIT": 3,
//   "DEAUTH_DEVICE_LIMIT": 1
// }




// NEW




  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSuccessMessage(state, data) {
        state.successMessage =data
    }
  },
};

export default ManageOtp;
