import API from '../plugins/http';

export default {
  namespaced: true,
  state: {
    items: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    message: '',
  },
  getters: {
    items: (state) => state.items,
    totalRows: (state) => state.totalRows,
    isLoading: (state) => state.isLoading,
    isError: (state) => state.isError,
    message: (state) => state.message,
  },
  actions: {
    async fetchVendorFormat({ commit }, state) {
      commit('setIsLoading', true);
      try {
        const response = await API.get('vendor-format', { params: state });
        commit('setItems', response?.data?.data?.rows)
        commit('setTotalRows', response?.data?.data?.count)

      } catch (error) {
        commit('setError', true);
      }

      commit('setIsLoading', false);
    },
    async updateVendorFormat({ commit }, state) {
      commit('setIsLoading', true);

      try {
        const response = await API.put(`vendor-format/${state.id}`, state);
        if (response.status == 201) {
          commit('setMessage', 'Success Update');
        }

      } catch (error) {
        commit('setError', true);
      }

      commit('setIsLoading', false);
    },
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setTotalRows(state, totalRows) {
      state.totalRows = totalRows;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsError(state, isError) {
      state.isError = isError;
    },
    setMessage(state, message) {
      state.message = message;
    },
  },
}