import API from "../plugins/http";
const narrator = {
  namespaced: true,
  state: {
    narrators:[],
    totalRows: 0,
    layoutsRows: 0,
    isLoading: false,
    isError: false,
    errorMessage:null,
    successMessage: null,
  },
  getters: {
    narrator: (state) => state.narrator,
    Narrator: (state) => state.Narrator,
  },

  actions: {
    async fetch({ commit }, props) {
      try {
        commit("setLoading", true);
        commit("setNarrator", []);
        const response = await API.get(`narrator`, {params: props});

        commit("setNarrator", response.data?.data.rows);
        commit("setNarratorRows", response.data?.data.count);
        commit("setLoading", false);
        return response;
      } catch (error) {
        let response = error.response
        commit("setNarrator", []);
        commit("setNarratorRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
        return response;
      }
    },
    async fetchByID({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setNarrator", []);
        const response = await API.get(
          `product-choices/${state.id}`
        );

        commit("setNarrator", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setNarrator", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },
    async fetchNarratorByID({ commit }, state) {
      commit('setLoading', true);
      commit('setError', false);
      commit("setNarrator", null);
      try {
        const response = await API.get(`narrator/${state.id}/detail`);
        commit("setNarrator", response.data?.data);
        commit('setLoading', false);
      } catch (error) {
        const statusCode = error.status ?? error.response?.status;
        let message = error.data?.message ?? error.response?.data?.message;
        commit('setError', true);
        commit('setErrorMessage', `${statusCode} : ${message}`);
        commit('setLoading', false);
      }
    },
    async postNarratorWithImage({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const data = {
          name: payload.name,
          first_name: payload.first_name,
          last_name: payload.last_name,
          birthdate: payload.birthdate,
          academic_title: payload.academic_title,
          is_active: payload.is_active,
          meta: payload.meta,
        }
        const response = await API.post(`narrator`, data);
        if (response.status == 201) {
          if(payload.image) {
            const id = response.data.data.rows[0].id;
            const formData = new FormData();
            formData.append('image', payload.image),
            await API.post(`narrator/${id}/upload`, formData);
            }
          commit('setSuccessMessage', "Success Created");
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.data.message??response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        if (response.status == 409) {
          errMsg = error.response?.data.data?.message ?? errMsg;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
      commit('setLoading', false);
    },
    async updateNarrator({ commit }, payload) {
      commit('setLoading', true);
      commit('setError', false);
      commit('setSuccessMessage', null);
      commit('setErrorMessage', null);
      try {
        const response = await API.put(`narrator/${payload.id}`, payload);
        commit('setLoading', false);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success');
        }
      } catch (error) {
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
    async uploadImage({ commit }, payload) {
      commit('setLoading', true);
      commit('setError', false);
      commit('setSuccessMessage', null);
      commit('setErrorMessage', null);
      commit('setNarrator', null);
      try {
        const formData = new FormData();
        formData.append('image', payload.image),
        await API.post(`narrator/${payload.id}/upload`, formData);
        commit('setSuccessMessage', 'Success');
      } catch (error) {
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.data.message??response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
          console.log('err msg', errMsg)
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
  },

  mutations: {
    setNarrator(state, item) {
      state.narrators = item;
    },
    setNarratorRows(state, data) {
      state.totalRows = data;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
  },
};

export default narrator;
