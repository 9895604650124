import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'tailwindcss/tailwind.css'

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import VueSweetalert2 from "vue-sweetalert2";
import Multiselect from "vue-multiselect";
import vue2Dropzone from 'vue2-dropzone';

import "sweetalert2/dist/sweetalert2.min.css";
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "vue-multiselect/dist/vue-multiselect.min.css";
import "./plugins/Veevalidate";
import "./plugins/filter";
import "./plugins/GlobalMethods";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import VueApexCharts from "vue-apexcharts";
import VCalendar from "v-calendar";
import PrimeVue from 'primevue/config';
import Calendar from 'primevue/calendar';
import './assets/css/tailwind.css';
import VueTailwind from 'vue-tailwind'
// Use v-calendar & v-date-picker components
Vue.use(VueApexCharts);
Vue.use(VCalendar);
Vue.config.productionTip = false;

import {
  TInput,
  TTextarea,
   TSelect,
  // TRadio,
   TCheckbox,
  // TButton,
  // TInputGroup,
  // TCard,
  // TAlert,
  TModal,
  TDropdown,
  // TRichSelect,
  TPagination,
  // TTag,
  // TRadioGroup,
  // TCheckboxGroup,
  TTable,
  // TDatepicker,
  // TToggle,
  //TDialog,
} from 'vue-tailwind/dist/components';

const settings = {
 
  't-pagination': {
    component: TPagination,
  },
  't-input': {
    component: TInput,
  },
  't-select': {
    component: TSelect,
    props: {
      fixedClasses: 'block w-full pl-3 pr-10 py-2.5 transition duration-100 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-black placeholder-gray-400 bg-gray-50 border-gray-300 focus:border-blue-500 ',
      variants: {
        danger: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
        success: 'border-green-300 bg-green-50 placeholder-gray-400 text-green-900'
      }
    }
  },

 't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay: 'overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-screen fixed overlay-t-modal-custom bg-opacity-50',
        wrapper: 'relative mx-auto z-50 max-w-lg px-3 py-12 flex items-center justify-center min-h-screen', // Updated
        modal: 'overflow-visible relative  rounded min-w-96 w-96 ',
        body: 'p-3',
        header: 'border-b p-3 rounded-t',
        footer: ' p-3 rounded-b',
        close: 'flex items-center justify-center rounded-full absolute right-3 top-3 '
      },
      classes: {
        overlay: 'bg-black',
        wrapper: '',
        modal: 'bg-white shadow',
        body: 'p-3',
        header: 'border-gray-100',
        footer: 'bg-gray-100',
        close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
        closeIcon: 'fill-current h-4 w-4',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: ''
      },
      variants: {
        danger: {
          overlay: 'bg-red-100',
          header: 'border-red-50 text-red-700',
          close: 'bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border',
          modal: 'bg-white border border-red-100 shadow-lg',
          footer: 'bg-red-50'
        }
      }
    }
  },
  't-table': {
    component: TTable,
    props: {
      classes: {
        table: 'w-full bg-gray-100 p-2 shadow-lg border-gray-300 border-0 ',
        thead: 'p-2.5',
        theadTr: 'px-2 text-center ',
        theadTh: 'px-2 py-3 text-left bg-gray-100 border-0 font-semibold upprace uppercase text-xs text-gray-500',
        tbody: 'bg-white divide-y divide-gray-100',
        tr: '',
        td: 'py-3 px-2 whitespace-no-wrap',
        tfoot: '',
        tfootTr: '',
        tfootTd: ''
      },
    },
  },
  't-checkbox': {
    component: TCheckbox,
  },
  't-textarea': {
    component: TTextarea,
    props: {
      fixedClasses: 'block w-full pl-3 pr-10 py-2.5 transition duration-100 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-black placeholder-gray-400 h-60 bg-gray-50 border-gray-300 focus:border-blue-500 ',
     
    }
  },
  't-dropdown': {
    component: TDropdown,
    props: {
      fixedClasses: {
        button: 'flex items-center text-white block px-4 py-2 transition duration-100 ease-in-out border border-transparent rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
        wrapper: 'inline-flex flex-col',
        dropdownWrapper: 'relative z-10',
        dropdown: 'origin-top-left absolute right-2 w-56 rounded shadow mt-1',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95'
      },
      classes: {
        button: 'bg-blue-500 hover:bg-blue-600',
        dropdown: 'bg-white'
      },
  },
}
  // ...Rest of the components
}

Vue.use(VueTailwind, settings)
Vue.component("apexchart", VueApexCharts);
Vue.component("vueDropzone", vue2Dropzone)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSweetalert2);
Vue.component("multiselect", Multiselect);
Vue.use(PrimeVue);
Vue.component('Calendar', Calendar);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
