import API from "../plugins/http";
const Brands = {
  namespaced: true,
  state: {
    items: [],
    product: [],
    totalRowsProduct: 0,
    isLoadingProduct: false,
    totalRows: 0,
    name : '',
    isLoading: false,
    isError: false,
    isCreate: false,
    isSuccess:false,
    errorMessage: '',
    successMessage:'',
    refresh: false,
    status:'',
  },
  getters: {
    brands: (state) => state.items,
    product: (state) => state.product,
  },

  actions: {
    async fetchBrands({ commit }, state) {
      commit("setItems",[]);
      try {
        commit("setLoading", true);
        const response = await API.get(
          `brands?&page=${state.page}&limit=${state.limit}`
        );

        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },

    async searchBrands({ commit }, state) {
      try {
        const response = await API.get(`brands`, { params: state });
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
        return response;
      } catch (error) {
        return error;
      }
    },
    async findProducts({ commit }, props) {
      try {
        commit("setLoadingProduct", true);
        const response = await API.get(`brands-products/${props.id}`);
        let product = response.data.data.rows;
        commit("setProduct", product);
        commit("setRowsProduct", response.data.data.count);
        commit("setLoadingProduct", false);
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },async fetchBrandsByid({ commit }, props) {
      // to get brand by id (edit)
      let response = null;
      commit("setError", false);
      commit("setSuccessMessage", null);
      commit("setItems", []);
      let message = null;
      try {
        response = await API.get(`brands/${props.id}`);
        const allData = response.data.data;
        if(allData){
          const result = allData.result;
          const item = allData.data;
          message = allData.message;
          commit("setError", !result);
          commit("setItems", item);
        }
        return [response.data.data.data];
      } catch (error) {
        if(error.response.data.message.message){
          message = error.response.data.message.message;        
        }
        commit("setError", true);
        
      }
      commit("setSuccessMessage",message);
      commit('setLoading',false);
      return null;
      
  },
  async updateBrands({ commit }, payload) {
  // for update brands
    commit('setLoading', true);
    commit('setSuccessMessage', null);
    commit('setErrorMessage', null);
    commit("setSuccess", false);
    commit("setError", false);
    let response = null;
    let message = 'Error';
    try {
      response = await API.put(`/brands/updateDataOnlyBrands`, payload);
      const allData = response.data.data;
      message = allData.message;
      if (response.status == 200 || response.status == 201 ) {
        commit("setSuccess", true);
        commit("setSuccessMessage", message);
      }
    } catch (error) {
      let errMsg = 'Error';
      const response = error.response;
      if (response.status == 404) errMsg = response.statusText;
      if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
      commit("setError",true);
      commit("setErrorMessage", errMsg);
      commit("setLoading", false);
    }
    
  },async createBrands({ commit }, payload) {
    // for create brands
    commit('setLoading', true);
    commit('setSuccessMessage', null);
    commit('setErrorMessage', null);
    commit('setSuccess',false);
    commit('setError',false);

    let response = null;
    let message = 'Error';
    const maps = {};
    maps.result = false;
    maps.message = '';
    maps.data = null;
    try {
      response = await API.post(`/brands`, payload);
      if (response.status == 200 || response.status == 201 ) {
        message = 'Success Create Brands';
        maps.data = response.data?.data;
        maps.result = true;
        maps.message = message;
        commit('setSuccess',true); 
        commit('setSuccessMessage', message);
      } else {
        message = 'Error Response Status';
        commit('setError', true);
        commit('setErrorMessage', message)
      }
    } catch (error) {
      let errMsg = 'Error';
      const response = error.response;
      if (response.status == 404) errMsg = response.statusText;
      if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        maps.message = errMsg;
      commit("setError",true);
      commit("setErrorMessage", errMsg)
    }
    commit('setLoading',false);
    return maps;
  },
  async getCountries({ commit }) {
      commit('setLoading', false);
      try {
        const response = await API.get(`countries`);
        return response
      } catch (error) {
        return error
      }
  },async getCategoriesByBrandIdAdapter({ commit },query) {
    // display category
    commit('setLoading', true);
    commit('setSuccessMessage', null);
    commit("setRows",0);
    commit("setError",false);
    commit("setItems",null);
    commit("setName",null);
    let data = null;
    try {
        const response = await API.get(`brands/getCategoriesById/${query.id}`);
        const allData = response.data.data;
        const result = allData.result;
        const isCreate = allData.isCreate;
        const name = allData.brandName;
        data = allData.data;
        commit('setError',!result);
        commit('setName', name);
        commit('setItems', data);
        commit('setIsCreate', isCreate);
        commit('setRows', data.length);
        if(!result){
          commit('setSuccessMessage', allData.message);
        }
      } catch (error) {
        let message = null;
        if(error.response.data.message.message){
          message = error.response.data.message.message;        
        }
        commit("setError",true);
        commit("setSuccessMessage",message);
      }
      commit('setLoading',false);
  },async getBrandsNameById({ commit },query) {
    commit('setLoading', true);
    commit("setRows",null);
    commit("setName",null);
    commit("setError",null);
    commit("setSuccess",null);
    commit("setSuccessMessage",null);
    let message = null;
      try {
        const response = await API.get(`brands/getBrandNameById/${query}`);
        if(response!=null){
          const allData = response.data.data;
          const result = allData.result;
          const message = allData.result;
          const name = allData.name;
          commit("setError",!result);
          commit("setSuccessMessage",message)
          commit("setName",name);
        }
      } catch (error) {
        if(error.response.data.message.message){
          message = error.response.data.message.message;        
        }
        commit("setError",true);
        commit("setSuccessMessage",message)
      }
      commit('setLoading', false);
  },async searchCategory({ commit }, query) {
    commit('setLoading', false);
    try {
      const response = await API.get(`brands/getCategoryByQuery/`+query.id+`/`+query.qry);
      return response
    } catch (error) {
      return error
    }
  },async getCategoryLimit({ commit }, query) {
    commit('setLoading', false);
    try {
      const response = await API.get(`brands/getCategoryLimit/`+query.id);
      return response
    } catch (error) {
      return error
    }
  },async insertCategory({ commit }, payload) {
    // for insert categories
    commit('setLoading', true);
    commit('setSuccessMessage', null);
    commit('setSuccess',false);
    let message = 'Error';
    try {
      const response  = await API.post(`/brands/insertCategories`, payload);
      const allData = response.data.data;
      const result = allData.result;
      message = allData.message;
      commit('setSuccess', result);
    } catch (error) {
      if(error.response.data.message.message){
        message = error.response.data.message.message;        
      }
      commit("setSuccess",false);
    }
    commit("setSuccessMessage",message);
    commit('setLoading',false);
  },async getPremiumsByBrandIdAdapter({ commit }, id) {
    // for display premium page
    commit('setLoading', true);
    commit('setSuccessMessage', null);
    commit("setRows",0);
    commit("setError",false);
    commit("setItems",null);
    commit("setName",null);
    let message = 'Error';
    let data = null;
    try {
      const response = await API.get(`brands/getPremiumsBy/${id}`);
      const allData = response.data.data;
      const result = allData.result;
      const isCreate = allData.isCreate;
      const name = allData.brandName;
      message = allData.message;
      data = allData.data;
      commit("setError",!result);
      commit("setName", name);
      commit("setItems", data);
      commit("setIsCreate", isCreate);
      commit("setRows", data.length);
      if(!result){
        commit('setSuccessMessage', allData.message);
      }
    } catch (error) {
      if(error.response.data.message.message){
        message = error.response.data.message.message;        
      }
      commit("setError",true);
      commit("setSuccessMessage",message);
    }
    commit("setLoading",false);
  },async getPremiumLimit({ commit }, query) {
    commit('setLoading', false);
    try {
      const response = await API.get(`brands/getPremiumLimit/`+query.id);
      return response
    } catch (error) {
      return error
    }
  },async searchPremium({ commit }, query) {
    commit('setLoading', false);
    try {
      const response = await API.get(`brands/getPremiumByQuery/`+query.id+`/`+query.qry);
      return response
    } catch (error) {
      return error
    }
  },async insertPremiums({ commit }, payload) {
    // for insert premiums
    commit('setLoading', true);
    commit('setSuccessMessage', null);
    commit('setSuccess',false);
    let response = null;
    let message = 'Error';
    try {
      response = await API.post(`/brands/insertPremiums`, payload);
      const allData = response.data.data;
      const result = allData.result;
      message = allData.message;
      commit('setSuccess',result);
    } catch (error) {
      if(error.response.data.message.message){
        message = error.response.data.message.message;        
      }
      commit('setSuccess',false);
    }
    commit("setSuccessMessage",message);
    commit('setLoading',false);
  },async deletePremiums({ commit },payload) {
    // for delete premium
    commit('setError', false);
    let response = null;
    let message = null;
    let result = false;
    const maps= {};
    maps.result = false;
    maps.message = message;
    try {
      response = await API.delete(`/brands/deletePremiums/${payload.offer_id}/${payload.id}`);
      const allData = response.data.data;
      result = allData.result;
      message = allData.message;
    } catch (error) {
      if(error.response.data.message.message){
        message = error.response.data.message.message;        
      } else {
        message = 'Internal Server Error';
      }
    }
    maps.result = result;
    maps.message = message;
    return maps;
    
  },async deleteCategory({ commit }, payload) {
    // for delete category
    commit('setError', false);
    let response = null;
    let message = null;
    let result = false;
    const maps= {};
    maps.result = false;
    maps.message = message;
    try {
      response = await API.delete(`/brands/deleteCategory/${payload.category_id}/${payload.id}`);
      const allData = response.data.data;
      result = allData.result;
      message = allData.message;
    } catch (error) {
      if(error.response.data.message.message){
        message = error.response.data.message.message;        
      } else {
        message = 'Internal Server Error';
      }
    }
    maps.result = result;
    maps.message = message;
    return maps;
  },async searchVendorById({ commit }, props) {
      commit('setLoading', false);
      try {
        const response = await API.get(`brands/searchVendorById/`+props);
        return response
      } catch (error) {
        return error
      }
  },async getVendor({ commit }) {
    commit('setLoading', false);
    try {
      const response = await API.get(`vendor`);
      return response
    } catch (error) {
      return error
    }
},async searchVendor({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `brands/searchVendor/search/?query=${state.q}`
        );   
        commit("setLoading", false);
        return response;
      } catch (error) {
        let response = error.response
        
        return response;
      }
  },async searchCountries({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setItems", []);
        const response = await API.get(
          `countries/search?query=${state.q}`
        );
        commit("setItems", response.data.data);
        commit("setRows", response.data.data);
        commit("setLoading", false);
        return response;
      } catch (error) {
        let response = error.response
        commit("setError", true);
        if (response.status == 403) {
          let err = response.data.message
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
        return error;
      }
  },async getPremiumOffer({ commit }) {
      commit('setLoading', false);
      try {
        const response = await API.get(`offers-premium`);
        return response
      } catch (error) {
        return error
      }
    },
    
  },mutations: {
    setItems(state, item) {
      state.items = item;
    },setProduct(state, item) {
      state.product = item;
    },setRows(state, data) {
      state.totalRows = data;
    },setRowsProduct(state, data) {
      state.totalRowsProduct = data;
    },setLoadingProduct(state, data) {
      state.isLoadingProduct = data;
    },setLoading(state, data) {
      state.isLoading = data;
    },setError(state, data) {
      state.isError = data;
    },setSuccessMessage(state, data) {
      state.successMessage = data;
    },setErrorMessage(state, data) {
      state.errorMessage = data;
    },setRefresh(state, data) {
      state.refresh = data;
    },setStatus(state, data) {
      state.status = data;
    },setIsCreate(state, data){
      state.isCreate = data;
    },setName(state, data){
      state.name = data;
    },setSuccess(state,data){
      state.isSuccess = data;
    }
  },
};




export default Brands;
