const LayoutsPath = {
  path: '/layouts',
  name: 'layouts',
  component: () => import('../views/product_choices/index.vue'),
}
const LayoutsCreatePath = {
  path: '/layouts/create',
  name: 'layoutsCreate',
  component: () => import('../views/product_choices/create.vue')
}
const LayoutsDetailPath = {
  path: '/layouts/detail/:id',
  name: 'layoutsDetail',
  component: () => import('../views/product_choices/detail.vue')
}

module.exports = {
  LayoutsPath,
  LayoutsCreatePath,
  LayoutsDetailPath,
}