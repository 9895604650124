<template>
  <div>
    <div class="row p-2 m-1">
      <div class="w-1/2 pr-2 mb-4 ">
        <div :no-body="true">
          <div class="p-0 clearfix bg-white shadow-md rounded-lg">
            <i class="fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left  rounded-bl-lg rounded-tl-md"></i>
            <div class="h6 text-info mb-0 pt-3" v-if="order_paid_rp">
              {{
          formatPrice(
            order_paid_rp.total_payment,
            order_paid_rp.currency_code
          )
        }}
            </div>
            <div v-else class="h6 text-info mb-0 pt-3">
              <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>

            <div class="text-muted text-uppercase font-xs">
              Income
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/2 pl-2 mb-4">
        <div :no-body="true">
          <div class="p-0 clearfix bg-white shadow-md rounded-lg">
            <i class="fa fa-bell bg-danger p-4 font-2xl mr-3 float-left rounded-bl-lg rounded-tl-md"></i>
            <div class="h6 mb-0 pt-3" v-if="order_paid_rp">
              <span class="text-red-500">
                {{
          formatPrice(
            order_cancel_rp.total_payment,
            order_cancel_rp.currency_code
          )
        }} 
              </span>
            </div>
            <div v-else class="h6 text-info mb-0 pt-3">
              <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
            <div class="text-muted text-uppercase font-xs">
              Failed Income
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/2 pr-2 mb-4">
        <div :no-body="true">
          <div class="p-0 clearfix bg-white shadow-md rounded-lg">
            <i class="fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left rounded-bl-lg rounded-tl-md"></i>
            <div class="h6 text-info mb-0 pt-3 " v-if="order_paid_dollar">
              {{
          formatPrice(
            order_paid_dollar.total_payment,
            order_paid_dollar.currency_code
          )
        }}
            </div>
            <div v-else class="h6 text-info mb-0 pt-3">
              <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
            <div class="text-muted text-uppercase font-xs">
              Income
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/2 pl-2 mb-4">
        <div :no-body="true">
          <div class="p-0 clearfix bg-white shadow-md rounded-lg">
            <i class="fa fa-bell bg-danger p-4 px-4 rounded-bl-lg rounded-tl-md font-2xl mr-3 float-left"></i>
            <div class="h6 text-danger mb-0 pt-3 text-red-500" v-if="order_paid_dollar">
              {{
          formatPrice(
            order_cancel_dollar.total_payment,
            order_cancel_dollar.currency_code
          )
        }}
            </div>
            <div v-else class="h6 text-info mb-0 pt-3">
              <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
            <div class="text-muted text-uppercase font-xs">
              Failed Income
            </div>
          </div>
        </div>
      </div>
      <div class="w-full">
        <div class="bg-white shadow-md border rounded p-1">
          <ColumnChart />
        </div>
      </div>

      <div class="w-full flex justify-between mt-4 overflow-hidden">

        <div class="w-1/2">
          <DonutChart />
        </div>
        <div class="w-1/2 rounded-md p-1">
          <readingpopular />
        </div>
      </div>
      <!-- <div class="col-sm-6">
        <div class="card shadow-sm rounded p-1">
          <DonutChart />
        </div>
      </div> -->
      <!-- <div class="col-sm-6">
        <div class="card shadow-sm rounded p-1">
          <readingpopular />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import 'flowbite';
import moment from "moment";
import DonutChart from "../components/chart/DonutChart.vue";
import ColumnChart from "../components/chart/ColumnChart.vue";
import readingpopular from "../components/chart/readingpopular.vue";
import API from "../plugins/http";
export default {
  name: "dashboard",
  components: {
    DonutChart,
    ColumnChart,
    readingpopular
  },
  data() {
    return {
      order_paid_rp: {},
      order_paid_dollar: {},
      order_cancel_rp: {},
      order_cancel_dollar: {},
      Saleseries: [{ data: [], name: "Sales" }],
      chartOptionsSales: {},
      chartOptionSingleSales: {},
      SaleseriesSingle: [{ data: [], name: "Sales" }],
      Dates: {
        from: moment().format("YYYY,MM,DD"),
        to: moment().format("YYYY,MM,DD")
      },
      range: {
        start: null,
        end: null
      },
      masks: {
        input: "YYYY-MM-DD"
      }
    };
  },
  mounted() {
    this.actionGetSales();
  },
  methods: {
    actionGetSales() {
      let from = moment().startOf('month').format("YYYY-MM-DD")
      let to = moment(from).endOf('month').format('YYYY-MM-DD')
      API.get(`dashboard/orders?from=${from}&to=${to}`).then(response => {
        let data = response.data.data;
        this.order_paid_rp = data.order_paid_rp;
        this.order_paid_dollar = data.order_paid_dollar;
        this.order_cancel_rp = data.order_cancel_rp;
        this.order_cancel_dollar = data.order_cancel_dollar;
      });
    },
    formatPrice(value, currency) {
      if (value) {
        let formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency
          });
        }

        return formatter.format(value);
      }

      return "0";
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
};
</script>

<style scoped></style>
