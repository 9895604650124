import API from "../plugins/http";
const userChart = {
  namespaced: true,
  state: {
    items: [],
    isError: false,
  },
  getters: {
    single: (state) => state.items,
  },
  actions: {
    async newUser({ commit }, state) {
      try {
        const response = await API.get(
          `dashboard-users/new-users/${state.from}/${state.to}`
        );

        commit("setItems", response.data.data);
      } catch (error) {
        commit("setItems", []);
        commit("setError", error);
      }
    },
    async searchNewUser({ commit }, state) {
      try {
        const response = await API.get(
          `dashboard-users/new-users/${state.from}/${state.to}`
        );

        return response;
      } catch (error) {
        commit("setError", true);
        return error;
      }
    },
  },
  mutations: {
    setItem(state, item) {
      state.items = item;
    },
    setError(state, data) {
      state.isError = data;
    },
  },
};

export default userChart;
