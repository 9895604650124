import API from '../plugins/http';

const organization = {
  namespaced: true,
  state: {
    items: [],
    usage: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    totalRowsUsage: 0,
    isLoadingUsage: false,
    isErrorUsage: false,
    successMessage: '',
    errorMessage: '',
  },
  getters: {
    organization: (state) => state.items,
    usage: (state) => state.usage,
  },
  actions: {
    async fetchOrganization({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `organization?page=${state.page}&limit=${state.limit}&type=${state.type}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async findOrganization({ commit }, state) {
      try {
        const response = await API.get(
          `organization/search-organization?name=${state.q}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },

    async fetchOrganizationById({ commit }, state) {
      try {
        const response = await API.get(`organization/${state.id}`);
        commit('setItems', [response.data.data]);
        commit('setItem', response.data.data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },

    async createOrganization({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.post('organization', state);
        if (response.status == 201) {
          commit(
            'setSuccessMessage',
            'Organization Has Been Successfully Created',
          );
        }
      } catch (error) {
        let errMsg = '';
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
      }
    },

    async editOrganization({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.put('organization/' + payload.id, payload);
        if (response.status == 200) {
          commit(
            'setSuccessMessage',
            'Organization Has Been Successfully Updated',
          );
        }
      } catch (error) {
        let errMsg = '';
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
      }
    },

    async searchOrganization({ commit }, props) {
      try {
        const response = await API.get(
          `organization/search?search=${props.search}`,
        );
        commit('setItems', response.data.data.name);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },

    async AutocompleteSearch({ commit }, state) {
      try {
        commit('setLoading', true);
        commit('setItems', []);
        const response = await API.get(
          `autocomplete-organization/?q=${state.q}`,
        );
        commit('setItems', response.data.data);
        commit('setRows', response.data.data);
        commit('setLoading', false);
        return response;
      } catch (error) {
        let response = error.response;
        commit('setError', true);
        if (response.status == 403) {
          let err = response.data.message;
          commit('setErrorMessage', err);
        } else {
          commit('setErrorMessage', error.response);
        }
        return error;
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setUsage(state, item) {
      state.usage = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRowsUsage(state, data) {
      state.totalRowsUsage = data;
    },
    setLoadingUsage(state, data) {
      state.isLoadingUsage = data;
    },
    setErrorUsage(state, data) {
      state.isErrorUsage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default organization;
