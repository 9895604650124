const CategoryBisac = {
  path: '/category-bisac',
  name: 'category-bisac',
  component: () => import('../views/category-bisac/category-bisac.vue'),
};
const EditCategoryBisac = {
  path: '/category-bisac/edit/:id',
  name: 'category-bisac-edit',
  component: () => import('../views/category-bisac/category-bisac-edit.vue'),
};
const CreateCategoryBisac = {
  path: '/category-bisac/create',
  name: 'category-bisac-create',
  component: () => import('../views/category-bisac/category-bisac-create.vue'),
};

module.exports = {
  CategoryBisac,
  EditCategoryBisac,
  CreateCategoryBisac,
};
