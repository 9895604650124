import API from '../plugins/http';

const discount_code = {
  namespaced: true,
  state: {
    items: [],
    usage: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    totalRowsUsage: 0,
    isLoadingUsage: false,
    isErrorUsage: false,
    successMessage: '',
    errorMessage: '',
  },
  getters: {
    discount_code: (state) => state.items,
    usage: (state) => state.usage,
  },
  actions: {
    async fetchDiscountCode({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(`discounts-code`, { params: state });
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async findDiscountCode({ commit }, state) {
      commit('setLoading', true);
      try {
        const response = await API.get(`search-discount-code?q=${state.q}`);
        commit('setItems', response.data?.data?.rows);
        commit('setRows', response.data?.data?.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error;
      }
    },

    async fetchDiscountCodeById({ commit }, state) {
      try {
        const response = await API.get(`discounts-code/${state.id}`);
        commit('setItems', [response.data.data]);
        commit('setItem', response.data.data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },

    async fetchUserByid({ commit }, state) {
      try {
        const response = await API.get(`users/get-by-id/${state.id}`);
        commit('setItem', response.data.data);
        return response;
      } catch (error) {
        return error;
      }
    },

    async updateMaxUse({ commit }, state) {
      try {
        const response = await API.put('discounts-code/update-maxuse', state);
        commit('setItem', response.data.data);
        return response;
      } catch (error) {
        return error;
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setUsage(state, item) {
      state.usage = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRowsUsage(state, data) {
      state.totalRowsUsage = data;
    },
    setLoadingUsage(state, data) {
      state.isLoadingUsage = data;
    },
    setErrorUsage(state, data) {
      state.isErrorUsage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default discount_code;
