import API from '../plugins/http';
const Orders = {
  namespaced: true,
  state: {
    items: [],
    isLoading: false,
    isLoadingPopular: false,
    item_wallet: [],
    totalWallet: 0,
    totalRows: 0,
    readings: [],
    totalReadings: 0,
    totalRevenue: 0,
    isError: false,
    errorMessage: null,
    totalAmount: 0,
    totalWalletAmount: 0,
    populars: [],
    Content: [],
    isLoadingContent: false,
    chartContent: [],
    isLoadingChartContent: false
  },
  getters: {},
  actions: {
    async FinanceReport({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `dashboard-vendor/finance-revenue?from=${state.from}&to=${state.to}`,
        );
        commit('setLoading', false);
        commit('setItems', response.data.data.rows);
      } catch (error) {
        commit('setItems', []);
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async FetchPopularPremiumBook({ commit }, state) {
      //report-single
      try {
        commit('setPopulars', []);
        commit('setLoading', true);
        commit('setPopulars', []);
        const response = await API.get(
          `dashboard-vendor/premium-reading?from=${state.from}&to=${state.to}`,
        );

        commit('setPopulars', response.data.rows);
        commit('setLoadingPopular', false);
        commit('setLoading', false);
      } catch (error) {
        let response = error.response;
        commit('setError', true);
        commit('setLoading', false);
        if (response && response.status == 403) {
          let err = response.data.message;
          commit('setErrorMessage', err);
        } else {
          commit('setErrorMessage', error.response);
        }
        return error;
      }
    },
    async Content({ commit }, state) {
      try {
        commit('setLoadingContent', true);
        const response = await API.get(
          `dashboard-content/aggregate?from=${state.from}&to=${state.to}&vendor_id=${state.vendor_id}`,
        );
        commit('setLoadingContent', false);
        commit('setContent', response.data.data);
      } catch (error) {
        commit('setContent', []);
        commit('setLoadingContent', false);
        commit('setError', true);
      }
    },
    async contentAdmin({ commit }, state) {
      try {
        commit('setLoadingChartContent', true);
        let baseURI = `dashboard-content?from=${state.from}&to=${state.to}`;
        if (state.groupBy) {
          baseURI = `dashboard-content?from=${state.from}&to=${state.to}&groupBy=${state.groupBy}`;
        }
        const response = await API.get(baseURI);
        commit('setLoadingChartContent', false);
        commit('setChartContent', response.data.data);
      } catch (error) {
        commit('setChartContent', []);
        commit('setLoadingChartContent', false);
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setLoadingPopular(state, data) {
      state.isLoadingPopular = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setWallet(state, item) {
      state.item_wallet = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setPopulars(state, data) {
      state.populars = data;
    },
    setContent(state, data) {
      state.Content = data;
    },
    setLoadingContent(state, data) {
      state.isLoadingContent = data;
    },
    setChartContent(state, data) {
      state.chartContent = data;
    },
    setLoadingChartContent(state, data) {
      state.isLoadingChartContent = data;
    },
  },
};

export default Orders;
