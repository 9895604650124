import destructErrResponse from "../plugins/destruct-error-api";
import API from "../plugins/http";
const magazine = {
  namespaced: true,
  state: {
    items: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    item: {},
    errorMessage: '',
    successMessage: '',
    refresh: false,
  },
  getters: {
    magazines: (state) => state.items,
    magazineSales: (state) => {
      return state.items.filter((m) => m.is_internal_content === false);
    },
    magazineNonSales: (state) => {
      return state.items.filter((m) => m.is_internal_content === true);
    },
  },
  actions: {
    async fetchMagazine({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `items`,
          {
            params : {
              item_type: 'magazine',
              ...state
            }
          }
        );
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },

    async fetchMagazineById({ commit }, props) {
      commit("setLoading", true);
      try {
        commit("setItem", {});
        const response = await API.get(`items/${props.id}`);
        let row = response.data.data;
        commit("setItem", row.rows[0]);
        commit("setItems", row.rows);
        commit("setRows", row.rows?.length);
        commit("setLoading", false);
        return row
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },

    async searchMagazine({ commit }, props) {
      try {
        commit('setError', false);
        commit("setLoading", true);
        const response = await API.get(
          `items-search/magazine`, {
            params: props,
          }
        );
          commit("setItems", response.data.data.rows);
          commit("setRows", response.data.data.count);
          commit("setLoading", false);
        } catch (error) {
          commit("setLoading", false);
          commit("setError", true);
        }
    },

    async postNewMagazine({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.post(`items/magazine`, payload);
        commit('setRefresh', false);
        if (response.status == 201) {
          commit('setSuccessMessage', "Success Created");
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
      } catch (error) {
        const {
          statusCode,
          statusText,
          message,
          data,
        } = destructErrResponse(error);
        let customMessage = data?.data?.message;

        if (statusCode == 422) {
          customMessage = statusText;
          customMessage += ' REQUIRED ';
          customMessage += (data.errors ?? []).map(item => item.param).join(', ');
        }

        commit('setError', true);
        commit('setErrorMessage', customMessage ?? message);
        commit('setLoading', false);
      }
    },

    async uploadAsset({ commit }, payload) {
      commit('setRefresh', false);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const formData = new FormData();
        formData.append('file_content', payload.file_content);
        formData.append('id', payload.id);
        formData.append('item_status', payload.item_status);
        formData.append('release_schedule', payload.release_schedule);
        const response = await API.post(`upload`, formData);
        if (response.status == 200) {
          commit('setSuccessMessage', "Success Uploaded");
          commit('setRefresh', true);
        }
      } catch (error) {
        const response = error?.response;
        let errMsg = "Error, please try again";

        if (response?.status == 404) errMsg = response?.statusText;
        if (response?.status == 422) {
          errMsg = response?.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response?.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response?.status == 500) {
          errMsg = response?.data.data.message ?? response?.statusText;
        }
        commit('setLoading', false);
        commit('setError', true);
        commit('setErrorMessage', errMsg);
      }
    },

    async updateMagazine({ commit }, payload) {
      commit('setRefresh', false);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.put(`items/${payload.item_id}`, payload);
        if (response.status == 201) {
          commit('setSuccessMessage', "Success Updated");
          commit('setRefresh', true);
        }
        commit('setLoading', false);
      } catch (error) {
        const {
          statusCode,
          statusText,
          message,
          data,
        } = destructErrResponse(error);
        let customMessage = data?.data?.message;

        if (statusCode == 422) {
          customMessage = statusText;
          customMessage += ' REQUIRED ';
          customMessage += (data.errors ?? []).map(item => item.param).join(', ');
        }

        commit('setError', true);
        commit('setErrorMessage', customMessage ?? message);
        commit('setLoading', false);
      }
    },

  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setItem(state, item) {
      state.item = item;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setRefresh(state, data) {
      state.refresh = data;
    },
  },
};

export default magazine;
