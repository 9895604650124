import moment from "moment";
import API from "../plugins/http";
import util from "../plugins/util";
import constant from "./constant";
import destructErrResponse from "../plugins/destruct-error-api";
const ebooks = {
  namespaced: true,
  state: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    isError: false,
    items : [],
  },
  getters: {
  },
  actions: {
    async searchCategories({ commit }, query) {
      commit('setLoading', false);
      try {
        const response = await API.get(`categories?name=${query}`);
        return response
      } catch (error) {
        return error
      }
    },

    async searchCategoriesV2({ commit }, state) {
      commit('setLoading', false);
      try {
        const response = await API.get(`categories`, {
          params: state
        });
        return response
      } catch (error) {
        return error
      }
    },

    async getCountries({ commit }) {
      commit('setLoading', false);
      try {
        const response = await API.get(`countries`);
        return response
      } catch (error) {
        return error
      }
    },

    async searchItemByType({ commit }, payload) {
      commit('setLoading', false);
      const itemType = payload.item_type;
      const query = payload.q;
      try {
        const response = await API.get(`items-search/${itemType}?q=${query}`);
        return response
      } catch (error) {
        return error
      }
    },

    generateEditionCodeBookMagazine({ commit }, brand ) {
      commit("setLoading", true);
      const date = moment();
      const year = date.format('YYYY');
      const month = date.format('MM');
      let editionCode = `${brand.brand_code}${year}MTH${month}`;

      if (brand.default_item_schedule == 'edition') {
        editionCode += 'ED'
      }
      commit("setLoading", false);
      return editionCode;
    },

    async bulkCreateUpload({ commit }, payload) {
      const result = {
        is_new: null,
        data: null,
        singleOffer: null,
        error: null,
        message: null,
      };
      try {
        const searchBrands = await API.get(`brands`, {
          params: { name: payload.name, vendor_id: payload.vendor_id }
        });
        let brand = searchBrands.data?.data.rows
          .find(val => {
            const checkName = (val.name.toLowerCase() == payload.name.toLowerCase());
            const checkVendor = (val.vendor_id == payload.vendor_id);
            return checkName && checkVendor
          });
        if (!brand?.id) {
          const newBrand = await API.post(`brands`, {
            created: new Date(),
            name: payload.name,
            brand_code: 'BLK_' + util.getFirstLetters(payload.name),
            vendor_id: payload.vendor_id,
            is_active: true,
            slug: util.toSlug(payload.name) + (+new Date()),
            default_item_type: payload.item_type,
            default_item_schedule: constant.BRAND_TYPE.EDITION,
            release_period: constant.BRAND_RELEASE_PERIOD.DAILY,
            default_languages: payload.languages,
            default_countries: payload.countries,
            offers_premium: payload.offers_premium,
          });
          brand = newBrand.data?.data;
        }
        payload.brand_id = brand.id;
        payload.edition_code = brand.brand_code.toUpperCase() + (+new Date());

        const searchItem = await API.get(`items-search/${payload.item_type}?q=${payload.name}`);
        let item = searchItem.data?.data.rows
          .find(val => {
            const checkName = (val.name.toLowerCase() == payload.name.toLowerCase());
            const checkBrand = (val.brand_id == payload.brand_id);
            return checkName && checkBrand;
          });
        if (item?.id) {
          result.is_new = false;
        } else {
          item = await API.post(`items/${payload.item_type}`, payload);
          item = item.data?.data.rows[0];
          result.is_new = true;
        }
        item = await API.get(`items/${item.id}`);
        item = item.data?.data.rows[0];
        
        if(!item.single.length) {
          await API.post(`offers`, {
            item_id: item.id,
            android_tier_id: payload.android_tier.id,
            ios_tier_id: payload.ios_tier.id,
            name: payload.name,
            offer_status: constant.OFFER_STATUS.READY_FOR_SALE,
            price_usd: Math.max(payload.android_tier.tier_price, payload.ios_tier.tier_price),
            price_idr: Math.max(payload.android_tier.price_idr, payload.ios_tier.price_idr),
            is_active: true,
            is_free: false,
            offer_type_id: constant.OFFER_TYPE.SINGLE,
            offer_code: item.edition_code,
            item_code: item.edition_code,
          });
        }

        item = await API.get(`items/${item.id}`);
        result.data = item.data?.data.rows[0];

        return result;
      } catch (error) {
        commit("setLoading", false);
        const {
          statusCode,
          statusText,
          message,
          data,
        } = destructErrResponse(error);
        let customMessage = data?.data?.message;

        if (statusCode == 422) {
          customMessage = statusText;
          customMessage += ' REQUIRED ';
          customMessage += (data.errors ?? []).map(item => item.param).join(', ');
        }

        result.error = true;
        result.message = customMessage ?? message;
        return result;
      }

    },
    async bulkUnPublish({ commit }, payload) {	
      commit('setLoading', true);	
      try {	
        const response = await API.put(`items/bulk/unpublished`, {	
          items_id: payload.items_id,	
        });	
        commit('setLoading', false);	
        return response;	
      } catch (error) {	
        commit('setLoading', false);	
        return error;	
      }	
    },	

    async updateItems({ commit }, payload) {
      commit("setLoading", true);
      const result = {
        error: false,
        data: {},
        message: '',
      }
      try {
        const response = await API.put(`items/${payload.id}`, payload);
        result.data = response.data?.data.rows[0];
        if (payload.price_usd) {
          const responseItems = await API.get(`items/${payload.id}`);
          const singleOffers = responseItems.data?.data?.rows[0]?.single[0];
          if (!singleOffers) {
            result.error = true;
            result.message = 'the item does not have a single offer';
            return result;
          }
          await API.put(`offers/${singleOffers.id}`, {
            android_tier_id: payload.android_tier.id,
            ios_tier_id: payload.ios_tier.id,
            offer_status: payload.offer_status,
            price_usd: payload.ios_tier.tier_price,
            price_idr: payload.ios_tier.price_idr,
          });
        }
        return result;
      } catch (error) {
        commit("setLoading", false);
        const {
          statusCode,
          statusText,
          message,
          data,
        } = destructErrResponse(error);
        let customMessage = data?.data?.message;

        if (statusCode == 422) {
          customMessage = statusText;
          customMessage += ' REQUIRED ';
          customMessage += (data.errors ?? []).map(item => item.param).join(', ');
        }

        result.error = true;
        result.message = customMessage ?? message;
        return result;
      }
    },

    async uploadCover({ commit }, payload) {
      commit('setLoading', true);
      try {
       const formData = new FormData();
        formData.append('item_id', payload.item_id);
        formData.append(`file_cover`, payload.file_cover);
        const response = await API.post(`upload/cover`, formData);
        commit('setLoading', false);
       return response;
      } catch (error) {
        commit('setLoading', false);
        return error.response ?? error;
      }
    },
    async uploadPreview({ commit }, payload) {
      commit('setLoading', true);
      try {
        const formData = new FormData();
        formData.append('file_preview', payload.file_preview);
        formData.append('item_id', payload.id);
        formData.append('preview_page', payload.page);
        const response = await API.post(`upload/preview`, formData);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error.response ?? error;
      }
    },
  },
  mutations: {
    setLoading(state, data) {
      state.isLoading = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setItems(state, data){
      state.items = data;
    }
  },
};


export default ebooks;
