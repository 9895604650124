const UserListPath = {
  path: '/settings/user',
  name: 'UserListPublisher',
  component: () => import('../views/settings/vendor/userList.vue'),
};
const AddUserVendorPath = {
  path: '/settings/user/create',
  name: 'AddUserPublisher',
  component: () => import('../views/settings/vendor/addUser.vue'),
};
const EditUserVendorPath = {
  path: '/settings/user/update/:id',
  name: 'EditUserPublisher',
  component: () => import('../views/settings/vendor/editUser.vue'),
};
const SidebarMenuListPath = {
  path: '/settings/sidebar-menu',
  name: 'sidebarMenus',
  component: () => import('../views/sidebar_menu/sidebarMenusList.vue'),
};
const SidebarMenuUpdatePath = {
  path: '/setting/sidebar-menu/update/:id',
  name: 'sidebarMenuUpdate',
  component: () => import('../views/sidebar_menu/updateMenu.vue'),
};
const FinanceReport = {
  path: '/setting/finance-reports',
  name: 'finance-report',
  component: () => import('../views/reports/finance-report/list.vue'),
};
const VendorReportFormat = {
  path: '/setting/vendor-report-format',
  name: 'vendor-report-format',
  component: () => import('../views/settings/vendor/vendorReportFormat.vue'),
};
const ConfigManament = {
  path: '/setting/otp-management',
  name: 'otp-management',
  component: () => import('../views/settings/otp/configManagement.vue'),
}
const EditConfigManagement = {
  path: '/setting/otp-management/edit',
  name: 'otp-management-edit',
  component: () => import('../views/settings/otp/editConfigManagement.vue'),
}
module.exports = {
  UserListPath,
  AddUserVendorPath,
  EditUserVendorPath,
  SidebarMenuListPath,
  SidebarMenuUpdatePath,
  FinanceReport,
  VendorReportFormat,
  ConfigManament,
  EditConfigManagement
};
