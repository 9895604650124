var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-4 bg-white rounded-lg shadow-md"
  }, [_c('label', {
    staticClass: "ml-4 text-base block mb-4"
  }, [_vm._v(" Popular Reading - " + _vm._s(_vm.currentMonth))]), _c('apexchart', {
    attrs: {
      "type": "bar",
      "height": "320",
      "options": _vm.chartReading,
      "series": _vm.ReadingSeries
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }