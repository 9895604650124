import API from "../plugins/http";
const Orders = {
  namespaced: true,
  state: {
    items: [],
    financeReport:[],
    item_wallet: [],
    totalWallet: 0,
    totalRows: 0,
    readings: [],
    totalReadings: 0,
    totalRevenue: 0,
    isLoading: false,
    isDownload: false,
    isError: false,
    errorMessage: null,
    successMessage: null,
    totalAmount: 0,
    totalWalletAmount: 0,
    populars: [],
    detailRevenue: [],
    isLoadingDetailRevenue: false,
  },
  getters: {
    subledger: (state) => state.items,
    amount: (state) => state.items.reduce((acc, item) => {
      return acc + item.total
    })
  },

  actions: {
    async fetchReport({ commit }, state) {
      try {
        let url
        if (state.vendor_id && state.offer_type) {
          url = `report-subledger?from=${state.from}&to=${state.to}&page=${state.page}&limit=${state.limit}&vendor_id=${state.vendor_id}&offer_type=${state.offer_type}`
        } else if (state.vendor_id) {
          url = `report-subledger?from=${state.from}&to=${state.to}&page=${state.page}&limit=${state.limit}&vendor_id=${state.vendor_id}`
        } else if (state.offer_type) {
          url = `report-subledger?from=${state.from}&to=${state.to}&page=${state.page}&limit=${state.limit}&offer_type=${state.offer_type}`
        } else {
          url = `report-subledger?from=${state.from}&to=${state.to}&page=${state.page}&limit=${state.limit}`
        }
        commit("setLoading", true);
        commit("setItems", []);
        const response = await API.get(url);

        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status == 403) {
          let err = response.data.message
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
      }
    },

    async fetchFinanceReport({ commit }, state) {
      commit("setFinanceReport", []);
        
      try {
        let url
        if (state.vendor_id && state.offer_type) {
          url = `finance-report/summary?from=${state.from}&to=${state.to}&vendor_id=${state.vendor_id}&offer_type=${state.offer_type}`
        } else if (state.vendor_id) {
          url = `finance-report/summary?from=${state.from}&to=${state.to}&vendor_id=${state.vendor_id}`
        } else if (state.offer_type) {
          url = `finance-report/summary?from=${state.from}&to=${state.to}&offer_type=${state.offer_type}`
        } else {
          url = `finance-report/summary?from=${state.from}&to=${state.to}`
        }
        commit("setLoading", true);
        const response = await API.get(url);
        commit("setFinanceReport", response.data.data);
        commit("setLoading", false);
        
      } catch (error) {
        let response = error.response
        commit("setFinanceReport", []);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status == 403) {
          let err = response.data.message
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
      }
    },

    async fetchReportByVendor({ commit }, state) {
      //report-single
      try {
        commit("setItems", []);
        commit("setLoading", true);
        const response = await API.get(
          `report-subledger/buffets/vendor${state.id}?from=${state.from}&to=${state.to}`
        );

        if (response.data.data.count > 0) {

          commit("setItems", response.data.data.rows);
          commit("setRows", response.data.data.count);
          commit("setPopulars", response.data.data.populars);
          commit("setLoading", false);
        }
        commit("setLoading", false);

      } catch (error) {
        let response = error.response
        commit("setError", true);
        commit("setLoading", false);
        if (response.status == 403) {
          let err = response.data.message
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
        return error;
      }
    },
    async fetchReportByVendorOffers({ commit }, state) {
      //report-single
      try {
        commit("setItems", []);
        commit("setLoading", true);
        commit("setPopulars", []);
        commit("setWallet", []);
        const response = await API.get(
          `report-subledger/vendor/${state.id}?offer_type=${state.offer_type}&period=${state.period}&page=${state.page}&limit=${state.limit}`
        );
        let data = response.data.data
        if (response.data.data.count > 0) {
          let amount = parseFloat(data.amount,10)
          console.log(amount)
          commit("setWallet", response.data.data.rows);
          commit("setRowWallet", response.data.data.count);
          commit("setLoading", false);
          commit("setPopulars", response.data.data.popular);
          commit("setWalletAmount", amount.toFixed(2));
        }
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setError", true);
        commit("setLoading", false);
        commit("setRowWallet", 0)
        commit("setWalletAmount", 0);
        if (response && response.status == 403) {
          let err = response.data.message
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
        return error;
      }
    },

    async fetchByTransaction({ commit }, state) {
      try {
        commit("setReadings", []);
        commit("setLoadingDetail", true);
        commit("setRowReadings", 0);
        commit("setTotalRevenue", 0)
        const response = await API.get(
          `detail-ledger/${state.trans_id}`
        );
        let data = response.data.data;
        if (response.data.data.count > 0) {
          let amount = data.rows.reduce((a, b) => parseFloat(a) + parseFloat(b.publisher_revenue), 0)
          // sort by vendor_id
          data.rows.sort((a, b) => (a.vendor_name > b.vendor_name) ? 1 : -1)
          commit("setReadings", response.data.data.rows);
          commit("setRowReadings", response.data.data.count);
          commit("setLoadingDetail", false);
          commit("setTotalRevenue", amount)
        }
        commit("setLoadingDetail", false);

      } catch (error) {
        let response = error.response
        commit("setError", true);
        commit("setLoadingDetail", false);
        if (response && response.status == 403) {
          let err = response.data.message
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
        return error;
      }
    },

    async fetchByTransactionNonPremium({ commit }, state) {
      try {
        commit("setLoadingDetail", true);
        commit("setDetailNonPremium", []);
        const response = await API.get(
          `detail-ledger/${state.trans_id}`
        );
        commit("setLoadingDetail", false);
        commit("setDetailNonPremium", response.data.data.rows);

      } catch (error) {
        let response = error.response
        commit("setError", true);
        commit("setLoadingDetail", false);
        if (response && response.status == 403) {
          let err = response.data.message
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
        commit("setLoadingDetail", false);
        return error;
      }
    },

    async sendReportByPeriod({ commit }, state) {
      commit("setErrorMessage", false);
      commit("setLoadingDownload", true);
      commit("setSuccessMessage", null);
      try {
        const response = await API.post(`send-report/subledger`, state);

        commit("setLoadingDownload", false);
        commit("setSuccessMessage", response.data.message);

        return response;
      } catch (error) {
        let response = error.response;

        commit("setLoadingDownload", false);
        if (response.status == 404) {
          commit("setErrorMessage", response.data.message);
        }

        return response;
      }
    }
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setFinanceReport(state, item) {
      state.financeReport = item
    },
    setWallet(state, item) {
      state.item_wallet = item;
    },
    setReadings(state, item) {
      state.readings = item;
    },
    setRowReadings(state, item) {
      state.rowReadings = item;
    },
    setTotalRevenue(state, item) {
      state.totalRevenue = item;
    },
    setAmount(state, item) {
      state.totalAmount = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setRowWallet(state, rowWallet) {
      state.totalWallet = rowWallet;
    },
    setWalletAmount(state, amount) {
      state.totalWalletAmount = amount;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setLoadingDownload(state, data) {
      state.isDownload = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;

    },
    setPopulars(state, data) {
      state.populars = data;
    },
    setDetailNonPremium(state, data) {
      state.detailRevenue = data;
    },
    setLoadingDetail(state, data) {
      state.isLoadingDetailRevenue = data;
    }
  },
};

export default Orders;
