import API from "../plugins/http";
//import EventBus from '../event-bus'
const Orders = {
  namespaced: true,
  state: {
    items: [],
    descriptionAndTitle:[],
    subscription: [],
    isLoading: false,
    duplicateItem:null,
    isSubLoading: false,
    errorMessage: '',
    successMessage:'',
    aggregates: [],
    isError: false,
    isSubError: false,
    subErrorMessage: null,
  },
   actions: {
    async fetchSingle({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setItems", []);
        const response = await API.get(
          `report-content?from=${state.from}&to=${state.to}&offer_type=${state.offer_type}&page=${state.page}&limit=${state.limit}`
        );
        let data = response.data.data;
        commit("setItems", data);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response;
        commit("setError", true);
        commit("setLoading", false);
        if (response && response.status == 403) {
          let err = response.data.message;
          commit("setErrorMessage", err);
        } else {
          commit("setErrorMessage", error.response);
        }
        return error;
      }
    },

    async listRecomendasi({ commit },query) {
      commit('setLoading', true);
      commit("setItems", []);
      try {
        const response = await API.get(`recommendations-list?title=${query.q}`);
        const data = response.data.data;
        commit("setItems", data);
    
        commit('setLoading', false);
      } catch (error) {
        return error
      }
    },
    async detailRecomendationId({commit},id) {
    try {
      const response = await API.get(`recommendations-list/detail/${id}`);
      const data = response.data.data;
      commit("setItems", data); 
      commit('setLoading', false);
    } catch (error) {
      return error
    }
    },
    checkDuplicate({ commit, state }, items) {
      commit('addArrayItems', items);
      return Promise.resolve(state.duplicates);
    },
    async detailRecomendationTitleDescription({commit},id) {
      
    try {
      const response = await API.get(`recommendations-list/${id}`);
      const data = response.data.data;
      commit("setDescriptionAndTitle", data);
      commit('setLoading', false);
    } catch (error) {
      return error
    }
    },


      
    async EditRecomendationTitleDescription({commit},payload) { 
    try {
      const dataEdit = {
        'title':payload.title,
        'description':payload.description
      }
      const response = await API.put(`recommendations-list/${payload.id}`,dataEdit);
     const data = response.data.data;
     commit("setDescriptionAndTitle", data);
      commit('setLoading', false);
    } catch (error) {
      return error
    }
    },
    async fetchCategoriRecomendation({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setItems", []);
        const response = await API.get(`layouts/${state}`);
        let data = response.data.data;
        commit("setItems", data);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response;
        commit("setError", true);
        commit("setLoading", false);
        if (response && response.status == 403) {
          let err = response.data.message;
          commit("setErrorMessage", err);
        } else {
          commit("setErrorMessage", error.response);
        }
        return error;
      }
    },
    async moveUptable ({commit},payload) {
      commit('setLoading', false);
      const offers = {
        offers: payload
      };
      try {
        const response = await API.put('layouts/offer/sort-priority',offers);
        commit('setLoading', false);
        return response
      } catch (error) {
        console.log(error.message);
      }

    },
    async generateFetchData({ commit }, id) {
      commit('setLoading',true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      const data = {
        recommendation_id:id,
      }
      try {
        const response = await API.post('recommendations-list/generate-recommendations',{recommendation_id:data.recommendation_id});
       commit('setItems', response.data.data)
        commit('setLoading', false);
      } catch (error) {
        console.log(error);
      }
    },

    async allSaveAndSubmit({commit},payload) {
        commit('setLoading', true)
        commit('setSuccessMessage', null);
       
        const itemIds = payload.items.map(item => item.item_id);
        const data = {
            item_ids: itemIds  
        };
       try {
        const response = await API.post(`/recommendations-list/detail/${payload.id}/reorder`,data);
       if (response.status === 200) { 
          commit('setSuccessMessage', "Update has been success");
          commit('setAlert', { message: 'Data has been updated', type: 'success' });
          commit('setLoading', false);
          
          return response.data; 
        }
    
      } catch (error) { 
        console.log(error)
      }
    },
   
   async getSearchItemContent({ commit }, query) {
    commit('setLoading', false);
    try {
     const response = await API.get(`recommendations-list/search-item?recommendation_id=${query.id}&type=${query.type}&q=${query.query}`);
      return response
    } catch (error) {
      return error
    }
  },
    async fetchSubscription({ commit }, state) {
      try {
        commit("setSubscription", []);
        const response = await API.get(
          `report-content?from=${state.from}&to=${state.to}&offer_type=${state.offer_type}&page=${state.page}&limit=${state.limit}`
        );
        let data = response.data.data;
        commit("setSubscription", data);
      } catch (error) {
        let response = error.response;
        commit("setSubError", true);
        commit("setSubLoading", false);
        if (response && response.status == 403) {
          let err = response.data.message;
          commit("setSubErrorMessage", err);
        } else {
          commit("setSubErrorMessage", error.response);
        }
        return error;
      }
    }
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setDescriptionAndTitle(state,item) {
      state.descriptionAndTitle = item
    },
    setAlert(state, payload) {
      state.alertMessage = payload.message;
      state.alertType = payload.type;
  },
    setSubscription(state, item) {
      state.subscription = item;
    },
    deleteItem(state, index) {
      state.items.splice(index,1);
    },
    
    // addArrayItems(state, items) {
    //   if (!Array.isArray(state.items)) {
    //     state.items = [];
    //   }
    //   items.forEach((newItem) => {
    //     const itemExists = state.items.some(item => item.item_id === newItem.item_id);
    //     console.log('check',itemExists)
    //     if (!itemExists) {
    //       state.items.push(newItem);
    //     }
    //   });
    // },
    
    addArrayItems(state, items) {
      if (!Array.isArray(state.items)) {
        state.items = [];
      }
      const duplicates = [];
  
      items.forEach((newItem) => {
        const itemExists = state.items.some(item => item.item_id === newItem.item_id);
        if (itemExists) {
          duplicates.push(newItem); // Simpan item duplikat
        } else {
          state.items.push(newItem); // Tambahkan item baru
        }
      });
  
      state.duplicates = duplicates; // Simpan duplikat di state
    },
    SET_ITEMS(state, newItems) {
      state.items = newItems;
    },
    SET_SORT_PRIORITY(state, { datadrag, newIndex }) {
      const oldIndex = state.items.findIndex(item => item.item_id === datadrag.item_id);
      state.items.splice(oldIndex, 1);
      state.items.splice(newIndex, 0, datadrag);
    },  
    setLoading(state, data) {
      state.isLoading = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setSubLoading(state, data) {
      state.isSubLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSubError(state, data) {
      state.isSubError = data;
    },
    setSubErrorMessage(state, data) {
      state.subErrorMessage = data;
    },
  },
};

export default Orders;
