import API from "../plugins/http";
const JSZip = require("jszip");
const ebooks = {
  namespaced: true,
  state: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    isError: false,
    items : [],
  },
  getters: {
  },
  actions: {  
    async checkAudioChapter({ commit },state) {
      commit ('setError',false);
      const { editionCode } = state;
      const map = {};
      try {
        const response = await API.get('/bulkUpload/checkAudioChapter/?editionCode=' +editionCode );
        map.result = response.data.data.result;
        map.message = response.data.data.message;
        map.release_schedule = response.data.data.release_schedule;
        map.item_status = response.data.data.item_status;
        map.id = response.data.data.id;
      }
      catch(error) {
        let message = error.response.data.message.message;
        let result = error.response.data.message.result;
        if (!message) {
          message = 'Internal Server Error';
        }
        map.message = message;
        map.result = result;
      }
      return map;
    },
    async checkEditionCode({ commit },state) {
      commit ('setError',false);
      const { editionCode } = state;
      const map = {};
      try {
        const response = await API.get('/bulkUpload/checkEditionCode/?editionCode=' +editionCode );
        map.result = response.data.data.result;
        map.message = response.data.data.message;
        map.release_schedule = response.data.data.item.release_schedule;
        map.name = response.data.data.item.name;
        map.item_status = response.data.data.item.item_status;
        map.id = response.data.data.item.id;
      }
      catch(error) {
        let message = error.response.data.message.message;
        let result = error.response.data.message.result;
        if (!message) {
          message = 'Internal Server Error';
        }
        map.message = message;
        map.result = result;
      }
      return map;
    },
    async checkChapter({ commit },state) {
      commit ('setError',false);
      const { item_id } = state;
      const { chapter } = state;
      const map = {};
      try {
        const response = await API.get(`/bulkUpload/checkChapter/?item_id=${item_id}&chapter=${chapter}` );
        map.result = response.data.data.result;
        map.message = response.data.data.message;
        map.item = response.data.data.item;
        map.id = response.data.data.id;
      }
      catch(error) {
        let message = error.response.data.message.message;
        let result = error.response.data.message.result;
        if (!message) {
          message = 'Internal Server Error';
        }
        map.message = message;
        map.result = result;
      }
      return map;
    },
    
    async uploadBulk({ commit }, payload) {
      commit('setError', false);
      const maps = {};
      maps.result = false;
      maps.message = '';
      try {
        const formData = new FormData();
        formData.append('file_content', payload.file_content);
        formData.append('id', payload.id);
        formData.append('item_status', payload.item_status);
        formData.append('release_schedule', payload.release_schedule);
        const response = await API.post(`upload`, formData);
        if (response.status == 200) {
          commit('setLoading', false);
          commit('setSuccessMessage', "Success Uploaded");
          maps.result = true;
          maps.message = 'Success Upload';
        } else {
          maps.message = 'Failed Upload';
        }
      } catch (error) {
        console.log('error', error);
        let { 
          statusCode,
          statusText,
          message,
          data,
        } = destructErrResponse(error);
        if (statusCode== 500){
          message = error.response.data.data.message;
        }
        if (statusCode == 422) {
          message = statusText;
          message += ' REQUIRED ';
          message += (data.errors ?? []).map(item => item.param).join(', ');
        }
        maps.message = message;
        commit('setLoading', false);
      }
      return maps;
    },
    
    async unzipFile({ commit }, payload) {
      commit('setLoading', false);
      const zipFile = new File([payload], payload.name, {type: payload.type});
      const flist = [];
      let resultbool = true;
      let messageZip = '';
      const zip = await JSZip.loadAsync(zipFile);
      for(let filename in zip.files) {
        const blob = await zip.file(filename).async('blob');
        let chapter = filename;
        const maps = {};
        maps.result = false;
        maps.chapter = null;
        maps.blob = blob;
        maps.file_content = null;
        maps.size = blob.size;
        if(blob.size>157286400) {
          messageZip = `Error, File ${filename} 's Size Must Not Bigger than 150 MB`;
          maps.message = messageZip;
          resultbool = false;
          break;
        } else {
          if (filename.endsWith(".mp3")) {
              chapter = filename.replace('.mp3','');
              if(!isNaN(chapter)){
              maps.result = true;
              maps.chapter = chapter;
              maps.message = 'Success Unziped File';
              const fileMusic = new File([blob], filename, { type: 'audio/mp3' });
              maps.file_content = fileMusic;
            } else {
              resultbool = false;
              messageZip = `Unsupported Title for File ${filename}, Only Filename With Number is Supported`;
              maps.message = messageZip;
              break;
            }
          } else {
            resultbool = false;
            messageZip = `Unsupported Format for File ${filename}, Only .mp3 File is Supported`;
            maps.message = messageZip;
            break;
          }
        }
        flist.push(maps);
      }
      return {resultbool, messageZip, flist};
    },
  },
  mutations: {
    setLoading(state, data) {
      state.isLoading = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setItems(state, data){
      state.items = data;
    }
  },
};
const destructErrResponse = (error) => {
  const statusCode = error.status ?? error.response?.status;
  const statusText = error.statusText ?? error.response?.statusText;
  const data = error.data ?? error.response?.data;
  let message = error.data?.message ?? error.response?.data?.message;
  
  return { statusCode, statusText, data, message};
}

export default ebooks;
