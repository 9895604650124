import API from '../plugins/http';
const Brands = {
  namespaced: true,
  state: {
    item: {},
    items: [],
    product: [],
    totalRowsProduct: 0,
    isLoadingProduct: false,
    totalRows: 0,
    isLoading: false,
    isError: false,
  },
  getters: {
    payments: (state) => state.items,
  },

  actions: {
    async fetchPayment({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `payments-gateways?paymentgateway=${state.name}&status=${state.status}&page=${state.page}&limit=${state.limit}`,
        );

        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async fetchAllPayment({ commit }) {
      try {
        const response = await API.get(`payments-gateways?allpayments=true`);
        commit('setItems', response.data.data.rows);
      } catch (error) {
        commit('setError', true);
      }
    },

    async searchPayments({ commit }, state) {
      try {
        const response = await API.get(`search-payments?name=${state.q}`);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },

    async fetchPaymentById({ commit }, state) {
      try {
        const response = await API.get(`payments-gateways/${state.id}`);
        commit('setItems', [response.data.data]);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },

    async getDetailPayment({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `payments-gateways/detail-payment/${state.orderID}/${state.paymentgateway}`,
        );
        commit('setItem', response.data?.data);
        commit('setLoading', false);
      } catch (error) {
        commit('setError', true);
        commit('setLoading', false);
      }
    },

    async updatePaymentType({ commit }, state) {
      commit('setItem', {});
      try {
        commit('setLoading', true);
        const response = await API.put(
          `payments-gateways/update-payment-type/${state.id}`,
          state,
        );
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error;
      }
    },
  },

  mutations: {
    setItem(state, item) {
      state.item = item;
    },
    setItems(state, item) {
      state.items = item;
    },
    setProduct(state, item) {
      state.product = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setRowsProduct(state, data) {
      state.totalRowsProduct = data;
    },
    setLoadingProduct(state, data) {
      state.isLoadingProduct = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
  },
};

export default Brands;
