const AppStoreNotification = {
  path: '/appstore-notification',
  name: 'AppStoreNotification',
  component: () => import('../views/appstore/appstore-notification.vue'),
};
const AppStoreNotificationDetail = {
  path: '/appstore-notification/:transaction_id',
  name: 'AppStoreNotificationDetail',
  component: () => import('../views/appstore/appstore-notification-detail.vue'),
};

module.exports = {
  AppStoreNotification,
  AppStoreNotificationDetail,
};
