import API from '../plugins/http';
const revenueshares = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    itemRevenue: {},
    revenue: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    successMessage: '',
    errorMessage: '',
  },
  getters: {
    revenueshares: (state) => state.revenueshares,
  },

  actions: {
    async fetchAllRevenueShares({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `revenueshare/v2?name=${state.name}&status=${state.status}&page=${state.page}&limit=${state.limit}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setError', true);
      }
    },

    async fetchRevenueShareByID({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(`revenueshare/v2/${state.id}`);
        commit('setItem', response.data.data);
        commit('setLoading', false);
      } catch (error) {
        commit('setError', true);
        commit('setLoading', false);
      }
    },

    async editRevenueShare({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await API.put(`revenueshare/v2`, state);
        commit('setLoading', false);
        if (response.status == 200) {
          commit(
            'setSuccessMessage',
            'Revenue Share Has Been Successfully Updated',
          );
        }
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        let errMsg = '';
        if (error.status == 403) {
          errMsg += error.data.message;
        } else {
          if (error.response.status == 422) {
            errMsg += error.response.data.message;
          } else {
            errMsg += error.response.data.data.message;
          }
        }

        commit('setErrorMessage', errMsg);
      }
    },

    async createRevenueShare({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await API.post('revenueshare/v2', state);
        if (response.status == 201) {
          commit(
            'setSuccessMessage',
            'Revenue Share Has Been Successfully Created',
          );
        }
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        let errMsg = [];
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setItemRevenue(state, item) {
      state.itemRevenue = item;
    },
    setRevenue(state, item) {
      state.revenue = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default revenueshares;
