'use strict';
import API from '../plugins/http';
const Ax = {
  namespaced: true,
  state: {
    items: [],
    itemsDraft: [],
    isError: false,
    isLoading: true,
    message: null,
    totalRows: 0,
  },
  getters: {
    ax: (state) => state.items,
    isError: (state) => state.isError,
    isLoading: (state) => state.isLoading,
  },

  actions: {
    async fetchItem({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `register-ax?page=${state.page}&limit=${state.limit}&q=${state.q}`,
        );
        commit('setItem', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async fetchItemDraft({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `register-ax/draft?page=${state.page}&limit=${state.limit}`,
        );
        console.log(response.data);
        commit('setItem', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async searchCategoryAx({ commit }, props) {
      commit('setLoading', true);
      try {
        const response = await API.get(`category-ax?name=${props.q}`);

        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
      }
    },
    async search({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(`register-ax?q=${state.q}`);
        commit('setItem', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async checkApprovedItem({ commit }, state) {
      commit('setLoading', false);
      return API.post('register-ax/check-status-approved', state);
    },
  },

  mutations: {
    setItem(state, item) {
      state.items = item;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setItemDraft(state, item) {
      state.itemsDraft = item;
    },
    setLoadingDraft(state, data) {
      state.isLoadingDraft = data;
    },
    setErrorDraft(state, data) {
      state.isErrorDraft = data;
    },
    setMessage(state, data) {
      state.message = data;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
  },
};

export default Ax;
