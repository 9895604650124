const AnnouncementManagePath = {
  path: '/settings/announcement',
  name: 'announcement',
  component: () => import('../views/announcements/manageAnnouncement.vue'),
};

const AnnouncementCreatePath = {
  path: '/settings/announcement/create',
  name: 'announcement-create',
  component: () => import('../views/announcements/newAnnouncement.vue'),
};

const AnnouncementEditPath = {
  path: '/settings/announcement/edit/:id',
  name: 'announcement-edit',
  component: () => import('../views/announcements/editAnnouncement.vue'),
};

module.exports = {
  AnnouncementManagePath,
  AnnouncementCreatePath,
  AnnouncementEditPath,
};
