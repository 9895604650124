const CustomersPath = {
  path: '/customers/list',
  name: 'customers',
  component: () => import('../views/users/user.vue'),
};

const CustomerDetailPath = {
  path: '/customers/detail/:id',
  name: 'customerDetail',
  component: () => import('../views/users/detail.vue'),
};

const CustomersBannedPath = {
  path: '/customers/banned',
  name: 'userBanned',
  component: () => import('../views/users/banned.vue'),
};

const userRenewal = {
  path: '/customers/user-renewal',
  name: 'user-renewal',
  component: () => import('../views/users/user-renewal.vue'),
};

const userReview = {
  path: '/customers/reviews',
  name: 'user-review',
  component: () => import('../views/reviews/reviews.vue'),
};

module.exports = {
  CustomersPath,
  CustomerDetailPath,
  CustomersBannedPath,
  userRenewal,
  userReview,
};
