var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboardContent"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row mt-2 mb-1"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v("Book")]), _c('b-button', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Magazines")]), _c('b-button', {
    attrs: {
      "variant": "dark"
    }
  }, [_vm._v("Newspaper")])], 1)], 1), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.actionGroup('day');
      }
    }
  }, [_vm._v(" Days")]), _c('b-button', {
    attrs: {
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.actionGroup('month');
      }
    }
  }, [_vm._v("Month")]), _c('b-dropdown', {
    attrs: {
      "right": "",
      "text": "Data Rage"
    }
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.actionshowCountMonth(1);
      }
    }
  }, [_vm._v(" 1 Month")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.actionshowCountMonth(3);
      }
    }
  }, [_vm._v(" 3 Month")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.actionshowCountMonth(6);
      }
    }
  }, [_vm._v(" 6 Month")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.actionshowCountMonth(12);
      }
    }
  }, [_vm._v(" 1 Year")])], 1)], 1)], 1), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('i', {
    staticClass: "fa fa-line-chart btn btn-sm",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function ($event) {
        return _vm.actionChangeChartType('line');
      }
    }
  }), _c('i', {
    staticClass: "fa fa-bar-chart btn btn-sm",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function ($event) {
        return _vm.actionChangeChartType('bar');
      }
    }
  }), _c('i', {
    staticClass: "fa fa-area-chart btn btn-sm",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function ($event) {
        return _vm.actionChangeChartType('area');
      }
    }
  })]), _c('div', {
    staticClass: "col-md-3"
  })]), _c('div', {
    staticClass: "row mb-2 pb-1"
  }, [_c('div', {
    staticClass: "col-md-9 shadow-sm p-1"
  }, [_c('RevenueLine', {
    attrs: {
      "type": _vm.typeChart,
      "height": 400,
      "options": _vm.options,
      "series": _vm.series
    }
  })], 1), _c('div', {
    staticClass: "col-md-3 pt-1 pb-1"
  }, [_vm._v(" " + _vm._s(_vm.loadChart ? 'Loading ...' : '') + " "), _vm.summary ? _c('div', {
    staticClass: "card p-2 rounded"
  }, [_c('label', {
    staticClass: "form-control-label text-black"
  }, [_vm._v("Summary ")]), _c('div', {
    staticClass: "card-body shadow-lg rounded"
  }, [_c('p', [_vm._v("Total : " + _vm._s(_vm.formatPriceBasic(_vm.summary.count)))]), _c('p', [_vm._v("Average : " + _vm._s(_vm.formatPriceBasic(_vm.summary.average)))]), _c('p', [_vm._v("Median : " + _vm._s(_vm.formatPriceBasic(_vm.summary.median)))]), _c('p', [_vm._v("Max : " + _vm._s(_vm.formatPriceBasic(_vm.summary.max)))]), _c('p', [_vm._v("Min : " + _vm._s(_vm.formatPriceBasic(_vm.summary.min)))])])]) : _vm._e(), _vm.summary ? _c('div', {
    staticClass: "card p-2 rounded"
  }, [_c('label', {
    staticClass: "form-control-label text-black"
  }, [_vm._v("Summary Type ")]), _c('div', {
    staticClass: "card-body shadow-lg rounded"
  }, [_c('p', [_vm._v(" Book :" + _vm._s(_vm.formatPriceBasic(_vm.summary.books)) + " ")]), _c('p', [_vm._v(" Magazines :" + _vm._s(_vm.formatPriceBasic(_vm.summary.magazines)) + " ")]), _c('p', [_vm._v(" Newspaper :" + _vm._s(_vm.formatPriceBasic(_vm.summary.newspaper)) + " ")])])]) : _vm._e()])])]), _c('br'), _c('div', {
    staticClass: "mt-2 pt-2 col-md-12"
  }, [_c('div', {
    staticClass: "shadow-sm pb-5 mb-2"
  }, [_c('ReadingChart')], 1)]), _c('b-overlay', {
    attrs: {
      "show": _vm.busy,
      "no-wrap": ""
    },
    on: {
      "shown": _vm.onShown,
      "hidden": _vm.onHidden
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return undefined;
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-2"
  }, [_c('h4', {
    staticClass: "h4 text-left"
  }, [_vm._v(" Content ")])]);
}]

export { render, staticRenderFns }