import API from "../plugins/http";
const Orders = {
  namespaced: true,
  state: {
    items: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    totalAmount:0,
    totalAmountD:0,
    errorMessage:null
  },
  getters: {
    single: (state) => state.items,
  },

  actions: {
    async fetchReport({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `report-subscriptions?from=${state.from}&to=${state.to}&page=${state.page}&limit=${state.limit}`
        );

        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    /** summary (count by offer_code or uniq item) */
    async fetchReportSingle({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `report-subscriptions-summary?from=${state.from}&to=${state.to}`
        );
        console.log(response.data.data);
        commit("setItems", response.data.data);
        commit("setRows", response.data.data);
        commit("setLoading", false);
        return response;
      } catch (error) {
        let response = error.response
        commit("setError", true);
        commit("setLoading", false);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",response)
        }
        return error;
      }
    },
    async fetchReportByVendor({ commit }, state) {
      //report-subscriptions
      try {
        commit("setItems",[]);
        commit("setLoading", true);
        const response = await API.get(
          `report-subscriptions/vendor/${state.id}?from=${state.from}&to=${state.to}`
        );
        let amount = response.data.data

        if (response.data.data.count > 0) {
          
          commit("setItems", response.data.data.rows);
          let data = amount.total
          let idr =data.filter(e => e.currency =='IDR')
          let usd =data.filter(e => e.currency =='USD')
          commit("setAmount", idr.length > 0 ? idr[0].amount: 0);
          commit("setAmountD",usd.length > 0 ? usd[0].amount : 0);
          commit("setRows", response.data.data.count);
          commit("setLoading", false);
          return response;
        } else {
          commit("setLoading", false);
          commit("setItems",[]);
          commit("setAmount", 0);
          commit("setAmountD", 0);
          commit("setRows", 0);
          commit("setLoading", false);
        }
      } catch (error) {
        commit("setError", true);
        commit("setLoading", false);
        let response = error
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",response)
        }
        return error;
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setAmount(state, item) {
      state.totalAmount = item;
    },
    setAmountD(state, item) {
      state.totalAmountD = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default Orders;
