"use strict";
import API from "../plugins/http";

const Refund = {
  namespaced: true,
  state: {
    items: [],
    isError: false,
    isLoading: false,
    ErrorMessage: null,
    totalRows: 0,
  },
  actions: {
    async fetchRefundsGoogle({ commit }, state) {
      try {
        commit("setLoading", true);
        let url = `google-iab-voided?page=${state.page}&limit=${state.limit}`;
        if (state.q) {
          url += `&q=${state.q}`;
        }
        const response = await API.get(url);
        commit("setItem", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
  },

  mutations: {
    setItem(state, item) {
      state.items = item;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setMessage(state, data) {
      state.ErrorMessage = data;
    },
    setRows(state, data) {
      state.totalRows = data;
    }
  },
};

export default Refund;
