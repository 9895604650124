<template>
  <div class="home mt-2" v-if="!isLoading">
    <div v-if="ebooks && roleID ==1 || ebooks && roleID ==2">
      <Dashboard />
    </div>
    <div v-else-if="ebooks && roleID =='14'"   class="row card">
      <dashboardContentVue/>
    </div>
    <div v-else>
      <div class="row card">
        <div class="col-md-12">
          <DashboardVendor />
        </div>
      </div>
    </div>
  </div>
  <!-- Skeleton-->
  <div v-else>
    <div class="row mt-3">
      <div v-for="c in rowsCard" :key="c" class="col-md-4 text-center">
         <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-card>
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
              </b-card>
            </template>
          </b-skeleton-wrapper>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <b-card>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
            </b-card>
          </template>
        </b-skeleton-wrapper>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <b-card>
              <b-skeleton-table
                :rows="5"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </b-card>
          </template>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardVendor from './dashboard-vendor.vue'
import Dashboard from './dashboard.vue'
import dashboardContentVue from './dashboardContent.vue';
export default {
  name: "Home",
  components: {
    Dashboard,
    DashboardVendor,
    dashboardContentVue
  },
  data() {
    return {
      isLoading: true,
      ebooks: false,
      rowsCard:3,
      roleID: JSON.parse(localStorage.getItem("session_auth")).role_id
    }
  },
  mounted() {
    let auth =  JSON.parse(localStorage.getItem("session_auth"))
    this.ebooks = auth.group=='ebook' ? true : false
    this.isLoading = false
  },
  watch: {
    $route: {
        immediate: true,
        handler(to) {
            document.title = to.meta.title || 'Dashboard | Content Management System Ebooks Gramedia.com';
        }
    },
  }
};
</script>
<style lang="css" scoped>
.app {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>