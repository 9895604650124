import API from '../plugins/http';

const Profile = {
  namespaced: true,
  state: {
    item_id :{},
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMessage: '',
    successMessage:'',
    refresh: false,
    status:'',
    isSuccess: false,
    username:'',
    email:'',
    key:null,
    qrCode:null,
    vendor_id: null,

  },

  getters: {
  },
  actions: {
    async fetchProfile({ commit }) {
    commit('setLoading', true);
    commit('setSuccess',false);
    commit('setError',false);
    commit('setSuccessMessage',null);
    try {
      const response = await API.get("profile");
      let data=response.data.data

      commit("setVendorId",data[0]?.data.vendor_id);
      
      if(data.length>0){
        if(data[0].result==true){
          commit("setSuccess",true);
          commit("setItem_id",data[0].data );
          commit("setLoading",false);
          commit("setSuccessMessage",data[0].message);  
          commit("setUsername",data[0].data.username);
          commit("setEmail",data[0].data.email);
        }else{
          commit("setSuccess",false);
          commit("setSuccessMessage",data[0].message);  
          commit("setLoading",false);
          commit('setError',true);
        }
      }else{
        commit("setSuccess",false);
        commit("setSuccessMessage","No Account Exist");
        commit("setLoading",false);
        commit('setError',true);
      }
    } catch (error) {
      commit("setSuccess",false);
      commit('setError',true);
      commit("setSuccessMessage", "Error System");
      commit("setLoading",false);
    }
    },
    async changePassword({ commit }, props) {
      commit('setLoading', false);
      commit('setSuccessMessage', null);
      commit('setErrorMessage', null);
      try {
        commit('setLoading', true);
        await API.put('profile/change-password', props);
        commit('setSuccessMessage', 'Success Change');
        commit("setLoading",false);
      } catch (error) {
        commit('setLoading', false);
        commit('setErrorMessage','Oops! Something went wrong. Please try again');
      }
    },
    async generateSecretKey({ commit }) {
      commit('setLoading', true);
      commit('setSuccessMessage', null);
      commit('setKey',null);
      commit('setQrCode',null);
      try {
        const response = await API.get(`updateSecretKey/get`);
        let data=response.data.data
        if(data.length>0){
          if(data[0].result==true){
            commit("setLoading",false);
            commit("setSuccessMessage",data[0].message);
            commit('setKey',data[0].secretKey);
            commit('setQrCode',data[0].qrCode);
          }else{
            commit("setSuccessMessage",data[0].message);
            commit("setLoading",false);
            commit('setError',true);
          }
        }else{
          commit("setSuccess",false);
          commit('setError',true);
          commit("setSuccessMessage","Link Not Found");
          commit("setLoading",false);
        }
      } catch (error) {
        const statusCode = error.status ?? error.response?.status;
        let message = error.data?.message ?? error.response?.data?.message;
        if (statusCode == 422) {
          const attr = error.response?.data.data?.map(val => val.param).join(' ,');
          message += ` (${attr})`;
        }
        if (statusCode == 404) {
          message = error.response?.data.data?.message ?? message;
        }
        if (statusCode == 409) {
          message = error.response?.data.data?.message ?? message;
        }
        commit("setSuccess",false);
        commit("setSuccessMessage",'Error System: '+ message);
        commit("setLoading",false);
        commit('setPage',false);
        
      }
    }
    ,async saveSecretKey({ commit }, state) {
      commit('setLoading', true);
      commit('setSuccess',false);
      commit('setError',false);
      commit('setSuccessMessage',null);
      try {
        const response = await API.post("updateSecretKey/update", state);
        let data=response.data.data
        if(data.length>0){
          if(data[0].result==true){
            commit("setSuccess",true);
            commit("setLoading",false);
            commit("setSuccessMessage",data[0].message);  
          }else{
            commit("setSuccess",false);
            commit("setSuccessMessage",data[0].message);  
            commit("setLoading",false);
            commit('setError',true);
          }
        }else{
          commit("setSuccess",false);
          commit("setSuccessMessage","No Account Exist");
          commit("setLoading",false);
          commit('setError',true);
        }
      } catch (error) {
        commit("setSuccess",false);
        commit('setError',true);
        commit("setSuccessMessage", "Error System");
        commit("setLoading",false);
      }
    },
    async changeEmail({ commit }, state) {
      commit("setLoading", true);
      try {
        const response = await API.put('profile/change-email', {
          email: state.email,
          new_email:state.new_email
        });
        console.log('Response:', response.data);
        commit("setLoading", false);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  },mutations: {
    setRows(state, data) {
      state.totalRows = data;
    },setLoading(state, data) {
      state.isLoading = data;
    },setError(state, data) {
      state.isError = data;
    },setItem_id(state,data){
      state.item_id = data;
    },setSuccessMessage(state, data) {
      state.successMessage = data;
    },setErrorMessage(state, data) {
      state.errorMessage = data;
    },setRefresh(state, data) {
      state.refresh = data;
    },setStatus(state, data) {
      state.status = data;
    },setSuccess(state, data) {
      state.isSuccess = data
    },setUsername(state, data){
      state.username= data
    },setEmail(state,data){
      state.email = data
    },setKey(state,data){
      state.key = data;
    },setQrCode(state,data){
      state.qrCode = data;
    },setVendorId(state,data){
      state.vendor_id = data;
    },
  },
};
export default Profile;
