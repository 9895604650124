import API from "../plugins/http";
const Orders = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: true,
    isLodingBtnSearch: false,
    isError: false,
    isLoadingOne: false,
    isErrorOne: false,
    isErrorPatch: false,
  },
  getters: {
    ofs_orders: (state) => state.items,
    orderPaid: (state) => {
      return state.items.filter((o) => o.order_status === 90000);
    },
    orderError: (state) => {
      return state.items.filter(
        (o) => o.order_status !== 90000 && o.order_status !== 20001
      );
    },
  },

  actions: {
    async fetchOrders({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `ofs-orders?page=${state.page}&limit=${state.limit}`
        );

        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async fetchOrdersById({ commit }, state) {
      try {
        const response = await API.get(`ofs-orders/detail/${state.id}`);

        commit("setItem", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoadingOne", false);
      } catch (error) {
        commit("setItem", []);
        commit("setLoadingOne", false);
        commit("setErrorOne", true);
      }
    },
    async searchOrders({ commit }, props) {
      try {
        commit("setLoadingBtn", true);
        const response = await API.get(
          `search-ofs-orders?userid=${props.userid}&page=${props.page}&limit=${props.limit}`
        );
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoadingBtn", false);
        return response;
      } catch (error) {
        commit("setItem", []);
        commit("setLoadingOne", false);
        commit("setErrorOne", true);
        return error;
      }
    },
    async patchOrders({ commit }, props) {
      try {
        return await API.post("customer-patch", props);
      } catch (error) {
        commit("setErrorOne", true);
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.items = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setLoadingOne(state, data) {
      state.isLoadingOne = data;
    },
    setLoadingBtn(state, data) {
      state.isLodingBtnSearch = data;
    },
    setErrorOne(state, data) {
      state.isErrorOne = data;
    },
    setErrorPatch(state, data) {
      state.isErrorPatch = data;
    },
  },
};

export default Orders;
