var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isLoading ? _c('div', {
    staticClass: "home mt-2"
  }, [_vm.ebooks && _vm.roleID == 1 || _vm.ebooks && _vm.roleID == 2 ? _c('div', [_c('Dashboard')], 1) : _vm.ebooks && _vm.roleID == '14' ? _c('div', {
    staticClass: "row card"
  }, [_c('dashboardContentVue')], 1) : _c('div', [_c('div', {
    staticClass: "row card"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('DashboardVendor')], 1)])])]) : _c('div', [_c('div', {
    staticClass: "row mt-3"
  }, _vm._l(_vm.rowsCard, function (c) {
    return _c('div', {
      key: c,
      staticClass: "col-md-4 text-center"
    }, [_c('b-skeleton-wrapper', {
      attrs: {
        "loading": _vm.isLoading
      },
      scopedSlots: _vm._u([{
        key: "loading",
        fn: function () {
          return [_c('b-card', [_c('b-skeleton', {
            attrs: {
              "width": "85%"
            }
          }), _c('b-skeleton', {
            attrs: {
              "width": "55%"
            }
          }), _c('b-skeleton', {
            attrs: {
              "width": "70%"
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('b-skeleton-wrapper', {
    attrs: {
      "loading": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function () {
        return [_c('b-card', [_c('b-skeleton', {
          attrs: {
            "width": "100%"
          }
        }), _c('b-skeleton', {
          attrs: {
            "width": "100%"
          }
        }), _c('b-skeleton', {
          attrs: {
            "width": "100%"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('b-skeleton-wrapper', {
    attrs: {
      "loading": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function () {
        return [_c('b-card', [_c('b-skeleton-table', {
          attrs: {
            "rows": 5,
            "columns": 4,
            "table-props": {
              bordered: true,
              striped: true
            }
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }