import API from "../plugins/http";

const SO = {
  namespaced: true,
  state: {
    items: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMessage: null,
    payloadSo: [],
    totalRowsSo: 0,
    isLoadingSo: false,
    isErrorSo: false,
    errorMessageSo: null,
    totalAmount: 0,
  },
  actions: {
    async fetchData({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setItems", []);
        let url = `so?page=${state.page}&limit=${state.limit}`;
        if (state.q) {
          url = `so?q=${state.q}&page=${state.page}&limit=${state.limit}`;
        }
        const response = await API.get(url);

        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response;
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status == 403) {
          let err = response.data.message;
          commit("setErrorMessage", err);
        } else {
          commit("setErrorMessage", error.response);
        }
      }
    },

    async fetchGenerateSO365({ commit }, state) {
      try {
        commit('setLoadingSO', true);
        commit("setSO", []);
        commit("setErrorSO", false);
        commit("setErrorMessageSO", null);
        const response = await API.post(`sales-order-365`, state);

        commit("setSO", response.data.data.rows);
        commit("setRowsSO", response.data.data.count);
        commit("setLoadingSO", false);
        commit("settotalAmount", response.data.data?.amount);
      } catch (error) {
        let response = error.response;
        commit("setSO", []);
        commit("setRowsSO", 0);
        commit("setLoadingSO", false);
        commit("setErrorSO", true);
        commit("settotalAmount", 0);
        if (response.status == 403) {
          let err = response.data?.message;
          commit("setErrorMessageSO", err);
        } else if (response.status == 422) {
          let errorMessage = response.data?.errors;
          commit("setErrorMessageSO", errorMessage);
        } else {
          commit("setErrorMessageSO", error.response);
        }
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSO(state, data) {
      state.payloadSo = data;
    },
    setRowsSO(state, data) {
      state.totalRowsSo = data;
    },
    setLoadingSO(state, data) {
      state.isLoadingSo = data;
    },
    setErrorSO(state, data) {
      state.isErrorSo = data;
    },
    setErrorMessageSO(state, data) {
      state.errorMessageSo = data;
    },
    settotalAmount(state, data) {
      state.totalAmount = data;
    },
  },
};

export default SO;
