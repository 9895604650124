import API from "../plugins/http";
const Orders = {
  namespaced: true,
  state: {
    items: [],
    isLoading: false,
    item_wallet: [],
    totalWallet: 0,
    totalRows: 0,
    readings: [],
    totalReadings: 0,
    totalRevenue: 0,
    isError: false,
    errorMessage: null,
    totalAmount: 0,
    totalWalletAmount: 0,
    populars: [],
    aggregates: [],
    isDownload: false,
  },
  getters: {

  },
  actions: {
    async fetchReport({ commit }) {

      try {
        commit("setLoading", true);
        const response = await API.get(
          `finance-report/ready`
        );
        commit("setLoading", false);
        commit("setItems", response.data.data);
      } catch (error) {
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async fetchReportOffers({ commit }, state) {
      //report-single
      try {
        commit("setItems", []);
        commit("setLoading", true);
        commit("setPopulars", []);
        const response = await API.get(
          `finance-report?offer_type=${state.offer_type}&period=${state.period}&page=${state.page}&limit=${state.limit}`
        );
        let data = response.data.data
        if (response.data.data.count > 0) {
          let amount = data.amount
          console.log(amount)
          commit("setWallet", response.data.data.rows);
          commit("setRowWallet", response.data.data.count);
          commit("setLoading", false);
          commit("setPopulars", response.data.data.popular);
          commit("setWalletAmount", amount.toFixed(2));
        }
        commit("setLoading", false);

      } catch (error) {
        let response = error.response
        commit("setError", true);
        commit("setLoading", false);
        if (response && response.status == 403) {
          let err = response.data.message
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
        return error;
      }
    },
    async fetchReportDetailGroupByClient({ commit }, state) {
      try {
        commit("setAggregates", []);
        const response = await API.get(
          `finance-report/aggregate?offer_type=${state.offer_type}&period=${state.period}&vendor_id=${state.vendor_id}`
        );
        let data = response.data.data
        let Ebooks = [];
        let Gramedia = [];
        let eperpus = [];
        data.forEach(element => {
          if (element.client_id == 82) {
            Gramedia.push(element)
          } else if (element.client_id == 67) {
            eperpus.push(element)
          } else {
            Ebooks.push(element)
          }
        });
        const amounEbooks = Ebooks.reduce((a, b) => a + b.amount, 0);
        let alldisbursment = [{
          "amount": amounEbooks,
          "client_id": 1,
          "total": Ebooks.reduce((a, b) => parseInt(a, 10) + parseInt(b.total, 10), 0)
        }]
        let all = [...alldisbursment, ...Gramedia, ...eperpus]
        commit("setAggregates", all);

      } catch (error) {
        let response = error.response
        commit("setError", true);
        commit("setLoading", false);
        if (response && response.status == 403) {
          let err = response.data.message
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
        return error;
      }
    },
    async sendReportByPeriod({ commit }, state) {
      commit("setErrorMessage", false);
      commit("setLoadingDownload", true);
      try {
        const response = await API.post(`send-report/finance-report`, state);
        commit("setLoadingDownload", false);

        return response;
      } catch (error) {
        commit("setLoadingDownload", false);

        let response = error.response;
        if (response.status == 404) {
          commit("setErrorMessage", response.data.message);
        }

        return response;
      }
    },
    async sendReportDetailBuffetByPeriod({ commit }, state){
      commit("setErrorMessage", false);
      try {
        const reponse = await API.post(`send-report/buffet/detail`, {
          period: state.period,
          vendor_id: state.vendor_id,
        });
        return reponse;
      } catch (error) {
        return error?.response;
      }
    }
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setWallet(state, item) {
      state.item_wallet = item;
    },
    setReadings(state, item) {
      state.readings = item;
    },
    setRowReadings(state, item) {
      state.rowReadings = item;
    },
    setTotalRevenue(state, item) {
      state.totalRevenue = item;
    },
    setAmount(state, item) {
      state.totalAmount = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setRowWallet(state, rowWallet) {
      state.totalWallet = rowWallet;
    },
    setWalletAmount(state, amount) {
      state.totalWalletAmount = amount;
    },
    setPopulars(state, data) {
      state.populars = data;
    },
    setAggregates(state, data) {
      state.aggregates = data;
    },
    setLoadingDownload(state, data) {
      state.isDownload = data;
    }
  },
};

export default Orders;
