const Vendor = {
  path: '/vendor',
  name: 'vendor',
  component: () => import('../views/vendor/vendor.vue'),
};
const EditVendor = {
  path: '/vendor/edit/:id',
  name: 'vendor-edit',
  component: () => import('../views/vendor/editVendor.vue'),
};
const CreateVendor = {
  path: '/vendor/create',
  name: 'vendor-create',
  component: () => import('../views/vendor/createVendor.vue'),
};
const DetailVendor = {
  path: '/vendor/detail/:id',
  name: 'vendor-detail',
  component: () => import('../views/vendor/detailVendor.vue'),
};
const VendorAggrement = {
  path: '/vendor-aggrements',
  name: 'vendor-aggrements',
  component: () => import('../views/vendor/vendor-aggrement.vue'),
};
const VendorAggrementAdd = {
  path: '/vendor-aggrement/create',
  name: 'vendor-aggrement-create',
  component: () => import('../views/vendor/vendor-aggrement-create.vue'),
};
const VendorAggrementEdit = {
  path: '/vendor-aggrement/edit/:id',
  name: 'vendor-aggrement-edit',
  component: () => import('../views/vendor/vendor-aggrement-edit.vue'),
};

const Brands = {
  path: '/brands',
  name: 'brands',
  component: () => import('../views/brands/brands.vue'),
};

const BrandsProduct = {
  path: '/brands-product/:id',
  name: 'brands-product',
  component: () => import('../views/brands/product.vue'),
};

//create brands
const CreateBrandPath = {
  path: '/brands/createBrands',
  name: 'createBrands',
  component: () => import('../views/brands/createBrands.vue'),
};
const EditBrandPath = {
  path: '/brands/editBrands/:id',
  name: 'editBrands',
  component: () => import('../views/brands/editBrands.vue'),
};
const DisplayCategoryBrand = {
  path: '/brands/displayCategory/:id',
  name: 'DisplayCategoryBrand',
  component: () => import('../views/brands/displayCategory.vue'),
};
const DisplayPremiumBrand = {
  path: '/brands/displayPremiums/:id',
  name: 'DisplayPremiumBrand',
  component: () => import('../views/brands/displayPremiums.vue'),
};
const CreateCategoryPath = {
  path: '/brands/createCategory/:id',
  name: 'CreateCategoryPath',
  component: () => import('../views/brands/createCategory.vue'),
};

const CreatePremiumPath = {
  path: '/brands/createPremiums/:id',
  name: 'CreatePremiumPath',
  component: () => import('../views/brands/createPremium.vue'),
};

// Revenue Share
const revenueShare = {
  path: '/revenueshares',
  name: 'revenueshare',
  component: () => import('../views/revenue_share/revenueshare.vue'),
};
const revenueShareEdit = {
  path: '/revenueshare/edit/:id',
  name: 'revenueshare-edit',
  component: () => import('../views/revenue_share/revenueshare-edit.vue'),
};
const revenueShareCreate = {
  path: '/revenueshare/create',
  name: 'revenueshare-create',
  component: () => import('../views/revenue_share/revenueshare-create.vue'),
};
const client = {
  path: '/clients',
  name: 'client',
  component: () => import('../views/client/client.vue'),
};
const clientEdit = {
  path: '/client/edit/:id',
  name: 'client-edit',
  component: () => import('../views/client/client-edit.vue'),
};
const clientCreate = {
  path: '/client/create',
  name: 'client-create',
  component: () => import('../views/client/client-create.vue'),
};

module.exports = {
  Vendor,
  EditVendor,
  CreateVendor,
  DetailVendor,
  VendorAggrement,
  VendorAggrementAdd,
  VendorAggrementEdit,
  Brands,
  BrandsProduct,
  CreateBrandPath,
  EditBrandPath,
  CreateCategoryPath,
  CreatePremiumPath,
  DisplayCategoryBrand,
  DisplayPremiumBrand,
  revenueShare,
  revenueShareEdit,
  revenueShareCreate,
  client,
  clientCreate,
  clientEdit,
};
