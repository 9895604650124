import API from "../plugins/http";

const Monitoring = {
  namespaced: true,
  state: {
    items: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMessage:null
  },
  actions: {
    async fetch({ commit }, state) {
      commit("setLoading", true);
      commit("setItems", []);
      try {
        const response = await API.get(
          `monitoring-item-upload`,
          {
            params: state,
          }
        );

        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },

    async search({ commit }, state) {
      commit("setLoading", true);
      try {
        const response = await API.get(
          `monitoring-item-upload/search`,
          {
            params: state,
          }
        );

        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },

    async searchAudio({ commit }, state) {
      commit("setLoading", true);
      try {
        const response = await API.get(
          `monitoring-item-upload/searchAudio`,
          {
            params: state,
          }
        );
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },

    async updateStatus({ commit }, props) {
      commit("setLoading", true);
      try {
        const response = await API.put(`monitoring-item-upload/${props.id}`,{
          status: props.status,
        });
        commit("setLoading", false);
        return response;
      } catch (error) {
        commit("setLoading", false);
        return error;
      }
    },
    
    async deleteProcess({ commit }, props) {
      commit("setLoading", true);
      try {
        const response = await API.delete(`monitoring-item-upload/${props.id}`);
        commit("setLoading", false);
        return response;
      } catch (error) {
        commit("setLoading", false);
        return error;
      }
    },

    async fetchAudio({ commit }, state) {
      commit("setLoading", true);
      commit("setItems", []);
      try {
        const response = await API.get(
          `monitoring-item-upload/audio`,
          {
            params: state,
          }
        );
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },

  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default Monitoring;
