import destructErrResponse from "../plugins/destruct-error-api";
import API from "../plugins/http";

export default {
  namespaced: true,
  state: {
    items: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMessage: '',
    isSuccess: false,
    successMessage: '',
  },
  getters: {
  },
  actions: {
    async fetchBrandsWithPremiumOffers({ commit }, state) {
      commit('setIsLoading', true);
      commit('setIsError', false);
      try {
        const response = await API.get('/brands-offers', {
          params: {
            vendor_id: state.vendor_id,
            offers_id: state.offers_id,
            action_type: state.action_type,
            brand_name: state.brand_name,
            limit: state.limit,
            offset: state.offset,
          }
        });
        commit('setItems', response?.data?.data?.rows);
        commit('setTotalRows', response?.data?.data?.count);
        commit('setIsLoading', false);
      } catch (error) {
        const {
          statusCode,
          statusText,
          message,
          data,
        } = destructErrResponse(error);
        let errorMessage = data?.data?.message ?? message ?? statusText;

        commit('setIsError', true);
        commit('setErrorMessage', statusCode + ':' + errorMessage);
        commit('setIsLoading', false);
      }
    },

    async postBrandsWithPremiumOffers({ commit }, state) {
      commit('setIsLoading', true);
      commit('setIsError', false);
      commit('setIsSuccess', false);
      try {
        await API.post('/brands-offers', {
          brands_id: state.brands_id,
          offers_id: state.offers_id,
        })
        commit('setIsSuccess', true);
        commit('setSuccessMessage', `success add ${state?.brands_id?.length} item`);
        commit('setIsLoading', false);
      } catch (error) {
        const {
          statusCode,
          statusText,
          message,
          data,
        } = destructErrResponse(error);
        let errorMessage = data?.data?.message ?? message ?? statusText;

        commit('setIsError', true);
        commit('setErrorMessage', statusCode + ':' + errorMessage);
        commit('setIsLoading', false);
      }
    },


    async deleteBrandsWithPremiumOffers({ commit }, state) {
      commit('setIsLoading', true);
      commit('setIsError', false);
      commit('setIsSuccess', false);
      try {
        const result = await API.put('/brands-offers', {
          brands_id: state.brands_id,
          offers_id: state.offers_id,
        })
        commit('setIsSuccess', true);
        commit('setSuccessMessage', result.data?.data?.message);
        commit('setSuccessMessage', `success update ${state?.brands_id?.length} item`);
        commit('setIsLoading', false);
      } catch (error) {
        const {
          statusCode,
          statusText,
          message,
          data,
        } = destructErrResponse(error);
        let errorMessage = data?.data?.message ?? message ?? statusText;

        commit('setIsError', true);
        commit('setErrorMessage', statusCode + ':' + errorMessage);
        commit('setIsLoading', false);
      }
    },
  },
  mutations: {
    setItems(state, value) {
      state.items = value;
    },
    setIsLoading(state, value) {
      state.isLoading = value;
    },
    setIsError(state, value) {
      state.isError = value;
    },
    setIsSuccess(state, value) {
      state.isSuccess = value;
    },
    setTotalRows(state, value) {
      state.totalRows = value;
    },
    setSuccessMessage(state, value) {
      state.successMessage = value;
    },
    setErrorMessage(state, value) {
      state.errorMessage = value;
    },
  },
};
