const MonitoringSoPath = {
  path: '/monitoring/so',
  name: 'monitoringSo',
  component: () => import('../views/monitoring/so.vue'),
};

const MonitoringItemUploadPath = {
  path: '/monitoring/items-upload',
  name: 'monitoringItemUpload',
  component: () => import('../views/monitoring/itemUpload.vue'),
};
const MonitoringItemUploadAudioPath = {
  path: '/monitoring/items-upload-audio',
  name: 'monitoringItemUploadAudio',
  component: () => import('../views/monitoring/itemUploadAudio.vue'),
};

const MonitoringRegisterItemAxPath = {
  path: '/monitoring/register-items-ax',
  name: 'monitoringRegisterItemAx',
  component: () => import('../views/ax/ax.vue'),
};

const MonitoringDraftRegisterItemAxPath = {
  path: '/monitoring/draft-register-items-ax',
  name: 'monitoringDraftRegisterItemAx',
  component: () => import('../views/ax/draftax.vue'),
};

const CatalogOfsPath = {
  path: '/catalog/ofs',
  name: 'catalogOfs',
  component: () => import('../views/ofs/ofsCatalog.vue'),
};

const DetailCatalogOfsPath = {
  path: '/catalog/ofs/detail/:id',
  name: 'DetailCatalogOfs',
  component: () => import('../views/ofs/DetailOfsCatalog.vue'),
};

const PayloadSo365Path = {
  path: '/monitoring/so-365',
  name: 'monitoringSo365',
  component: () => import('../views/monitoring/generateSo365.vue'),
};

const PayloadPo365Path = {
  path: '/monitoring/po-365',
  name: 'monitoringPo365',
  component: () => import('../views/monitoring/generatePo365.vue'),
};

const MonitoringLog = {
  path: '/monitoring/log',
  name: 'monitoring-log',
  component: () => import('../views/monitoring/log-activity.vue'),
};

const DetailMonitoringLog = {
  path: '/monitoring/log/:id',
  name: 'detail-monitoring-log',
  component: () => import('../views/monitoring/log-activity-detail.vue'),
};

module.exports = {
  MonitoringSoPath,
  MonitoringItemUploadPath,
  MonitoringRegisterItemAxPath,
  CatalogOfsPath,
  DetailCatalogOfsPath,
  MonitoringDraftRegisterItemAxPath,
  MonitoringItemUploadAudioPath,
  PayloadSo365Path,
  PayloadPo365Path,
  MonitoringLog,
  DetailMonitoringLog,
};
