import API from "../plugins/http";
const Orders = {
  namespaced: true,
  state: {
    items: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMessage:null,
    totalAmount:0,
    totalAmountD:0
  },
  getters: {
    bundle: (state) => state.items,
  },

  actions: {
    async fetchReport({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setItems", []);
        const response = await API.get(
          `report-bundle?from=${state.from}&to=${state.to}&page=${state.page}&limit=${state.limit}`
        );

        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },
    async fetchReportBundle({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setItems", []);
        const response = await API.get(
          `report-bundle-summary?from=${state.from}&to=${state.to}`
        );
        console.log(response.data.data);
        commit("setItems", response.data.data);
        commit("setRows", response.data.data);
        commit("setLoading", false);
        return response;
      } catch (error) {
        let response =error.response
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
        return error;
      }
    },
    async fetchReportByVendor({ commit }, state) {
      //report-bundle
      try {
        commit("setItems", []);
        commit("setLoading", true);
        const response = await API.get(
          `report-bundle/${state.id}?from=${state.from}&to=${state.to}`
        );
        let amount = response.data.data
        if(response.data.data.count > 0){

          commit("setItems", response.data.data.rows);
          let data = amount.total
          let idr =data.filter(e => e.currency =='IDR')
          let usd =data.filter(e => e.currency =='USD')
          commit("setAmount",idr.length > 0 ? idr[0].amount:0);
          commit("setAmountD",usd.length > 0 ? usd[0].amount: 0);
          
          commit("setRows", response.data.data.count);
          commit("setLoading", false);
        }
        commit("setLoading", false);
        
      } catch (error) {
        let response = error.response
        commit("setError", true);
        commit("setLoading", false);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
        return error;
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setAmount(state, item) {
      state.totalAmount = item;
    },
    setAmountD(state, item) {
      state.totalAmountD = item;
    },
    setTotalAmount(state, item) {
      state.totalAmount = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default Orders;
