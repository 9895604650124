import API from "../plugins/http";
const productChoices = {
  namespaced: true,
  state: {
    items: [],
    layouts:[],
    totalRows: 0,
    layoutsRows: 0,
    isLoading: false,
    isError: false,
    errorMessage:null,
  },
  getters: {
    single: (state) => state.items,
  },

  actions: {
    async fetchLayout({ commit }) {
      try {
        commit("setLoading", true);
        commit("setLayouts", []);
        const response = await API.get(
          `product-choices`
        );

        commit("setLayouts", response.data.data.rows);
        commit("setLayoutRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setLayouts", []);
        commit("setLayoutRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },
    async fetchLayoutByID({ commit }, state) {
        try {
          commit("setLoading", true);
          commit("setItems", []);
          const response = await API.get(
            `product-choices/${state.id}`
          );
  
          commit("setItems", response.data.data.rows);
          commit("setRows", response.data.data.count);
          commit("setLoading", false);
        } catch (error) {
          let response = error.response
          commit("setItems", []);
          commit("setRows", 0);
          commit("setLoading", false);
          commit("setError", true);
          if (response.status ==403) {
            let err =response.data.message
            commit("setErrorMessage",err)  
          } else {
            commit("setErrorMessage",error.response)
          }
        }
      },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLayouts(state, item) {
        state.layouts = item;
    },
    setLayoutRows(state, data) {
        state.totalRows = data;
      },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default productChoices;
