'use strict'


class Utils {
  PaymentCost(id) {
    let p = this.payment.find((e) => e.id == id);
    if (p) {
      let formula = p.formula
      let fixedFormula = parseInt(formula.fixed_amount) > 0 ? parseInt(formula.fixed_amount) : 0;
      let value = parseFloat(formula.percentage_amount) > 0 ? parseFloat(formula.percentage_amount) : 0
      return {
        fixed_amount: fixedFormula,
        value: value,
      };
    } else {
      return id;
    }
  }
  FormatFormula(data) {
    if (data) {
      if (data.fixed_amount > 0 && data.value > 0) {
        return `Rp ${data.value} + ${data.fixed_amount}`;
      } else if (data.fixed_amount > 0 && data.value < 1) {
        return `Rp ${data.fixed_amount}`
      } else {
        return `${data.value}`;
      }
    }
  }
  toSlug(title) {
    const titlebook = title.toLowerCase();
    return titlebook
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes
      .replace(/^-+/, '') // trim - from start of text
      .replace(/-+$/, '') // trim - from end of text
      .replace(/\//g, '-');
  }
  getFirstLetters(str) {
    const firstLetters = str
      .replace(/\W+/g, ' ')
      .split(' ')
      .map(word => word[0])
      .join('');

    return firstLetters;
  }
  encodeBase64(str) {
    return Buffer.from(str?.trim()).toString('base64');
  }
  decodeBase64(str) {
    return Buffer.from(str?.trim(), 'base64').toString('ascii');
  }
  encodeUrlWithBase64(str) {
    return encodeURIComponent(this.encodeBase64(str?.trim()));
  }
  decodeUrlWithBase64(str) {
    return this.decodeBase64(decodeURIComponent(str?.trim()));
  }
}

module.exports = new Utils()