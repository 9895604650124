import API from "../plugins/http";
const itemCodeOffer = {
  namespaced: true,
  state: {
    items: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMessage: null
  },
  getters: {
    items: (state) => state.items,
  },

  actions: {
    async fetch({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setItems", []);
        const response = await API.get(
          `autocomplete_item?page=${state.page}&limit=${state.limit}`
        );

        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status == 403) {
          let err = response.data.message
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
      }
    },
    async Search({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setItems", []);
        let listItem = [];
        state.current_item.forEach(item => {
          listItem.push(item.value);
        });
        const response = await API.get(
          `autocomplete-item?q=${state.q}&item_type=${state.item_type}&current_item=${JSON.stringify(listItem)}`
        );
        commit("setItems", response.data.data);
        commit("setRows", response.data.data);
        commit("setLoading", false);
        return response;
      } catch (error) {
        let response = error.response
        commit("setError", true);
        if (response.status == 403) {
          let err = response.data.message
          commit("setErrorMessage", err)
        } else {
          commit("setErrorMessage", error.response)
        }
        return error;
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default itemCodeOffer;
