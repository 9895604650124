const Payment = {
  path: '/settings/payments',
  name: 'payment',
  component: () => import('../views/payments/payment.vue'),
};

const CreatePayment = {
  path: '/settings/payment/create',
  name: 'payment-create',
  component: () => import('../views/payments/createPayment.vue'),
};

const EditPayment = {
  path: '/settings/payment/edit/:id',
  name: 'payment-edit',
  component: () => import('../views/payments/editPayment.vue'),
};

module.exports = {
  Payment,
  CreatePayment,
  EditPayment,
};
