'use strict';
import API from '../plugins/http';
const Admin = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    isError: false,
    isLoading: false,
    message: null,
    totalRows: 0,
    errorMessage: '',
    successMessage: '',
  },
  getters: {
    admins: (state) => state.items,
    isError: (state) => state.isError,
    isLoading: (state) => state.isLoading,
  },

  actions: {
    async fetchAdmin({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(`admin-cms`, { params: state });
        commit('setItem', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async search({ commit }, props) {
      try {
        commit('setError', false);
        commit('setLoading', true);
        const response = await API.get(`admin-cms?email=${props.q}`);
        commit('setItem', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async fetchUserAdminById({ commit }, state) {
      try {
        const response = await API.get(`admin-cms/${state.id}`);
        commit('setItemObject', response.data.data);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setError', true);
        return error;
      }
    },

    async createUserAdmin({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.post('admin-cms', state);
        if (response.status == 201) {
          commit(
            'setSuccessMessage',
            'User Admin Has Been Successfully Created',
          );
        }
        commit('setLoading', false);
      } catch (error) {
        let errMsg = '';
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
      }
    },

    async updateUserAdmin({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.put('admin-cms/' + state.id, state);
        if (response.status == 200) {
          commit(
            'setSuccessMessage',
            'User Admin Has Been Successfully Updated',
          );
        }
      } catch (error) {
        let errMsg = '';
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
      }
    },
    async showSecret({ commit }, state) {
      commit('setMessage', null);
      commit('setLoading', true);
      commit('setError', false);
      commit('setErrorMessage', null);
      const { userId } = state;
      const { userEmail } = state;
      const { password } = state;
      const map = {};
      try {
        const response = await API.get(
          'showSecret/getSecretKey/?userId=' +
            userId +
            '&userEmail=' +
            userEmail +
            '&password=' +
            password,
        );
        map.result = response.data.data.result;
        map.message = response.data.data.message;
        map.secretKey = response.data.data.secretKey;
        map.secretKeyIsNotExist = response.data.data.secretKeyIsNotExist;
        return map;
      } catch (error) {
        let message = error.response.data.message.message;
        let result = error.response.data.message.result;
        let secretKey = error.response.data.message.secretKey;
        let secretKeyIsNotExist =
          error.response.data.message.secretKeyIsNotExist;
        if (!message) {
          message = 'Password Incorrect';
        }
        map.message = message;
        map.result = result;
        map.secretKey = secretKey;
        map.secretKeyIsNotExist = secretKeyIsNotExist;
        commit('setError', result);
        commit('setErrorMessage', message);
        return map;
      }
    },
    async fetchAdminVendor({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(`admin-vendor`, { params: state });

        commit('setItem', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async fetchAdminVendorById({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(`admin-vendor/${state.id}`);
        commit('setLoading', false);
        return response.data?.data;
      } catch (error) {
        commit('setError', true);
        commit('setLoading', false);
        commit(
          'setMessage',
          error.response?.data?.message ??
            'Oops! Something went wrong. Please contact your administrator',
        );
      }
    },

    async createAdminVendor({ commit }, state) {
      commit('setMessage', null);
      commit('setLoading', true);
      commit('setError', false);
      try {
        const response = await API.post('admin-vendor', state);
        commit('setMessage', response.data?.message);
        commit('setLoading', false);
      } catch (error) {
        commit('setError', true);
        commit('setLoading', false);
        commit(
          'setMessage',
          error.response?.data?.message ??
            'Oops! Something went wrong. Please contact your administrator',
        );
      }
    },

    async updateAdminVendor({ commit }, state) {
      commit('setMessage', null);
      commit('setLoading', true);
      commit('setError', false);
      try {
        const response = await API.put(`admin-vendor/${state.id}`, state);
        commit('setMessage', response.data?.message);
        commit('setLoading', false);
      } catch (error) {
        commit('setError', true);
        commit('setLoading', false);
        commit(
          'setMessage',
          error.response?.data?.message ??
            'Oops! Something went wrong. Please contact your administrator',
        );
      }
    },
  },
  mutations: {
    setItem(state, item) {
      state.items = item;
    },
    setItemObject(state, item) {
      state.item = item;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setMessage(state, data) {
      state.message = data;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setIsPassword(state, data) {
      state.isPassword = data;
    },
  },
};

export default Admin;
