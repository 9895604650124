<template>
  <apexchart
    type="donut"
    height="350"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
export default {
  name: "ChartContents",
  props: {
    title: {
      type: String,
      default: "Chart"
    },
    options: {
      type: Object,
      default: () => {}
    },
    series: {
      type: Array,
      default: () => []
    },
  },
}
</script>
