import API from '../plugins/http';
const API_URL = 'dynamic-po/';
import axios from "axios";
const dynamicPO = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    ErrorMessage: '',
    SuccessMessage: '',
  },
  actions: {
    async fetchAllDynamicPO({ commit }, props) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await API.get(API_URL, { params: props });
        commit('setLoading', false);
        commit('setItems', response.data?.data?.rows);
        commit('setRows', response.data?.data?.count);
      } catch (error) {
        commit('setError', true);
        const response = error.response;
        if (response?.status == 422) {
          commit('setErrorMessage', response?.data?.message);
        }
        commit('setLoading', false);
      }
    },
    async postResendPO({ commit }, payload) {
      commit('setLoading', true);
      try {
      
        const data = {
          reference_number: payload.reference_number
        }
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'api-key': 'eyJpZCI6IjEiLCJuYW1lIjoic2Nvb3BfaW9zX2FwcHMifQ',
          }
        };
    
        const response = await axios.post('/cms/resend/po', data, config);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error?.response;
      }
    },    
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.ErrorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.SuccessMessage = data;
    },
  },
};

export default dynamicPO;
