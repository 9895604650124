import API from '../plugins/http';
import Utils from '../plugins/util';
const Brands = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    itemRevenue: {},
    revenue: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    successMessage: '',
    errorMessage: '',
  },
  getters: {
    vendors: (state) => state.items,
    revenue: (state) => state.revenue,
  },

  actions: {
    async fetchVendors({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await API.get(`vendor`, { params: state });

        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async searchVendors({ commit }, state) {
      try {
        commit('setLoading', true);
        let newPayload = state;
        if (newPayload?.q) {
          const keyword = Utils.encodeUrlWithBase64(newPayload.q);
          newPayload.q = keyword;
        }
        if (newPayload?.name) {
          const keyword = Utils.encodeUrlWithBase64(newPayload.name);
          newPayload.name = keyword;
        }
        if(newPayload?.email) {
          const keyword = Utils.encodeUrlWithBase64(newPayload.email);
          newPayload.email = keyword;
        }
        const response = await API.get(`search-vendor`, {
          params: newPayload,
        });
        commit('setRows', response.data.data.count);
        commit('setItems', response.data.data.rows);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error;
      }
    },
    async fetchVendorsById({ commit }, state) {
      try {
        const response = await API.get(`vendor/${state.id}`);
        commit('setItems', [response.data.data]);
        commit('setItem', response.data.data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setError', true);
        return error;
      }
    },
    async fetchOwnVendors({ commit }) {
      try {
        const response = await API.get(`search-vendor/me`);
        commit('setItem', response.data.data);
        commit('setLoading', false);
      } catch (error) {
        commit('setError', true);
      }
    },
    async RevenueByVendor({ commit }, state) {
      try {
        const response = await API.get(`revenue-share/${state.id}`);
        commit('setRevenue', response.data.data);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        return error;
      }
    },
    async imageVendorDetail({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const data = {
          file:payload.formData
        }
        const response = await API.put(`vendor/upload-images/${payload.id}`,data.file)
        if (response.status == 200) {
          commit('setSuccessMessage', 'Vendor Has Been Successfully Updated');
        }
        return response
      } catch (error) {
        commit('setErrorMessage', error);
        if (error.response.status == 422) {
            commit('setErrorMessage', error.response.data.message);
        } else {
          commit('setErrorMessage', error.response.data.message);
        }
      }
    },

    async editVendor({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.put(
          'vendor/' + payload.id,
          payload.formData,
        );
        if (response.status == 200) {
          commit('setSuccessMessage', 'Vendor Has Been Successfully Updated');
        }
      } catch (error) {
        let errMsg = '';
        if (error.status == 403) {
          errMsg += error.data.message;
        } else {
          if (error.response.status == 422) {
            errMsg += error.response.data.message;
          } else {
            errMsg += error.response.data.data.message;
          }
        }

        commit('setErrorMessage', errMsg);
      }
    },

    async AutocompleteSearch({ commit }, state) {
      try {
        commit('setLoading', true);
        commit('setItems', []);
        const response = await API.get(`autocomplete-vendor/?q=${state.q}`);
        commit('setItems', response.data.data);
        commit('setRows', response.data.data);
        commit('setLoading', false);
        return response;
      } catch (error) {
        let response = error.response;
        commit('setError', true);
        if (response.status == 403) {
          let err = response.data.message;
          commit('setErrorMessage', err);
        } else {
          commit('setErrorMessage', error.response);
        }
        return error;
      }
    },

    async AutocompleteVendorId({ commit }, state) {
      try {
        const response = await API.get(`autocomplete-vendor/${state.id}`);
        commit('setItems', [response.data.data]);
        commit('setItem', response.data.data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        let response = error.response;
        commit('setError', true);
        if (response.status == 403) {
          let err = response.data.message;
          commit('setErrorMessage', err);
        } else {
          commit('setErrorMessage', error.response);
        }
        return error;
      }
    },

    async AutocompleteVendorByOrganizationId({ commit }, state) {
      try {
        const response = await API.get(
          `autocomplete-vendor/organization/${state.id}`,
        );
        commit('setItems', [response.data.data]);
        commit('setItem', response.data.data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        let response = error.response;
        commit('setError', true);
        if (response.status == 403) {
          let err = response.data.message;
          commit('setErrorMessage', err);
        } else {
          commit('setErrorMessage', error.response);
        }
        return error;
      }
    },

    async getVendorRevenue({ commit }, state) {
      try {
        const response = await API.get(
          'vendor-revenue-share/v2/id/' + state.id,
        );
        commit('setItemRevenue', response.data.data);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setError', true);
        return error;
      }
    },

    async findVendor({ commit }, state) {
      try {
        const q = Utils.encodeUrlWithBase64(state.q);
        const response = await API.get(`search-vendor/search?name=${q}`);
        commit('setItems', response.data.data.name);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },
    async searchVendor({ commit }, props) {
      commit('setLoading', true);
      try {
        const q = Utils.encodeUrlWithBase64(props.q);
        const response = await API.get(
          `search-vendor/search/vendor-ax?name=${q}`,
        );
        console.log(response.data?.data);
        commit('setItems', response.data?.data?.rows);
        commit('setRows', response.data?.data?.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error;
      }
    },
    async postVendorsPremiumById({ commit }, payload) {
      commit('setLoading', true);
      try {
        const response = await API.post(`vendor/request-vendor-premium/${payload.id}`);
        return response; 
      } catch (error) {
        commit('setError', true);
        throw error; 
      }
    },

    async getVendorId({ commit }, props) {
      commit('setLoading', false);
      try {
        const response = await API.get(`vendor/get/${props}`);
        return response;
      } catch (error) {
        return error;
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setItemRevenue(state, item) {
      state.itemRevenue = item;
    },
    setRevenue(state, item) {
      state.revenue = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default Brands;
