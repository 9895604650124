import API from "../plugins/http";
const gramedia = {
  namespaced: true,
  state: {
    items: [],
    sales_item:[],
    isLoadingSale:false,
    isErrorSales:false,
    totalAmount:0,
    totalAmountD:0,
    payments: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
  },
  getters: {
    sales: (state) => state.items,
  },

  actions: {
    async fetchReport({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `report-single-gramedia?from=${state.from}&to=${state.to}`
        );
        
        let amount = response.data ? response.data.data : [];
        let data = amount.total
        if (response.data.data) {
          
          let idr =data.filter(e => e.currency =='IDR')
          
          console.log(idr)
          commit("setAmount",idr.length > 0 ? idr[0].amount:0);
          commit("setItems", response.data.data.rows);
          commit("setRows", response.data.data.count);
          commit("setLoading", false);
          commit("setPayments", response.data.data.paymentgateway_id);
          
        }
      } catch (error) {
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async fetchReportByPayment({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `report-single-gramedia/payments?from=${state.from}&to=${state.to}`
        );
        let amount = response.data ? response.data.data : [];
        let data = amount.total
        let idr =data.filter(e => e.currency =='IDR')
        
        console.log(idr)
        commit("setAmount",idr.length > 0 ? idr[0].amount:0);
        commit("setItems", data.rows);
        commit("setRows", data.length);
        commit("setLoading", false);
        commit("setPayments", data.paymentgateway_id);

        return response.data;
      } catch (error) {
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async fetchReportByBendor({ commit }, state) {
      try {
        commit("setLoadingSales", true);
        const response = await API.get(
          `report-single-gramedia/vendor/${state.id}?from=${state.from}&to=${state.to}`
        );
        let data = response.data ? response.data.data : [];
        console.log(response.data.data);
        commit("setItemSales", data.rows);
        commit("setRows", data.length);
        commit("setLoadingSales", false);
        return response.data;
      } catch (error) {
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItemSales(state, item) {
      state.sales_item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setLoadingSales(state, data) {
      state.isLoadingSale = data;
    },
    setErrorSales(state, data) {
      state.isErrorSales = data;
    },
    setPayments(state, data) {
      state.payments = data;
    },
    setAmount(state, item) {
      state.totalAmount = item;
    },
  },
};

export default gramedia;
