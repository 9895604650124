const ComplaintCreditCard = {
  path: '/complaint/credit',
  name: 'credit-card',
  component: () => import('../views/complaint/credit.vue'),
};

const ComplaintItunes = {
  path: '/complaint/itunes',
  name: 'itunes',
  component: () => import('../views/complaint/itunes.vue'),
};

const ComplaintGopay = {
  path: '/complaint/gopay',
  name: 'gopay',
  component: () => import('../views/complaint/gopay.vue'),
};

const ComplaintVA = {
  path: '/complaint/va',
  name: 'va',
  component: () => import('../views/complaint/va.vue'),
};

const ComplaintGoogle = {
  path: '/complaint/google',
  name: 'google',
  component: () => import('../views/complaint/google.vue'),
};

module.exports = {
  ComplaintCreditCard,
  ComplaintGoogle,
  ComplaintGopay,
  ComplaintItunes,
  ComplaintVA,
};
