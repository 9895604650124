import API from "../plugins/http";

const OffersPremium = {
  namespaced: true,
  state: {
    offers: [],
    offer: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    items: [],
    totalItemRows: {},
    isLoadingAction: false,
    errorMessage: '',
    successMessage: '',
  },
  getters: {
    premium: (state) => state.offers,
    items: (state) => state.items,
    totalItemRows: (state) => state.totalItemRows,
  },
  actions: {
    async fetchOffers({ commit }, props) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `offers-premium?page=${props.page}&limit=${props.limit}`
        );
        commit("setOffers", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async fetchPremiumOffersById({ commit }, id) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `offers-premium/${id}`
        );
        const data = response.data.data;
        commit('setOffer', data);
        commit('setOffers', [data]);
        commit('setLoading', false);
        return data;
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        commit('setError', true);
        if (response.status == 404) {
          commit('setErrorMessage', 'single offer not found');
        } else{
          commit('setErrorMessage', 'Internal Server Error');
        }
      }
    },
    async fetchOffersItemsByOfferId({ commit }, props) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `offers-premium/items/${props.id}?page=${props.page}&limit=${props.limit}`
        );
        commit("setItems", response.data.data.rows);
        commit("setItemsRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async deleteBrandOffer({ commit }, props) {
      try {
        commit("setLoadingAction", true);
        const response = await API.delete(
          `offers-premium/offer-brands/${props.id}`
        );
        commit("setLoadingAction", false);
        console.log(response);
      } catch (error) {
        commit("setLoadingAction", false);
        commit("setError", true);
        commit('setErrorMessage', error.response.data.message);
      }
    },
    async searchOffers({ commit }, props) {
      commit("setError", false);
      try {
        const response = await API.get(
          `offers-premium?name=${props.name}`
        );
        return response;
      } catch (error) {
        return error;
      }
    },
    async postPremiumOffers({ commit }, payload) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.post(`offers-premium`, payload);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success Created');
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
    async updatePremiumOffers({ commit }, payload) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.put(`offers-premium/${payload.id}`, payload);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success Updated');
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setOffers(state, offer) {
      state.offers = offer;
    },
    setOffer(state, offer) {
      state.offer = offer;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setItems(state, data) {
      state.items = data;
    },
    setItemsRows(state, data) {
      state.totalItemRows = data;
    },
    setLoadingAction(state, data) {
      state.isLoadingAction = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
  },
};

export default OffersPremium;
