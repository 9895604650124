import API from "../plugins/http";
const authorPopular = {
  namespaced: true,
  state: {
    populars:[],
    popular: [],
    totalRows: 0,
    layoutsRows: 0,
    isLoading: false,
    isError: false,
    errorMessage:null,
    message:null,
  },
  getters: {
    populars: (state) => state.populars,
    popular: (state) => state.popular,
  },

  actions: {
    async fetch({ commit }) {
      try {
        commit("setLoading", true);
        commit("setPopulars", []);
        const response = await API.get(
          `author-popular`
        );

        commit("setPopulars", response.data?.data.rows);
        commit("setPopularRows", response.data?.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setPopulars", []);
        commit("setPopularRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },
    async fetchByID({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setPopular", []);
        const response = await API.get(
          `product-choices/${state.id}`
        );

        commit("setPopular", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setPopular", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },
    createAuthorPopular({ commit }, state) {
      return new Promise((resolve, reject) => {
        API.post(`author-popular`, state)
          .then(response => {
            resolve(response.data);
            commit("setMessage",response.data.message);
          })
          .catch(error => {
            let response = error.response
            commit("setError", true);
            if (response.status ==403) {
              let err =response.data.message
              commit("setErrorMessage",err)  
            } else {
              commit("setErrorMessage",error.response)
            }
            reject(error);
          });
      });
    }
  },

  mutations: {
    setPopulars(state, item) {
      state.populars = item;
    },
    setPopularRows(state, data) {
      state.totalRows = data;
    },
    setPopular(state, item) {
      state.popular = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setMessage(state, data) {
      state.Message = data;
    },
  },
};

export default authorPopular;
