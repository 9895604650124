import API from '../plugins/http';
const appstore = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    ErrorMessage: '',
    SuccessMessage: '',
  },
  getters: {
    appstore: (state) => state.items,
  },
  actions: {
    async fetchAppstoreNotification({ commit }, props) {
      commit('setError', false);
      commit('setErrorMessage', '');
      commit('setItems', []);
      try {
        commit('setLoading', true);
        const response = await API.get('/appstore-notification/list', {
          params: props,
        });
        commit('setLoading', false);
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        commit('setError', true);
        if (response.status == 422) {
          response.data.errors.map((e) => commit('setErrorMessage', e?.msg));
        } else {
          commit('setErrorMessage', error.response.data.message);
        }
      }
    },

    async fetchByTransactionId({ commit }, props) {
      commit('setError', false);
      commit('setErrorMessage', null);
      commit('setItems', []);
      try {
        commit('setLoading', true);
        const response = await API.get(
          '/appstore-notification/' + props.transaction_id,
          {
            params: props,
          },
        );
        commit('setLoading', false);
        commit('setItems', response?.data?.data?.rows);
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        commit('setError', true);
        if (response?.status == 422) {
          response?.data?.errors.map((e) => commit('setErrorMessage', e?.msg));
        } else {
          commit('setErrorMessage', error.response?.data?.message);
        }
      }
    },

    async fetchDetailTransaction({ commit }, props) {
      commit('setError', false);
      commit('setErrorMessage', null);
      commit('setItem', {});
      try {
        commit('setLoading', true);
        const response = await API.get(
          '/appstore-notification/detail/' + props.transaction_id,
        );
        commit('setLoading', false);
        commit('setItem', response?.data?.data);
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        commit('setError', true);
        if (response?.status == 422) {
          response?.data?.errors?.map((e) => commit('setErrorMessage', e?.msg));
        } else {
          commit('setErrorMessage', error.response?.data?.message);
        }
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.ErrorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.SuccessMessage = data;
    },
  },
};

export default appstore;
