import API from '../plugins/http';
const BASE_URL = 'monitoring-log/';
const log = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
  },
  actions: {
    async fetchLog({ commit }, props) {
      commit('setError', false);
      commit('setItems', []);
      try {
        commit('setLoading', true);
        const response = await API.get(BASE_URL, {
          params: props,
        });
        commit('setLoading', false);
        commit('setItems', response.data?.data?.rows);
        commit('setRows', response.data?.data?.count);
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        commit('setError', true);
        if (response.status == 422) {
          response.data.errors.map((e) => commit('setErrorMessage', e?.msg));
        } else {
          commit('setErrorMessage', error.response.data.message);
        }
      }
    },

    async fetchLogById({ commit }, props) {
      commit('setError', false);
      commit('setItem', {});
      try {
        commit('setLoading', true);
        const response = await API.get(BASE_URL + props.id);
        commit('setLoading', false);
        commit('setItem', response.data?.data);
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        commit('setError', true);
        if (response.status == 422) {
          response.data.errors.map((e) => commit('setErrorMessage', e?.msg));
        } else {
          commit('setErrorMessage', error.response.data.message);
        }
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
  },
};

export default log;
