import API from "../plugins/http";
const authors = {
  namespaced: true,
  state: {
    authors:[],
    author: [],
    totalRows: 0,
    layoutsRows: 0,
    isLoading: false,
    isError: false,
    errorMessage:null,
    successMessage: null,
  },
  getters: {
    authors: (state) => state.authors,
    author: (state) => state.author,
  },

  actions: {
    async fetch({ commit }, props) {
      try {
        commit("setLoading", true);
        commit("setAuthors", []);
        const response = await API.get(`authors`, {params: props});

        commit("setAuthors", response.data?.data.rows);
        commit("setAuthorsRows", response.data?.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setAuthors", []);
        commit("setAuthorsRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },
    async fetchByID({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setAuthor", []);
        const response = await API.get(
          `product-choices/${state.id}`
        );

        commit("setAuthor", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        let response = error.response
        commit("setAuthor", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },
    async searchAuthor({ commit }, props) {
      try {
        commit("setLoading", true);
        commit("setAuthors", []);
        const response = await API.get(`authors/search`, {params: props});

        commit("setAuthors", response.data?.data.rows);
        commit("setAuthorsRows", response.data?.data.count);
        commit("setLoading", false);
        return response;
      } catch (error) {
        let response = error.response
        commit("setAuthors", []);
        commit("setAuthorsRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },
    async fetchAuthorByID({ commit }, state) {
      commit('setLoading', true);
      commit('setError', false);
      commit("setAuthor", null);
      try {
        const response = await API.get(`authors/${state.id}/detail`);
        commit("setAuthor", response.data?.data);
        commit('setLoading', false);
      } catch (error) {
        const statusCode = error.status ?? error.response?.status;
        let message = error.data?.message ?? error.response?.data?.message;
        commit('setError', true);
        commit('setErrorMessage', `${statusCode} : ${message}`);
        commit('setLoading', false);
      }
    },
    async postAuthor({ commit }, payload) {
      commit('setLoading', true);
      commit('setError', false);
      commit('setSuccessMessage', null);
      commit('setErrorMessage', null);
      try {
        await API.post(`authors`, payload);
        commit('setSuccessMessage', 'Success Create');
        commit('setLoading', false);
      } catch (error) {
        const statusCode = error.status ?? error.response?.status;
        let message = error.data?.message ?? error.response?.data?.message;

        if (statusCode == 422) {
          const attr = error.response?.data?.errors.map(val => val.param).join(' ,');
          message += ` (${attr})`;
        }
        if ( [404, 409].includes(statusCode)) {
          message = error.response?.data.data?.message ?? message;
        }

        commit('setError', true);
        commit('setErrorMessage', message);
        commit('setLoading', false);
      }
    },
    async updateAuthor({ commit }, payload) {
      commit('setLoading', true);
      commit('setError', false);
      commit('setSuccessMessage', null);
      commit('setErrorMessage', null);
      try {
        const response = await API.put(`authors/${payload.id}`, payload);
        commit('setLoading', false);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success');
        }
      } catch (error) {
        const statusCode = error.status ?? error.response?.status;
        let message = error.data?.message ?? error.response?.data?.message;
        if (statusCode == 422) {
          const attr = error.response?.data?.errors.map(val => val.param).join(' ,');
          message += ` (${attr})`;
        }
        commit('setError', true);
        commit('setErrorMessage', message);
        commit('setLoading', false);
      }
    },
    async uploadImage({ commit }, payload) {
      commit('setLoading', true);
      commit('setError', false);
      commit('setSuccessMessage', null);
      commit('setErrorMessage', null);
      try {
        const formData = new FormData();
        formData.append('image', payload.image),
        await API.post(`authors/${payload.id}/upload`, formData);
        commit('setSuccessMessage', 'Success');
      } catch (error) {
        const statusCode = error.status ?? error.response?.status;
        let message = error.data?.message ?? error.response?.data?.message;
        if (statusCode == 422) {
          console.log(error.response);
          const attr = error.response?.data?.errors?.map(val => val.param).join(' ,');
          message = `REQUIRED (${attr})`;
        }
        commit('setError', true);
        commit('setErrorMessage', message);
        commit('setLoading', false);
      }
    },
  },

  mutations: {
    setAuthors(state, item) {
      state.authors = item;
    },
    setAuthorsRows(state, data) {
      state.totalRows = data;
    },
    setAuthor(state, item) {
      state.author = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
  },
};

export default authors;
