import API from '../plugins/http';
const brandAx = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    ErrorMessage: '',
    SuccessMessage: '',
  },
  getters: {
    brandAx: (state) => state.items,
  },
  actions: {
    async fetchAll({ commit }, props) {
      commit('setError', false);
      commit('setErrorMessage', '');
      commit('setItems', []);
      try {
        commit('setLoading', true);
        const response = await API.get('/brand-ax', { params: props });
        commit('setLoading', false);
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        commit('setError', true);
        if (response.status == 422) {
          error.response.data.errors.map((e) =>
            commit('setErrorMessage', e?.msg),
          );
        } else {
          commit('setErrorMessage', error.response.data.message);
        }
      }
    },

    async createBrandAx({ commit }, props) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setLoading', true);
      try {
        const response = await API.post('/brand-ax', props);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Brand AX Has Been Successfully Created');
        }
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        if (response.status == 422) {
          if (response.data.errors) {
            response.data.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data.message);
          }
        } else {
          commit('setErrorMessage', error.response.data.message);
        }
      }
    },

    async updateBrandAx({ commit }, props) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setLoading', true);
      try {
        const response = await API.put('/brand-ax', props);
        if (response.status == 200) {
          commit('setSuccessMessage', 'Brand AX Has Been Successfully Updated');
        }
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        if (response.status == 422) {
          if (response.data.errors) {
            response.data.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data.message);
          }
        } else {
          commit('setErrorMessage', error.response.data.message);
        }
      }
    },

    async fetchById({ commit }, props) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        commit('setLoading', true);
        const response = await API.get('/brand-ax/' + props);
        commit('setLoading', false);
        commit('setItem', response.data.data);
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        if (response.status == 404) {
          commit('setErrorMessage', response.data.message);
        } else {
          commit('setErrorMessage', error.response.data.message);
        }
      }
    },

    async bulkUploadSubscriptionIdAx({ commit }, state) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        const response = await API.post('brand-ax/bulk-upload', state);
        if (response?.status == 200) {
          commit(
            'setSuccessMessage',
            `Item brand ax has been successfully uploaded (created : ${response?.data?.data?.created} item)`,
          );
        }

        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        const response = error?.response;
        if (response?.status == 422) {
          if (response?.data?.errors?.length) {
            response?.data?.errors.map((e) =>
              commit('setErrorMessage', e?.msg),
            );
          } else {
            commit('setErrorMessage', response.data?.message);
          }
        } else {
          commit('setErrorMessage', response.data?.message);
        }
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.ErrorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.SuccessMessage = data;
    },
  },
};

export default brandAx;
