const registerItemAx = {
  path: '/ax/items/register-item-ax',
  name: 'register-item-ax',
  component: () => import('../views/ax/register-item-ax.vue'),
};

const updateRegisterItemAx = {
  path: '/ax/items/register-item-ax/:id',
  name: 'update-register-item-ax',
  component: () => import('../views/ax/update-register-item-ax.vue'),
};

const dynamicPo = {
  path: '/dynamic-po',
  name: 'dynamic-po',
  component: () => import('../views/dynamics/dynamic-po.vue'),
};

const dynamicSo = {
  path: '/dynamic-so',
  name: 'dynamic-so',
  component: () => import('../views/dynamics/dynamic-so.vue'),
};

const vendorPremium = {
  path: '/upload-vendor-premium',
  name: 'upload-vendor-premium',
  component: () => import('../views/dynamics/vendor-premium.vue'),
};

const ListVendorPremium = {
  path: '/vendor-premium',
  name: 'vendor-premium',
  component: () => import('../views/dynamics/list-vendor-premium.vue'),
};

module.exports = {
  registerItemAx,
  dynamicPo,
  dynamicSo,
  vendorPremium,
  ListVendorPremium,
  updateRegisterItemAx,
};
