import Vue from 'vue';

Vue.filter('formatIDR', (value) => (
  parseFloat(value).toLocaleString('id-ID', {
    style: 'currency',
    currency: 'IDR'
  })
));


Vue.filter('formatUSD', (value) => (
  parseFloat(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })
));

// source info https://www.gbmb.org/mb-to-kb
Vue.filter('fileSizeFormat', (value, decimals = 2, baseNumber = 10) => {
  if (value === 0 || !value) return '0 Bytes';

  const base = {
    10: 1000,
    2: 1024,
  };
  const k = base[baseNumber];
  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
  const i = Math.floor(Math.log(value) / Math.log(k));

  return parseFloat((value / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
},)
