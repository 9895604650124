<template>
  <div class="dashboardContent">
    <div class="col-md-12">
      <div class="row mt-2 mb-1">
        <div class="col-md-2">
          <h4 class="h4 text-left"> Content </h4>
        </div>
        <div class="col-md-auto">
          <b-button-group>
            <b-button variant="primary">Book</b-button>
            <b-button variant="warning">Magazines</b-button>
            <b-button variant="dark">Newspaper</b-button>
          </b-button-group>
        </div>
        <div class="col-md-auto">
          <b-button-group>
            <b-button variant="light" @click="actionGroup('day')"> Days</b-button>
            <b-button variant="light" @click="actionGroup('month')">Month</b-button>
            <b-dropdown right text="Data Rage">
              <b-dropdown-item @click="actionshowCountMonth(1)"> 1 Month</b-dropdown-item>
              <b-dropdown-item @click="actionshowCountMonth(3)"> 3 Month</b-dropdown-item>
              <b-dropdown-item @click="actionshowCountMonth(6)"> 6 Month</b-dropdown-item>
              <b-dropdown-divider> </b-dropdown-divider>
              <b-dropdown-item @click="actionshowCountMonth(12)"> 1 Year</b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </div>
        <div class="col-md-auto">
          <i class="fa fa-line-chart btn btn-sm" aria-hidden="true" 
            @click="actionChangeChartType('line')">
          </i>
          <i class="fa fa-bar-chart btn btn-sm" aria-hidden="true" 
            @click="actionChangeChartType('bar')">
          </i>
          <i class="fa fa-area-chart btn btn-sm" aria-hidden="true" 
            @click="actionChangeChartType('area')">
          </i>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div class="row mb-2 pb-1">
        <div class="col-md-9 shadow-sm p-1">
          <RevenueLine 
            :type="typeChart"
            :height="400"
            :options="options"
            :series="series"
          />
        </div>
        <div class="col-md-3 pt-1 pb-1">
          {{ loadChart ? 'Loading ...':''}}
          <div class="card p-2 rounded" v-if="summary">
            <label class="form-control-label text-black">Summary </label>
            <div class="card-body shadow-lg rounded">
              <p>Total : {{ formatPriceBasic(summary.count) }}</p>
              <p>Average : {{ formatPriceBasic(summary.average) }}</p>
              <p>Median : {{ formatPriceBasic(summary.median)}}</p>
              <p>Max : {{ formatPriceBasic(summary.max) }}</p>
              <p>Min : {{ formatPriceBasic(summary.min) }}</p>
            </div>
          </div>
          <div class="card p-2 rounded" v-if="summary">
            <label class="form-control-label text-black">Summary Type </label>
            <div class="card-body shadow-lg rounded">
              <p> Book :{{ formatPriceBasic(summary.books)}} </p>
              <p> Magazines :{{ formatPriceBasic(summary.magazines)}} </p>
              <p> Newspaper :{{ formatPriceBasic(summary.newspaper)}} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="mt-2 pt-2 col-md-12">
      <div class="shadow-sm pb-5 mb-2">
        <ReadingChart />
      </div>
    </div>
    <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
      <template #overlay>
      </template>
    </b-overlay>  
  </div>
</template>

<script>
import { mapState,mapActions} from 'vuex'
import RevenueLine from '../components/dashboard/revenue'
import ReadingChart from "../components/chart/readingpopular.vue"
import moment from "moment";
  export default {
    name: "dashboarddigitalContent",
    components: {
      RevenueLine,
      ReadingChart,
    },
    data() {
      return {
        options: {},
        series: [],
        summary:{},
        loadChart: true,
        max_period: moment().subtract(6, 'month').format('YYYY-MM-DD'),
        label_chart:[],
        showCountMonth:12,
        typeChart:'line',
        seriesContent:[],
        optionsContent:{},
        name:'',
        groupBy:'month',
        busy: false,
        processing: false,
      };
    },
    computed: {
      ...mapState({
        items: (state) => state.dashboard.chartContent,
        isLoading: (state) => state.dashboard.isLoadingChartContent,
      })
    },
    mounted() {
      this.name = localStorage.getItem("session_name");
      this.actionLoadChartContent()
    },
    watch: {
      items() {
        if(this.items.length > 0){
          this.typeChart = 'line'
          this.loadChart = false
          this.actionSetLabelChart()
          this.actionLoadChart()
          this.summaryChart()
          this.busy = false
        }
      },
      $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || `Dashbaoard | ` +localStorage.getItem("session_name");
        }
      },
    },
    methods:{
      ...mapActions('dashboard',['contentAdmin']),
      actionLoadChartContent(){
        let payload = {
          from: moment().subtract(this.showCountMonth, 'month').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
          groupBy: this.groupBy
        }
        this.contentAdmin(payload)
        if(this.items.length > 0){
          this.label_chart = []
          this.actionSetLabelChart()
          this.busy = false
        }
      },
      actionshowCountMonth(count) {
        this.showCountMonth = count
        this.actionLoadChartContent()
        this.busy = true
      },
      actionGroup(groupName){
        this.groupBy = groupName
        this.actionLoadChartContent()
        this.busy = true
      },
      ActionRedender() {
        this.actionLoadChart()
      },
      actionChangeChartType(type) {
        this.typeChart = type
        this.ActionRedender()
      },
      actionSetLabelChart() {
        this.label_chart = []
       let startMonth = moment().subtract(this.showCountMonth, 'month').format('YYYY-MM-DD')
       if(this.groupBy == 'day'){
        let countDiff = moment().diff(moment(startMonth), 'days')
          for (let index = 0; index < countDiff; index++) {
            let inMonth = moment(startMonth).add(index, 'day').format('YYYY-MM-DD')
            this.label_chart.push(inMonth)
          }
       } else if(this.groupBy == 'week'){
        let countDiff = moment().diff(moment(startMonth), 'weeks')
          for (let index = 0; index < countDiff; index++) {
            let inMonth = moment(startMonth).add(index, 'week').format('YYYY-MM-DD')
            this.label_chart.push(moment(inMonth).endOf('week').format('YYYY-MM-DD'))
          }
       } else{
          for (let index = 0; index < this.showCountMonth; index++) {
            let inMonth = moment(startMonth).add(index, 'month').format('YYYY-MM-DD')
            this.label_chart.push(moment(inMonth).endOf('month').format('YYYY-MM-DD'))
          }
        }
      },
      setDataCart(labels,sales,type) {
        let data = []
        labels.forEach((e) => {
          if(sales.length > 0){
            let findInLabelMonth = sales.find(s => {
              if (this.groupBy == 'day') {
                return moment(s.release).format('YYYY-MM-DD') == moment(e).format('YYYY-MM-DD')
              } else if (this.groupBy == 'week') {
                return moment(s.release).format('YYYY-MM-DD') == moment(e).format('YYYY-MM-DD')
              } else {
                return moment(s.release).format('YYYY-MM') == moment(e).format('YYYY-MM')
              }
            })
            if (findInLabelMonth) {
              let amount = parseInt(findInLabelMonth.count,10)
              console.log("sales ",type,amount,findInLabelMonth)
              data.push(amount)
            } else {
              data.push(0)
            }
          } else {
            data.push(0)
          }
        });
        return data
      },
      actionLoadChart(){
        let labels = this.label_chart
        const rawBooks = this.items.filter((item) => item.item_type == 'book')
        const rawMagazines = this.items.filter((item) => item.item_type == 'magazine')
        const rawNewspaper = this.items.filter((item) => item.item_type == 'newspaper')
        let books = this.setDataCart(labels,rawBooks,'book')
        let magazines = this.setDataCart(labels,rawMagazines,'magazine')
        let newspaper = this.setDataCart(labels,rawNewspaper,'newspaper')
        this.options= {
          chart: {
            type: this.typeChart
          },
          stroke: {
            curve: 'smooth',
            width: [2,2,4]
          },
          markers: {
            size:8
          },
          colors: ['#0060af','#ffec2e','#ff06dc'],
          dataLabels: {
            enabled: false,
            position: 'bottom',
          },
          xaxis: {
            series:'Content',
            categories: labels,
          },
        }
        let buf = [{data:books,name:'Books',colors:['#43d14f']}]
        let sing = [{data:magazines,name:'Magazines'}]
        let bund = [{data:newspaper,name:'Newspaper'}]
        let series = [...buf,...sing]
        series =[...series,...bund]
        this.series =series
      },
      formatPriceBasic(value) {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
      Median(){
        let data = this.items.map(e => e.count)
        return data.sort((a, b) => a - b)[Math.floor(data.length / 2)]
      },
      MinimumDisbursement(){
        let data = this.items.map(e => parseFloat(e.count))
        let min = data[0];
        data.forEach(e => {
          if(e < min){
            min = e
          }
        });

        return min
      },
      MaximumDisbursement(){
        let data = this.items.map(e => parseFloat(e.count))
        let max = 0
        data.forEach(e => {
          if(e > max){
            max = e
          }
        });

        return max
      },
      Average(){
        let data = this.items.map(e => parseInt(e.count,10))
        return data.reduce((a, b) => a + b, 0) / data.length
      },
      summaryChart() {
        this.summary = {}
        let summary = {
          median : this.Median(),
          average : this.Average(),
          min : this.MinimumDisbursement(),
          max : this.MaximumDisbursement(),
          count : this.items.reduce((a, b) => a + parseInt(b.count,10), 0),
          books : this.items.filter((item) => item.item_type == 'book').reduce((a, b) => a + parseInt(b.count,10), 0),
          magazines : this.items.filter((item) => item.item_type == 'magazine').reduce((a, b) => a + parseInt(b.count,10), 0),
          newspaper : this.items.filter((item) => item.item_type == 'newspaper').reduce((a, b) => a + parseInt(b.count,10), 0),
        }
        this.summary = summary
      },
      onShown() {
        // Focus the dialog prompt
        this.$refs.dialog.focus()
      },
      onHidden() {
        // In this case, we return focus to the submit button
        // You may need to alter this based on your application requirements
        this.$refs.submit.focus()
      },
      showModal() {
        this.busy = true
      }
    }
  }
</script>
<style scoped>
.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
.cover {
  height: auto;
  width: 120px;
  padding:5px;
  margin: 5px 5px;
}
.center {
  width:90%;
  margin-left: auto;
  margin-right: auto;
}
.primary {
  border: 1px solid #0060af;
}
</style>