import API from "../plugins/http";

const Po = {
  namespaced: true,
  state: {
    items: [],
    totalRows: 0,
    totalDisbursement: 0,
    isLoading: false,
    isError: false,
    errorMessage: null,
  },
  actions: {
    async fetchGeneratePO365({ commit }, state) {
      try {
        commit("setLoading", true);
        commit("setItems", []);
        commit("setErrorMessage", null)
        commit("setError", false);
        const response = await API.post(`purchase-order`, state);

        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setDisbursement", response.data.data?.amount)
        commit("setLoading", false);
      } catch (error) {
        let response = error.response;
        commit("setItems", []);
        commit("setRows", 0);
        commit("setDisbursement", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status == 403) {
          let err = response.data.message;
          commit("setErrorMessage", err);
        } else if (response.status == 422) {
          let err = response.data?.errors;
          commit("setErrorMessage", err);
        } else {
          commit("setErrorMessage", response?.message);
        }
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setDisbursement(state, data) {
      state.totalDisbursement = data;
    },
  },
};

export default Po;
