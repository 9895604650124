var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row p-2 m-1"
  }, [_c('div', {
    staticClass: "w-1/2 pr-2 mb-4"
  }, [_c('div', {
    attrs: {
      "no-body": true
    }
  }, [_c('div', {
    staticClass: "p-0 clearfix bg-white shadow-md rounded-lg"
  }, [_c('i', {
    staticClass: "fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left rounded-bl-lg rounded-tl-md"
  }), _vm.order_paid_rp ? _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.order_paid_rp.total_payment, _vm.order_paid_rp.currency_code)) + " ")]) : _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Loading..."
    }
  })], 1), _c('div', {
    staticClass: "text-muted text-uppercase font-xs"
  }, [_vm._v(" Income ")])])])]), _c('div', {
    staticClass: "w-1/2 pl-2 mb-4"
  }, [_c('div', {
    attrs: {
      "no-body": true
    }
  }, [_c('div', {
    staticClass: "p-0 clearfix bg-white shadow-md rounded-lg"
  }, [_c('i', {
    staticClass: "fa fa-bell bg-danger p-4 font-2xl mr-3 float-left rounded-bl-lg rounded-tl-md"
  }), _vm.order_paid_rp ? _c('div', {
    staticClass: "h6 mb-0 pt-3"
  }, [_c('span', {
    staticClass: "text-red-500"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.order_cancel_rp.total_payment, _vm.order_cancel_rp.currency_code)) + " ")])]) : _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Loading..."
    }
  })], 1), _c('div', {
    staticClass: "text-muted text-uppercase font-xs"
  }, [_vm._v(" Failed Income ")])])])]), _c('div', {
    staticClass: "w-1/2 pr-2 mb-4"
  }, [_c('div', {
    attrs: {
      "no-body": true
    }
  }, [_c('div', {
    staticClass: "p-0 clearfix bg-white shadow-md rounded-lg"
  }, [_c('i', {
    staticClass: "fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left rounded-bl-lg rounded-tl-md"
  }), _vm.order_paid_dollar ? _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.order_paid_dollar.total_payment, _vm.order_paid_dollar.currency_code)) + " ")]) : _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Loading..."
    }
  })], 1), _c('div', {
    staticClass: "text-muted text-uppercase font-xs"
  }, [_vm._v(" Income ")])])])]), _c('div', {
    staticClass: "w-1/2 pl-2 mb-4"
  }, [_c('div', {
    attrs: {
      "no-body": true
    }
  }, [_c('div', {
    staticClass: "p-0 clearfix bg-white shadow-md rounded-lg"
  }, [_c('i', {
    staticClass: "fa fa-bell bg-danger p-4 px-4 rounded-bl-lg rounded-tl-md font-2xl mr-3 float-left"
  }), _vm.order_paid_dollar ? _c('div', {
    staticClass: "h6 text-danger mb-0 pt-3 text-red-500"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.order_cancel_dollar.total_payment, _vm.order_cancel_dollar.currency_code)) + " ")]) : _c('div', {
    staticClass: "h6 text-info mb-0 pt-3"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Loading..."
    }
  })], 1), _c('div', {
    staticClass: "text-muted text-uppercase font-xs"
  }, [_vm._v(" Failed Income ")])])])]), _c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "bg-white shadow-md border rounded p-1"
  }, [_c('ColumnChart')], 1)]), _c('div', {
    staticClass: "w-full flex justify-between mt-4 overflow-hidden"
  }, [_c('div', {
    staticClass: "w-1/2"
  }, [_c('DonutChart')], 1), _c('div', {
    staticClass: "w-1/2 rounded-md p-1"
  }, [_c('readingpopular')], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }