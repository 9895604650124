import API from "../plugins/http";
const promotions = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
  },
  getters: {
    promotions: (state) => state.items,
  },
  actions: {
    async fetchPromotions({ commit }, props) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `discounts?page=${props.page}&limit=${props.limit}`
        );
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async searchPromotions({ commit }, props) {
      try {
        const response = await API.get(
          `search-discounts?q=${props.q}&page=${props.page}&limit=${props.limit}`
        );
        commit("setLoading", false);
        return response;
      } catch (error) {
        return error;
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
  },
};

export default promotions;
