import API from '../plugins/http';
import axios from "axios";
const Orders = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: true,
    isLodingBtnSearch: false,
    isError: false,
    isLoadingOne: false,
    isErrorOne: false,
    isErrorPatch: false,
    ErrorMessage: '',
  },
  getters: {
    orders: (state) => state.items,
    orderPaid: (state) => {
      return state.items.filter((o) => o.order_status === 90000);
    },
    orderError: (state) => {
      return state.items.filter(
        (o) => o.order_status !== 90000 && o.order_status !== 20001,
      );
    },
  },

  actions: {
    async fetchOrders({ commit }, state) {
      commit('setErrorMessage', '');
      try {
        commit('setLoading', true);
        const orders = await API.get(`orders`, {
          params: state,
        });
        commit('setItems', orders.data.data);
        commit('setRows', orders.data.total);
        commit('setLoading', false);
        commit('setItems', orders.data.data.rows);
        commit('setRows', orders.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        let response = error;

        commit('setItems', []);
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);

        if (response.status == 403) {
          let err = response.data.message;
          console.log(err);
          commit('setErrorMessage', err);
        } else {
          commit('setErrorMessage', error.response);
        }
      }
    },
    async fetchOrdersById({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(`orders/detail`, { params: state });
        commit('setItem', response.data.data);
        commit('setLoadingOne', false);
        commit('setLoading', false);
      } catch (error) {
        let response = error.response;
        commit('setItem', []);
        commit('setLoadingOne', false);
        commit('setErrorOne', true);
        if (response.data.status == 403) {
          let err = response.data.message;
          commit('setErrorMessage', err);
        } else {
          commit('setErrorMessage', error.response);
        }
      }
    },
    async fetchOrdersByEmail({ commit }, state) {
      try {
        commit('setLoading', true);
        // const response = await API.get(`my-orders/email/` + state.email);
        const response = await API.get(
          `my-orders/email/${state.email}/?&page=${state.page}&limit=${state.limit}&email = ${state.email}`,
        );

        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        let response = error.response;
        commit('setItem', []);
        commit('setLoadingOne', false);
        commit('setErrorOne', true);
        if (response.data.status == 403) {
          let err = response.data.message;
          commit('setErrorMessage', err);
        } else {
          commit('setErrorMessage', error.response);
        }
      }
    },
    async searchOrders({ commit }, props) {
      try {
        commit('setLoadingBtn', true);
        const response = await API.get(
          `search-orders?userid=${props.userid}&page=${props.page}&limit=${props.limit}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoadingBtn', false);
        return response;
      } catch (error) {
        commit('setItem', []);
        commit('setLoadingOne', false);
        commit('setErrorOne', true);
        return error;
      }
    },
    async searchOrdersBydate({ commit }, props) {
      try {
        commit('setErrorMessage', '');
        commit('setLoadingBtn', true);
        commit('setLoading', true);
        commit('setItems', []);
        const response = await API.get(`orders`, {
          params: props,
        });
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoadingBtn', false);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        commit('setItems', []);
        commit('setLoadingOne', false);
        commit('setErrorOne', true);
        commit('setErrorMessage', error.response.data.message);
        return error;
      }
    },
    async patchOrders({ commit }, props) {
      try {
        return await API.post('customer-patch', props);
      } catch (error) {
        commit('setErrorOne', true);
      }
    },

    async postOrderManuaAppleId({ commit }, payload) {
      try {
        commit('setLoading', true);
        const data = {
          order_id: parseInt(payload.value.order_id),
          invoice_id: payload.value.invoice_id,
        }
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'api-key': 'eyJpZCI6IjEiLCJuYW1lIjoic2Nvb3BfaW9zX2FwcHMifQ',
          }
        };
    
        const response = await axios.post('/cms/validate/itunes-invoice/', data, config);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error?.response;
      }
    },
    async deliverAsset({ commit }, state) {
      const maps = {};
      try {
        const response = await API.post(`validate-itunes`, state);
        maps.message = response.data.data.message;
        maps.result = response.data.data.result;
        maps.offer_type = response.data.data.offer_type;
      } catch (error) {
        commit('setItem', []);
        let message = error.response.data.message.message;
        if (!message) {
          message = 'Internal Server Error';
        }
        maps.result = false;
        maps.message = message;
      }
      return maps;
    },

    async searchOrderNumber({ commit }, props) {
      try {
        commit('setErrorMessage', '');
        commit('setLoadingBtn', true);
        commit('setLoading', true);
        commit('setItems', []);
        const response = await API.get(
          `orders?from=${props.from}&to=${props.to}&clientId=${props.clientId}&ordernumber=${props.ordernumber}&page=${props.page}&limit=${props.limit}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoadingBtn', false);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        commit('setItems', []);
        commit('setLoadingOne', false);
        commit('setErrorOne', true);
        commit('setErrorMessage', error.response.data.message);
        return error;
      }
    },

    async updateOfs({ commit }, props) {
      commit('setLoading', false);
      return API.put('orders/update-ofs', props);
    },

    async updatePurchaseToken({ commit }, props) {
      commit('setLoading', true);
      try {
        const response = await API.put('orders/insert-purchase-token', props);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error?.response;
      }
    },

    async updateGPA({ commit }, props) {
      commit('setLoading', true);
      try {
        const response = await API.put('orders/update-gpa', props);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error?.response;
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setLoadingOne(state, data) {
      state.isLoadingOne = data;
    },
    setLoadingBtn(state, data) {
      state.isLodingBtnSearch = data;
    },
    setErrorOne(state, data) {
      state.isErrorOne = data;
    },
    setErrorPatch(state, data) {
      state.isErrorPatch = data;
    },
    setErrorMessage(state, data) {
      state.ErrorMessage = data;
    },
  },
};

export default Orders;
