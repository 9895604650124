const destructErrResponse = (error) => {
  const statusCode = error.status ?? error.response?.status;
  const statusText = error.statusText ?? error.response?.statusText;
  const data = error.data ?? error.response?.data;
  let message = error.data?.message ?? error.response?.data?.message;
  
  return { statusCode, statusText, data, message};
}

module.exports = destructErrResponse;
