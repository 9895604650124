import API from "../plugins/http";
const buffets = {
  namespaced: true,
  state: {
    items: [],
    totalRows: 0,
    total :0,
    totalAmount: 0,
    totalAmountD :0,
    totalAmountPTS: 0,
    isLoading: false,
    isLoadingDelete: false,
    isError: false,
    message: null,
    errorMessage: null
  },
  getters: {
    buffets: (state) => state.items,
  },

  actions: {
    async fetchReport({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `report-buffets?from=${state.from}&to=${state.to}&page=${state.page}&limit=${state.limit}`
        );

        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
        return response;
      } catch (error) {
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        let response = error
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",response)
        }
      }
    },
    async fetchReportBuffets({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `report-buffets-summary?from=${state.from}&to=${state.to}`
        );
        let res = response.data.data;
        commit("setItems",res)
        let t = res.map((e) => {
          if (e.currency_code == "IDR") {
              return  parseInt(e.amount);
            }
            return 0;
        });
        let pts = res.map((e) => {
          if (e.currency_code == "PTS") {
            return parseInt(e.amount);
          }
          return 0;
        });
        let td = res.map((e) => {
          if (e.currency_code == "USD") {
            return parseFloat(e.amount);
          }
          return 0;
        });
        
        let totalRows = res.length;
        let totalAmount = t.reduce((a, b) => a + b);
        let totalAmountD = td.reduce((a, b) => a + b);
        let totalAmountPTS = pts.reduce((a, b) => a + b);

        commit("setRows",totalRows)
        commit("setAmount",totalAmount)
        commit("setAmountD",totalAmountD)
        commit("setAmountPTS",totalAmountPTS)
        commit("setLoading", false);
        return response;
      } catch (error) {
        commit("setError", true);
        commit("setLoading", false);
        let response = error
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",response)
        }
        return error;
      }
    },
    async postBuffetByOrderID({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.post(`my-buffets`, state);
        commit("setLoading", false);
        return response;
      } catch (error) {
        commit("setError", true);
        commit("setLoading", false);
        commit("setMessage", error);
        let response = error
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",response)
        }
        return false;
      }
    },
    async updateBuffetByid({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.post(`user-buffets/update`, state);

        commit("setLoading", false);
        return response;
      } catch (error) {
        commit("setError", true);
        commit("setMessage", error);
        commit("setLoading", false);
        let response = error
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",response)
        }
        return error;
      }
    },
    async buffetDelete({ commit }, state) {
      try {
        commit("setLoadingDelete", true);
        const response = await API.post(`my-buffets/delete`, state);

        commit("setLoadingDelete", false);
        return response;
      } catch (error) {
        commit("setError", true);
        commit("setMessage", error);
        commit("setLoadingDelete", false);
        commit("setLoading", false);
        let response = error
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",response)
        }
        return error;
      }
    },
  },

  mutations: {
    setItems(state, item) {
      console.log(item);
      state.items = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setLoadingDelete(state, data) {
      state.isLoadingDelete = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setMessage(state, data) {
      state.message = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setTotal(state, item) {
      state.total = item;
    },
    setAmountD(state, item) {
      state.totalAmountD = item;
    },
    setAmountPTS(state, item) {
      state.totalAmountPTS = item;
    },
    setAmount(state, item) {
      state.totalAmount = item;
    },
  },
};

export default buffets;
