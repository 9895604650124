import API from '../plugins/http';

const Currency = {
  namespaced: true,
  state: {
    items: [],
    item_id :[],
    product: [],
    totalRowsProduct: 0,
    isLoadingProduct: false,
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMessage: '',
    successMessage:'',
    refresh: false,
    status:'',

  },
  getters: {
  },
  actions: {
    async fetchCurrencyAll({ commit }, state) {
      try {
        commit('setSuccessMessage', null);
        commit('setErrorMessage',null);
        commit("setLoading", true);
        commit("setItems", []);
        const response = await API.get(
          'currency-quotes', {state}
        );
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setError", false);
        commit("setLoading", false);
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    }, async searchCurrencyQuotes({ commit }, state) {
      try {
        const response = await API.get(`currency-quotes/`, { params: state });
        commit("setRows", response.data.data.count);
        commit("setItems", response.data.data.rows);
        commit("setLoading", false);
        return response;
      } catch (error) {
        return error;
      }
    }, async displayCurrencyById({ commit }, props) {
      try {
        commit("setLoading", true);
        const response = await API.get(`currency-quotes/${props.id}`);
        commit("setItems",[response.data.data]);
        commit("setRows", 1);
        commit("setLoading", false);
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
     async getCurrencyQuotesByIdHome({ commit }, props) {
      try {
        commit("setLoading", true);
        const response = await API.get(`currency-quotes/${props.id}`);
        
        commit("setItems",response.data.data);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },async createCurrencyQuotes({ commit }, payload) {
      commit('setLoading', true);
      commit('setSuccessMessage', null);
      commit('setErrorMessage',null);
      commit("setItems",null)
      let response = null;
      let message  = 'Error';
      try {
        response = await API.post(`/currency-quotes`, payload);
        message = response.data.data.message;
        commit("setError",false);
        commit("setSuccessMessage", message);
        commit('setLoading', false);
      } catch (error) {
        message ='Server Error';
        if(error.response.data.message.message){
          message = error.response.data.message.message;        
        }
        let errMsg = 'Error';
        const response = error.response;
        if (response.status == 404) {
          console.log("this is response", response)
          errMsg = response.data.data.message;
        }
        if (response.status == 422) {
            errMsg = response.statusText;
            errMsg += ' REQUIRED ';
            errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
          }
          if (response.status == 500) {
            errMsg = response.data.data.message ?? response.statusText;
          }
        commit("setError",true);
        commit("setErrorMessage", errMsg);
        commit("setLoading", false);
      }
      commit('setLoading', false);
    },async updateCurrencyQuotes({ commit }, payload) {
      commit('setLoading', true);
      commit('setSuccessMessage', null);
      commit('setErrorMessage',null);
      commit("setItems",null)
      let response = null;
      let message  = 'Error';
      let result = false;
      try {
        response = await API.put(`/currency-quotes/updateAll`, payload);
        result = response.data.data.result;
        message = response.data.data.message;
        commit("setError",!result);
        commit("setSuccessMessage", message);
        commit("setItems",null);
        commit('setLoading', false);
      } catch (error) {
        message ='Server Error';
        if(error.response.data.message.message){
          message = error.response.data.message.message;        
        }
        let errMsg = 'Error';
        const response = error.response;
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
            errMsg = response.statusText;
            errMsg += ' REQUIRED ';
            errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
          }
          if (response.status == 500) {
            errMsg = response.data.data.message ?? response.statusText;
          }
        commit("setError",true);
        commit("setErrorMessage", errMsg);
        commit("setLoading", false);
      }
      commit('setLoading', false);
    },async updateIsActive({ commit }, payload) {
      commit('setLoading', true);
      commit("setItems",null)
      commit('setSuccessMessage', null);
      commit('setErrorMessage',null);
      let response = null;
      let message  = 'Error';
      let result = false;
      const maps = {};
      try {
        response = await API.put(`/currency-quotes/updateIsActive`, payload);
        result = response.data.data.result;
        message = response.data.data.message;
        maps.message = message;
        maps.result = result
        
      } catch (error) {
        message ='Server Error';
        if(error.response.data.message.message){
          message = error.response.data.message.message;        
        }
        let errMsg = 'Error';
        const response = error.response;
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
            errMsg = response.statusText;
            errMsg += ' REQUIRED ';
            errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
          }
          if (response.status == 500) {
            errMsg = response.data.data.message ?? response.statusText;
          }
        commit("setLoading", false);
        maps.message = errMsg;
      }
      return maps;
    },
    
  },
  mutations: {
    setItems(state, data) {
      state.items = data;
    },
    setItem(state, data) {
      state.item = data;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },setRefresh(state, data) {
      state.refresh = data;
    },
  },
};

export default Currency;
