import API from "../plugins/http";
const emailReport = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    isError: false,
    refresh: false,
  },
  getters: {
  },
  actions: {
    async fetchListEmail({ commit }, props) {
      try {
        commit('setError', false);
        commit("setLoading", true);
        const response = await API.get(`email-report`, {
          params: {
            page: props.page,
            limit: props.limit,
            q: props.q,
          }
        });
        commit("setItems", response.data?.data.rows);
        commit("setRows", response.data?.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async fetchEmailByID({ commit }, id) {
      try {
        commit('setError', false);
        commit("setLoading", true);
        const response = await API.get(`email-report/${id}`);
        commit("setItem", response.data?.data);
        commit("setLoading", false);
      } catch (error) {
        const statusCode = error.status ?? error.response?.status;
        let message = error.data?.message ?? error.response?.data?.message;
        if (statusCode == 422) {
          const attr = error.response?.data.data?.map(val => val.param).join(' ,');
          message += ` (${attr})`;
        }
        commit('setError', true);
        commit('setErrorMessage', message);
        commit('setLoading', false);
      }
    },
    async postEmailReport({ commit }, props) {
      commit('setLoading', true);
      commit('setError', true);
      commit('setSuccessMessage', null);
      commit('setErrorMessage', null);
      try {
        const response = await API.post(`email-report`, props);
        commit('setLoading', false);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success');
        }
      } catch (error) {
        const statusCode = error.status ?? error.response?.status;
        let message = error.data?.message ?? error.response?.data?.message;
        if (statusCode == 422) {
          const attr = error.response?.data.data?.map(val => val.param).join(' ,');
          message += ` (${attr})`;
        }
        if (statusCode == 404) {
          message = error.response?.data.data?.message ?? message;
        }
        if (statusCode == 409) {
          message = error.response?.data.data?.message ?? message;
        }
        commit('setError', true);
        commit('setErrorMessage', message);
        commit('setLoading', false);
      }
    },
    async updateEmailReport({ commit }, props) {
      commit('setLoading', true);
      commit('setError', true);
      commit('setSuccessMessage', null);
      commit('setErrorMessage', null);
      try {
        const response = await API.put(`email-report/${props.id}`, props);
        commit('setLoading', false);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success');
        }
      } catch (error) {
        const statusCode = error.status ?? error.response?.status;
        let message = error.data?.message ?? error.response?.data?.message;
        if (statusCode == 422) {
          const attr = error.response?.data.data?.map(val => val.param).join(' ,');
          message += ` (${attr})`;
        }
        commit('setError', true);
        commit('setErrorMessage', message);
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRefresh(state, data) {
      state.refresh = data;
    },
  },
};

export default emailReport;
