const UserEbookManagePath = {
  path: '/access/user-ebooks',
  name: 'userEbookManage',
  component: () => import('../views/access/manageUserEbook.vue'),
}
const UserEbookCreatePath = {
  path: '/access/user-ebooks/create',
  name: 'userEbookCreate',
  component: () => import('../views/access/newUserEbook.vue'),
}
const UserEbookEditPath = {
  path: '/access/user-ebooks/edit/:id',
  name: 'userEbookEdit',
  component: () => import('../views/access/editUserEbook.vue'),
}
const RoleManagePath = {
  path: '/access/roles',
  name: 'roleManage',
  component: () => import('../views/access/manageRole.vue'),
}
const RoleCreatePath = {
  path: '/access/roles/create',
  name: 'roleCreate',
  component: () => import('../views/access/newRole.vue'),
}
const RoleEditPath = {
  path: '/access/roles/edit/:id',
  name: 'roleEdit',
  component: () => import('../views/access/editRole.vue'),
}
const PermissionManagePath = {
  path: '/access/permission',
  name: 'permissionManage',
  component: () => import('../views/access/managePermission.vue'),
}
const PermissionCreatePath = {
  path: '/access/permission/create',
  name: 'permissionCreate',
  component: () => import('../views/access/newPermission.vue'),
}
const PermissionEditPath = {
  path: '/access/permission/edit/:id',
  name: 'permissionEdit',
  component: () => import('../views/access/editPermission.vue'),
}
const PublisherManagePath = {
  path: '/access/publisher',
  name: 'publisherManage',
  component: () => import('../views/access/managePublisher.vue'),
}
const PublisherEmailPath = {
  path: '/access/publisher/emails',
  name: 'publisherEmail',
  component: () => import('../views/access/email/emailReports.vue'),
}
const PublisherEmailCreatePath = {
  path: '/access/publisher/emails/create',
  name: 'publisherEmailCreate',
  component: () => import('../views/access/email/createEmail.vue'),
}
const PublisherEmailUpdatePath = {
  path: '/access/publisher/emails/update/:id',
  name: 'publisherEmailUpdate',
  component: () => import('../views/access/email/updateEmail.vue'),
}
const PublisherCreatePath = {
  path: '/access/publisher/create',
  name: 'publisherCreate',
  component: () => import('../views/access/newPublisher.vue'),
}
const PublisherUpdatePath = {
  path: '/access/publisher/update/:id',
  name: 'publisherUpdate',
  component: () => import('../views/access/updatePublisher.vue'),
}
const CustomerSupportManagePath = {
  path: '/access/support',
  name: 'customerSupportManage',
  component: () => import('../views/access/manageCustomerSupport.vue'),
}
const CustomerSupportCreatePath = {
  path: '/access/support/create',
  name: 'customerSupportCreate',
  component: () => import('../views/access/newCustomerSupport.vue'),
}
const CompanyManagePath = {
  path: '/access/company',
  name: 'companyManage',
  component: () => import('../views/access/manageCompany.vue'),
}
const CompanyCreatePath = {
  path: '/access/company/create',
  name: 'companyCreate',
  component: () => import('../views/access/newCompany.vue'),
}

module.exports = {
  UserEbookManagePath,
  UserEbookCreatePath,
  UserEbookEditPath,
  RoleManagePath,
  RoleCreatePath,
  RoleEditPath,
  PermissionManagePath,
  PermissionCreatePath,
  PermissionEditPath,
  PublisherManagePath,
  PublisherEmailPath,
  PublisherEmailCreatePath,
  PublisherEmailUpdatePath,
  PublisherCreatePath,
  PublisherUpdatePath,
  CustomerSupportManagePath,
  CustomerSupportCreatePath,
  CompanyManagePath,
  CompanyCreatePath,
}