import API from '../plugins/http';
const ebooks = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    isLoadingFetch: false,
  },
  getters: {
    ebooks: (state) => state.items,
  },
  actions: {
    async fetchEbooks({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `ofs-catalog?page=${props.page}&limit=${props.limit}&sort=${props.sort}`,
        );
        commit('setLoading', false);
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
      } catch (error) {
        console.log(error);
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchEbookById({ commit }, props) {
      try {
        commit('setItems', []);
        const response = await API.get(`ofs-catalog/${props.id}`);

        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async searchBooks({ commit }, props) {
      try {
        const response = await API.get(
          `ofs-catalog?q=${props.q}&page=${props.page}&limit=${props.limit}&sort=${props.sort}`,
        );
        commit('setLoading', false);
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        return response;
      } catch (error) {
        return error;
      }
    },
    async sendItem({ commit }, props) {
      commit('setLoadingFetch', true);
      return API.post('ofs-catalog', props);
    },
    async updateItem({ commit }, props) {
      commit('setLoadingFetch', true);
      return API.post('ofs-catalog/bulk', props);
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setLoadingFetch(state, data) {
      state.isLoadingFetch = data;
    },
    setError(state, data) {
      state.isError = data;
    },
  },
};

export default ebooks;
