import API from "../plugins/http";
const Orders = {
  namespaced: true,
  state: {
    items: [],
    sales_item:[],
    isLoadingSale:false,
    isErrorSales:false,
    payments: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMessage:null
  },
  getters: {
    items: (state) => state.items,
    isLoading: (state) => state.isLoading,
    isError: (state) => state.isError,
    payment: (state) => state.payments,
    errorMessage:(state)=> state.errorMessage
  },

  actions: {
    async fetchReport({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `reports?from=${state.from}&to=${state.to}`
        );
        let count = response.data ? response.data.data : [];
        commit("setItems", response.data.data);
        commit("setRows", count.length);
        commit("setLoading", false);
        return response.data;
      } catch (error) {
        let response = error
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },
    async fetchReportByPayment({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `reports/payments?from=${state.from}&to=${state.to}`
        );
        let data = response.data ? response.data.data : [];
        commit("setItems", data.rows);
        commit("setRows", data.length);
        commit("setLoading", false);
        commit("setPayments", data.paymentgateway_id);

        return response.data;
      } catch (error) {
        let response = error
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status ==403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },
    async fetchReportByBendor({ commit }, state) {
      try {
        commit("setLoadingSales", true);
        const response = await API.get(
          `reports/vendor/${state.id}?from=${state.from}&to=${state.to}`
        );
        let data = response.data ? response.data.data : [];
        commit("setItemSales", data.rows);
        commit("setRows", data.length);
        commit("setLoadingSales", false);
        return response.data;
      } catch (error) {
        let response = error
        commit("setItems", []);
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        if (response.status == 403) {
          let err =response.data.message
          commit("setErrorMessage",err)  
        } else {
          commit("setErrorMessage",error.response)
        }
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItemSales(state, item) {
      state.sales_item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setLoadingSales(state, data) {
      state.isLoadingSale = data;
    },
    setErrorSales(state, data) {
      state.isErrorSales = data;
    },
    setPayments(state, data) {
      state.payments = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default Orders;
