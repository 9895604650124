const SinglePath = {
  path: '/offers/single',
  name: 'singleOffers',
  component: () => import('../views/offers/offers.vue'),
};
const SingleCreatePath = {
  path: '/offers/single/create',
  name: 'singleOffersCreate',
  component: () => import('../views/offers/newSingleOffers.vue'),
};
const SingleEditPath = {
  path: '/offers/single/edit/:id',
  name: 'singleOffersEdit',
  component: () => import('../views/offers/editSingleOffers.vue'),
};
const SubscriptionPath = {
  path: '/offers/subscriptions',
  name: 'subscriptionOffers',
  component: () => import('../views/offers/subscriptionOffers.vue'),
};
const SubscriptionCreatePath = {
  path: '/offers/subscriptions/create',
  name: 'subscriptionOffersCreate',
  component: () => import('../views/offers/newSubscriptionOffers.vue'),
};
const SubscriptionEditPath = {
  path: '/offers/subscriptions/edit/:id',
  name: 'subscriptionOffersEdit',
  component: () => import('../views/offers/editSubscriptionOffers.vue'),
};
const SubscriptionDetailPath = {
  path: '/offers/subscriptions/detail/:id',
  name: 'subscriptionOffersDetail',
  component: () => import('../views/offers/detailSubscriptionOffers.vue'),
};
const BundlePath = {
  path: '/offers/bundle',
  name: 'bundleOffers',
  component: () => import('../views/offers/bundleOffers.vue'),
};
const BundleCreatePath = {
  path: '/offers/bundle/create',
  name: 'bundleOffersCreate',
  component: () => import('../views/offers/newBundleOffers.vue'),
};
const BundleEditPath = {
  path: '/offers/bundle/edit/:id',
  name: 'bundleOffersEdit',
  component: () => import('../views/offers/editBundleOffers.vue'),
};
const Premium = {
  path: '/offers/premium',
  name: 'premiumOffers',
  component: () => import('../views/offers/premiumoffers.vue'),
};
const PremiumDetail = {
  path: '/offers/premium/detail/:id',
  name: 'premiumOffersDetail',
  component: () => import('../views/offers/premiumoffers-detail.vue'),
};
const PremiumCreatePath = {
  path: '/offers/premium/create',
  name: 'premiumOffersCreate',
  component: () => import('../views/offers/newPremiumOffers.vue'),
};
const PremiumEditPath = {
  path: '/offers/premium/edit/:id',
  name: 'premiumOffersEdit',
  component: () => import('../views/offers/editPremiumOffers.vue'),
};
const PremiumItemPath = {
  path: '/offers/premium/:id/items',
  name: 'itemsPremiumOffers',
  component: () => import('../views/offers/premiumDetailitems.vue'),
};
const AndroidTierManagePath = {
  path: '/tiercode/android',
  name: 'manage-android-tier',
  component: () => import('../views/tiercode/manageAndroidTier.vue'),
};
const AndroidTierCreatePath = {
  path: '/tiercode/android/create',
  name: 'new-android-tier',
  component: () => import('../views/tiercode/newAndroidTier.vue'),
};
const AndroidTierEditPath = {
  path: '/tiercode/android/edit/:id',
  name: 'edit-android-tier',
  component: () => import('../views/tiercode/editAndroidTier.vue'),
};
const IosTierManagePath = {
  path: '/tiercode/ios',
  name: 'manage-ios-tier',
  component: () => import('../views/tiercode/manageIosTier.vue'),
};
const IosTierCreatePath = {
  path: '/tiercode/ios/create',
  name: 'new-ios-tier',
  component: () => import('../views/tiercode/newIosTier.vue'),
};
const IosTierEditPath = {
  path: '/tiercode/ios/edit/:id',
  name: 'edit-ios-tier',
  component: () => import('../views/tiercode/editIosTier.vue'),
};
const InvalidTiercodeOfferPath = {
  path: '/offers/invalid-price-idr/:platform',
  name: 'manage-invalid-tiercode-offers',
  component: () => import('../views/offers/invalidTiercodeOffers.vue'),
};

/// as discount
const DiscountPath = {
  path: '/discounts',
  name: 'discounts',
  component: () => import('../views/discounts/discount.vue'),
};
const CreateDiscountPath = {
  path: '/discount-add',
  name: 'createDiscounts',
  component: () => import('../views/discounts/create.vue'),
};
const DiscountEditPath = {
  path: '/discounts-update/:id',
  name: 'updateDiscount',
  component: () => import('../views/discounts/edit-discount.vue'),
};
const DiscountSpecified = {
  path: '/discounts-specified/:id',
  name: 'specifiedDiscount',
  component: () => import('../views/discounts/discount-specified.vue'),
};
const DiscountDetailPath = {
  path: '/discounts-detail/:id',
  name: 'detailDiscount',
  component: () => import('../views/discounts/detail-discount.vue'),
};

const CampaignPath = {
  path: '/campaign',
  name: 'campaign',
  component: () => import('../views/campaign/campaign.vue'),
};
const CreateCampaignPath = {
  path: '/campaign/create',
  name: 'createCampaign',
  component: () => import('../views/campaign/create.vue'),
};
const DiscountCodePath = {
  path: '/discount-code',
  name: 'discount-code',
  component: () => import('../views/discounts-code/discounts-code.vue'),
};
const CreatediscountCodePath = {
  path: '/discount-code/create',
  name: 'discountCode',
  component: () => import('../views/discounts-code/discountCodeAdd.vue'),
};
const DiscountLogManagePath = {
  path: '/discounts-log',
  name: 'discountLogManage',
  component: () => import('../views/discounts/manageDiscountLog.vue'),
};
const DiscountLogDetailPath = {
  path: '/discounts-log/:id',
  name: 'discountLogDetail',
  component: () => import('../views/discounts/detailDiscountLog.vue'),
};
const BannersPath = {
  path: '/banner',
  name: 'banner',
  component: () => import('../views/banners/banners.vue'),
};
const BannersAddPath = {
  path: '/banner-add',
  name: 'bannersAdd',
  component: () => import('../views/banners/addBanners.vue'),
};
const BannersEditPath = {
  path: '/banner-edit/:id',
  name: 'bannersEdit',
  component: () => import('../views/banners/editBanners.vue'),
};
const DisplayCurrencyQuotes = {
  path: '/currencyQuotes/display',
  name: 'currencyQuotesDisplay',
  component: () => import('../views/currencyQuotes/displayCurrencyQuotes.vue'),
};
const EditSingleCurrencyQuotes = {
  path: '/currencyQuotes/edit/:id',
  name: 'currencyQuotesEdit',
  component: () => import('../views/currencyQuotes/editCurrencyQuotes.vue'),
};
const CreateSingleCurrencyQuotes = {
  path: '/currencyQuotes/create',
  name: 'currencyQuotesCreate',
  component: () => import('../views/currencyQuotes/createCurrencyQuotes.vue'),
};
module.exports = {
  SinglePath,
  SingleCreatePath,
  SingleEditPath,
  Premium,
  PremiumDetail,
  PremiumItemPath,
  PremiumCreatePath,
  PremiumEditPath,
  AndroidTierManagePath,
  AndroidTierCreatePath,
  AndroidTierEditPath,
  IosTierManagePath,
  IosTierCreatePath,
  IosTierEditPath,
  DiscountPath,
  CreateDiscountPath,
  DiscountEditPath,
  DiscountDetailPath,
  DiscountSpecified,
  CampaignPath,
  CreateCampaignPath,
  InvalidTiercodeOfferPath,
  DiscountCodePath,
  CreatediscountCodePath,
  DiscountLogManagePath,
  DiscountLogDetailPath,
  SubscriptionPath,
  SubscriptionCreatePath,
  SubscriptionEditPath,
  SubscriptionDetailPath,
  BundlePath,
  BundleCreatePath,
  BundleEditPath,
  BannersPath,
  BannersAddPath,
  BannersEditPath,
  DisplayCurrencyQuotes,
  EditSingleCurrencyQuotes,
  CreateSingleCurrencyQuotes,
};
