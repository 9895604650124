<template>
  <div class="p-4 bg-white rounded-lg shadow-md">
    <label class="ml-4 text-base block mb-4"> Popular Reading - {{ currentMonth }}</label>
      <apexchart
      type="bar"
      height="320"
      :options="chartReading"
      :series="ReadingSeries"
    />
  </div>
</template>

<script>
import moment from "moment";
import API from "../../plugins/http";

export default {
  name: "PopularItemRead",
  data() {
    return {
      chartReading: {},
      ReadingSeries: [{ data: [], name: "Reading" }],
      items: [],
      duration: [],
      books: [],
      currentMonth: moment().format("MMMM YYYY")
    };
  },
  mounted() {
    this.actionGet();
  },
  methods: {
    actionGet() {
      let from = moment().format("YYYY-MM-DD");
      API.get('reading-durations/popular?from=' + from).then(response => {
        this.items = response.data.data.item;
        this.duration = response.data.data.reading;
        this.actionDestructor();
      });
    },
    actionDestructor() {
      this.items.forEach(e => {
        let find = this.duration.find(d => parseInt(d.item_id) == e.id);
        this.books.push({
          name: e.name,
          isbn: e.gtin13,
          duration: find.duration,
          item_type: find.item_type,
        });
      });

      this.actionBuildChart();
    },
    actionBuildChart() {
      let book = this.books.map(i => i.name);
      this.books.sort((a, b) => (a.duration < b.duration) ? 1 : ((b.duration < a.duration) ? -1 : 0));
      let total = this.books.map(i => i.duration);

      this.ReadingSeries[0].data = total;
      this.chartReading = {
        chart: {
          toolbar: {
            show: false
          },
          fontFamily: "Inter, sans-serif",
          offsetX: 10,
          offsetY: -10,
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            horizontal: true,
            distributed: true,
            barHeight: '90%',
            fontFamily: "Inter, sans-serif",
          },
        
        },
        colors: ['#4F46E5', '#6366F1', '#8B5CF6', '#A855F7', '#D946EF', '#EC4899', '#F43F5E', '#E11D48', '#BE123C', '#9F1239'],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            colors: ['#ffffff']
          },
          formatter: value => this.formatMinutes(value)
        },
        tooltip: {
          theme: "white",
          y: {
            formatter: value => this.formatMinutes(value)
          }
        },
        title: {
          // text: "Popular Reading in " + this.currentMonth,
          align: "left",
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#333333'
          }
        },
        xaxis: {
          categories: book,
          labels: {
            show: true,
            style: {
              fontSize: '14px',
              fontWeight: '600',
              colors: '#333333'
            },
            formatter: value => this.formatMinutes(value)
          }
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '14px',
              fontWeight: '600',
              colors: '#333333'
            },
          }
        },
        legend: {
            show: false,
          },
        grid: {
          borderColor: '#e7e7e7',
          strokeDashArray: 1,
        }
      };
    },
    formatMinutes(data) {
      let menit = parseInt(data) / 3600;
      if (menit > 59) {
        let hour = menit / 60;
        return Math.round(hour) + ' Hour';
      } else if (menit < 60) {
        return Math.round(menit) + ' Minute';
      } else {
        return Math.round(data) + ' Second';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/* Custom styles can be added here if needed */
</style>
