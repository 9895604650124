
import API from '../plugins/http';
import Utils from '../plugins/util';

const banners = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    isError: false,
    refresh: false,
  },
  getters: {
    banners: (state) => state.items,
  },
  actions: {
    async fetchBanners({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `banners?page=${props.page}&limit=${props.limit}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchBannersByid({ commit }, props) {
      try {
        commit("setLoading", true);
        const response = await API.get(`banners/${props.id}`);
        const data = response.data.data;
        commit("setItem", data);
        commit("setLoading", false);
        return data;
      } catch (error) {
        const response = error.response;
        commit("setLoading", false);
        commit("setError", true);
        if (response.status == 404) {
          commit('setErrorMessage', 'banner not found');
        } else {
          commit('setErrorMessage', 'Internal Server Error');
        }
      }
    },
    async searchBanners({ commit }, props) {
      commit('setLoading', true);
      try {
        const q = Utils.encodeUrlWithBase64(props.q);
        const response = await API.get(
          `search-banners/name/${q}?page=${props.page}&limit=${props.limit}`,
        );
        commit('setRows', response.data.data.count);
        commit('setItems', response.data.data.rows);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error;
      }
    },

    async fetchNotifBannersByid({ commit }, props) {
      try {
        commit("setLoading", true);
        const response = await API.get(`notif-banner/banner-id/${props}`);
        const data = response.data.data;
        commit("setItem", data);
        commit("setLoading", false);
        return data;
      } catch (error) {
        const response = error.response;
        commit("setLoading", false);
        commit("setError", true);
        if (response.status == 404) {
          commit('setErrorMessage', 'notif banner not found');
        } else {
          commit('setErrorMessage', 'Internal Server Error');
        }
      }
    },

    async bannerPost({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const formData = new FormData();
        formData.append('client_id', payload.platform);
        formData.append('name', payload.name);
        formData.append('show_price', payload.displayPrice);
        formData.append('zone', payload.displayZone);
        formData.append('banner_type', payload.bannerType);
        formData.append('valid_from', payload.valid_from);
        formData.append('valid_to', payload.valid_to);
        formData.append('payload', payload.payload);
        formData.append('campaign_id', payload.campaign.id);
        formData.append('term_and_condition', payload.termCondition);
        formData.append('image_file', payload.file);
        formData.append('title', payload.notifation);
        formData.append('content', payload.notifContent);
        formData.append('notice_date', payload.notifDisplayDate);
        formData.append('sort_priority', payload.sortPriority);
        const response = await API.post(`banners/`, formData);
        commit('setRefresh', false);
        if (response.status == 201) {
          commit('setSuccessMessage', "Success Created");
          commit('setRefresh', true);
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) {
          errMsg = response.statusText;
        }
        if (response.status == 422) {
          errMsg = (response.data.errors ?? []).map(item => `[${item.param}:${item.msg}]`).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },

    async bannerUpdate({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);

      try {
        const formData = new FormData();
        formData.append('client_id', payload.client_id);
        formData.append('name', payload.name);
        formData.append('show_price', payload.show_price);
        formData.append('zone', payload.zone);
        formData.append('banner_type', payload.banner_type);
        formData.append('valid_from', payload.valid_from);
        formData.append('valid_to', payload.valid_to);
        formData.append('payload', payload.payload);
        formData.append('campaign_id', payload.campaign_id.id);
        formData.append('term_and_condition', payload.term_and_condition);
        formData.append('image_file', payload.image_file);
        formData.append('title', payload.title);
        formData.append('content', payload.content);
        formData.append('notice_date', payload.notice_date);
        formData.append('sort_priority', payload.sortPriority);
        const response = await API.put(`banners/${payload.id}`, formData);
        commit('setRefresh', false);
        if (response.status == 201) {
          commit('setSuccessMessage', "Update success");
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error?.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) {
          errMsg = response.statusText;
        }
        if (response.status == 422) {
          errMsg = (response.data.errors ?? []).map(item => `[${item.param}:${item.msg}]`).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
    async searchCampaign({ commit }, state) {
      try {
        const response = await API.get(`search-campaign?q=${state.q}`);
        console.log(response, 'a');
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },
    async fetchCampaignById({ commit }, props) {
      try {
        const response = await API.get(`campaign/search/${props}`);
        console.log(response, 'e');
        commit('setItem', response.data.data);
        commit('setItems', [response.data.data]);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setError', true);
        return error;
      }
    },
    async fetchCampaignId({ commit }, props) {
      commit('setLoading', false);
      try {
        const response = await API.get(`campaign/${props}`);
        return response;
      } catch (error) {
        return error;
      }
    },

  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRefresh(state, data) {
      state.refresh = data;
    },
  },
};

export default banners;
