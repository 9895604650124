const ProfileSetting = {
  path: '/settings/profile',
  name: 'profile',
  component: () => import('../views/profile/profile.vue'),
};

const UpdateAuth = {
  path: '/settings/updateAuth',
  name: 'UpdateAuth',
  component: () => import('../views/profile/updateAuth.vue'),
};


module.exports = {
  ProfileSetting,
  UpdateAuth,
};
