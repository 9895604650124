import API from '../plugins/http';
const logOfs = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    ErrorMessage: "",
  },
  getters: {
    logOfs: (state) => state.items,
  },
  actions: {
    async fetchOfsByItemID({ commit }, props) {
      commit('setError', false);
      commit('setItems', []);
      try {
        commit('setLoading', true);
        const response = await API.get(
          `log-ofs-catalog/${props.itemId}?page=${props.page}&limit=${props.limit}`,
        );
        commit('setLoading', false);
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
      } catch (error) {
        console.log(error.response.status);
        commit('setLoading', false);
        commit('setError', true);
        // if response 404
        if (error.response.status === 404) {
          commit('setErrorMessages', 'Not Found History');
        } else if (error.response.status === 500) {
          commit('setErrorMessages', 'Server Busy Please Try Again Later');
        }
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessages(state, data) {
      state.ErrorMessage = data;
    }
  },
};

export default logOfs;
