import API from '../plugins/http';
const renewal = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    refresh: false,
  },
  getters: {
    renewal: (state) => state.items,
  },
  actions: {
    async fetch({ commit }, props) {
      try {
        commit('setError', false);
        commit('setLoading', true);
        const response = await API.get(
          `user-renewal?page=${props.page}&limit=${props.limit}&status=${props.status}&q=${props.q}&expired_date=${props.expired_date}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async search({ commit }, props) {
      try {
        commit('setError', false);
        commit('setLoading', true);
        const response = await API.get(
          `user-renewal?status=${props.status}&q=${props.q}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRefresh(state, data) {
      state.refresh = data;
    },
  },
};

export default renewal;
