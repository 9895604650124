"use strict";
import API from "../plugins/http";
const menus = {
  namespaced: true,
  state: {
    menus :[],
    menu : {},
    totalRows : 0,
    isError: false,
    isLoading: false,
    message: null,
  },
  getters: {
  },

  actions: {
    async fetchMenus({ commit }) {
      try {
        commit("setLoading", true);
        const response = await API.get('menu/list');
        const menus = response?.data?.data?.rows;
        const totalRows = response?.data?.data?.count;

        commit("setMenus", menus);
        commit("setRows", totalRows);
        commit("setLoading", false);
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async fetchMenusByRole({ commit }, props) {
      try {
        commit("setLoading", true);
        const response = await API.get('menu/list', {
          params: props,
        });
        const menu = response?.data?.data?.rows[0];

        commit("setMenu", menu);
        commit("setLoading", false);
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async postMenusByRole({ commit }, props) {
      try {
        commit("setLoading", true);
        await API.post('menu', props);
        const response = await API.get('menu/list');
        const menus = response?.data?.data?.rows;
        const totalRows = response?.data?.data?.count;

        commit("setMenus", menus);
        commit("setRows", totalRows);
        commit("setLoading", false);
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async updateMenusById({ commit }, props) {
      try {
        commit("setLoading", true);
        commit("setMessage", null);
        commit("setError", false);
        await API.put(`menu/${props.id}`, props.payload);

        commit("setLoading", false);
        commit("setMessage", 'Success Updated');
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
        commit("setMessage", 'Update Failed');
      }
    },
  },

  mutations: {
    setMenus(state,data) {
      state.menus = data;
    },
    setMenu(state,data) {
      state.menu = data;
    },
    setRows(state,data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setMessage(state, data) {
      state.message = data;
    },
  },
};

export default menus;
