"use strict";
import API from "../plugins/http";
const Authentication = {
  namespaced: true,
  state: {
    auth: {},
    isAuthenticated:false,
    isError: false,
    isLoading: false,
    message: null,
    menu :[],
    group:''
  },
  getters: {
    auth: (state) => state.auth,
    isAuthenticated: (state) => state.isAuthenticated,
    isError:(state) => state.isError,
    isLoading: (state) => state.isLoading,
    menu : (state) => state.menu,
  },

  actions: {
    setLogin({ commit }, state) {
      commit('setIsAuthenticated',state.isLogin)
    },
    setAuth({ commit }, state) {
      commit('setAuth',state.auth)
    },
    async fetchMenu({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `menu/${state.role_id}}`
        );

        commit("setMenu", response.data.data.menu);
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
  },

  mutations: {
    setAuth(state, item) {
      state.auth = item;
    },
    setIsAuthenticated(state, data) {
      state.isAuthenticated = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setMessage(state, data) {
      state.message = data;
    },
    setMenu(state,data) {
      state.menu = data;
    },
    setGroup(state,data) {
      state.group = data;
    }
  },
};

export default Authentication;
