var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full rounded-md"
  }, [_c('div', {
    staticClass: "shadow-md rounded-md bg-white mr-3 pb-4 pt-4"
  }, [_c('label', {
    staticClass: "ml-5 text-base block mb-4"
  }, [_vm._v(" Transaction in Platform from " + _vm._s(this.labels_date))]), _c('apexchart', {
    attrs: {
      "type": "pie",
      "height": "335",
      "options": _vm.chartOptionsSales1,
      "series": _vm.series
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }