import API from "../plugins/http";
import Utils from '../plugins/util';

const tierCodeAndriod = {
  namespaced: true,
  state: {
    items: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    item: {},
    successMessage: '',
    errorMessage: ''
  },
  getters: {
    items: (state) => state.items,
  },

  actions: {
    async fetchAndroidTier({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `tiercode-andriod?page=${props.page}&limit=${props.limit}`
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchAndroidTierById({ commit }, id) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `tiercode-andriod/${id}`
        );
        const data = response.data.data;
        commit("setItem", data);
        commit("setLoading", false);
        return data;
      } catch (error) {
        const response = error.response;
        commit("setLoading", false);
        commit("setError", true);
        if (response.status == 404) {
          commit('setErrorMessage', 'tiercode android not found');
        } else {
          commit('setErrorMessage', 'Internal Server Error');
        }

      }
    },
    async searchAndroidTier({ commit }, props) {
      commit('setError', false);
      try {
        const q = Utils.encodeUrlWithBase64(props.q);
        const currentPage = props?.page ?? 1;
        const currentLimit = props?.limit ?? 20;
        const by = props?.by ?? 'DESC';
        const response = await API.get(
          `tiercode-andriod/search/${q}?page=${currentPage}&limit=${currentLimit}&by=${by}`
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async searchAndroidTierSpecific({ commit }, props) {
      commit('setError', false);
      try {
        const response = await API.get(
          `tiercode-andriod/search/specific/${encodeURIComponent(props.q)}`
        );
        commit('setItems', response.data.data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async postAndroidTiers({ commit }, payload) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.post(`tiercode-andriod`, payload);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success Created');
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = (response.data.errors ?? []).map(item => item.msg).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
    async updateAndroidTiers({ commit }, payload) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.put(`tiercode-andriod/${payload.id}`, payload.field);
        if (response.status == 201) {
          commit('setSuccessMessage', "Success Updated");
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = (response.data.errors ?? []).map(item => item.msg).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setItem(state, data) {
      state.item = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
  },
};

export default tierCodeAndriod;
