import API from '../plugins/http';
const vendorScoopint = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    ErrorMessage: '',
    SuccessMessage: '',
  },
  actions: {
    async findVendorScoopintByID({ commit }, props) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        commit('setLoading', true);
        const response = await API.get('/vendor-scoopint/' + props.id);
        commit('setLoading', false);
        commit('setItem', response?.data?.data);
        return response?.data?.data;
      } catch (error) {
        const response = error.response;
        if (response?.status == 422) {
          commit('setErrorMessage', response?.data?.message);
        }
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.ErrorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.SuccessMessage = data;
    },
  },
};

export default vendorScoopint;
