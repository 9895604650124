import API from '../plugins/http';
const logSubledger = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorMessage: null,
    successMessage: null,
  },
  getters: {
    logSubledger: (state) => state.items,
  },

  actions: {
    async fetchLogLedger({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setError', false);
      commit('setItems', []);

      try {
        commit('setLoading', true);
        const response = await API.get('log-ledger', {
          params: state,
        });

        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        let response = error.response;
        commit('setLoading', false);
        commit('setError', true);

        if (response.status == 422) {
          error.response.data.errors.map((e) =>
            commit('setErrorMessage', e?.msg),
          );
        } else {
          commit('setErrorMessage', error.response.data.message);
        }
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
  },
};

export default logSubledger;
