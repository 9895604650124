import API from '../plugins/http';
const ItemSoax = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    itemPremium: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    successMessage: '',
    errorMessage: '',
  },
  getters: {
    ItemSoax: (state) => state.items,
  },
  actions: {
    async fetch({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(`items-soax`, { params: props });
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchPremium({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(`items-soax/premium`, { params: props });
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchSoAxByItemsId({ commit }, props) {
      commit('setError', false);
      commit('setLoading', true);
      try {
        commit('setItem', {});
        const response = await API.get(`items-soax/items/${props.id}`);
        let row = response.data.data;
        commit('setItem', row);
        commit('setLoading', false);
        return row;
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async fetchSoAxByItemsIdPremium({ commit }, props) {
      commit('setError', false);
      commit('setLoading', true);
      try {
        commit('setItem', {});
        const response = await API.get(`items-soax/items/premium/${props.id}`);
        let row = response.data.data;
        commit('setItemPremium', row);
        commit('setLoading', false);
        return row;
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async updateSoAxByItemsId({ commit }, payload) {
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.put(
          `items-soax/items/${payload.id}`,
          payload,
        );
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success Updated');
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
        return response;
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.data.errors.map((item) => item.msg).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setLoading', false);
        return errMsg;
      }
    },
    async updateSoAxPremiumByItemsId({ commit }, payload) {
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.put(
          `items-soax/items/premium/${payload.id}`,
          payload,
        );
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success Updated');
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
        return response;
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.data.errors.map((item) => item.msg).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setLoading', false);
        return errMsg;
      }
    },
    async createSoAxByItemsId({ commit }, payload) {
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.post(
          `items-soax/items/${payload.id}`,
          payload,
        );
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success Updated');
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
        return response;
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.data.errors.map((item) => item.msg).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setLoading', false);
        return errMsg;
      }
    },

    async bulkUploadSingleIdAx({ commit }, state) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        const response = await API.post('/items-soax/bulk-upload/single', state);
        commit('setSuccessMessage', 'Item has been successfully uploaded');
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        let errMsg = [];
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
      }
    },

    async bulkUploadPremiumIdAx({ commit }, state) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        const response = await API.post('/items-soax/bulk-upload/premium', state);
        commit('setSuccessMessage', 'Item has been successfully uploaded');
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        let errMsg = [];
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);

      }
    },

    async getSingleAxById({ commit }, state) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        const response = await API.get('/items-soax/single/' + state.id);
        commit('setItem', response.data.data);
        commit('setLoading', false);
        return response;
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        if (response.status == 404) {
          commit('setErrorMessage', response.data.message);
        } else {
          commit('setErrorMessage', error.response.data.message);
        }
      }
    },

    async getPremiumAxById({ commit }, state) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        const response = await API.get('/items-soax/premium/' + state.id);
        commit('setItem', response.data.data);
        commit('setLoading', false);
        return response;
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        if (response.status == 404) {
          commit('setErrorMessage', response.data.message);
        } else {
          commit('setErrorMessage', error.response.data.message);
        }
      }
    },

    async updateSingleAx({commit}, payload){
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        const response = await API.put('/items-soax/update-single', payload)
        commit('setLoading', false)
        commit('setSuccessMessage', 'Successfully updated');
        return response
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        if (response.status == 422) {
          if (response.data.errors) {
            response.data.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data.message);
          }
        } else {
          commit('setErrorMessage', response.data.message);
        }
      }
    },

    async updatePremiumAx({commit}, payload){
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        const response = await API.put('/items-soax/update-premium', payload)
        commit('setLoading', false)
        commit('setSuccessMessage', 'Successfully updated');
        return response
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        if (response.status == 422) {
          if (response.data.errors) {
            response.data.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data.message);
          }
        } else {
          commit('setErrorMessage', response.data.message);
        }
      }
    },

    async createSingleAx({commit}, payload){
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        const response = await API.post('/items-soax/create-single', payload);
        commit('setLoading', false);
        commit('setSuccessMessage', 'Successfully created');
        return response
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        if (response.status == 422) {
          if (response.data.errors) {
            response.data.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data.message);
          }
        } else {
          commit('setErrorMessage', response.data.message);
        }
      }
    },

    async createPremiumAx({commit}, payload){
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        const response = await API.post('/items-soax/create-premium', payload);
        commit('setLoading', false);
        commit('setSuccessMessage', 'Successfully created');
        return response
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        if (response.status == 422) {
          if (response.data.errors) {
            response.data.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data.message);
          }
        } else {
          commit('setErrorMessage', response.data.message);
        }
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setItemPremium(state, item) {
      state.itemPremium = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default ItemSoax;
