const SubledgerPath = {
  path: '/subledger',
  name: 'subledger',
  component: () => import('../views/subledger/subledger.vue'),
};

const SubledgerVendorPath = {
  path: '/subledger/vendor/:vendorid',
  name: 'subledgerVendor',
  component: () => import('../views/subledger/subledgerVendor.vue'),
};
const SubledgerReadingDurationsPath = {
  path: '/reading-buffets/:trans_id/vendor/:vendor_id',
  name: 'subledgerReadingDurations',
  component: () => import('../views/subledger/reading-durations.vue'),
};

const SubledgerDetailPath = {
  path: '/subledger/detail/:trans_id',
  name: 'subledgerDetail',
  component: () => import('../views/subledger/detail-revenue.vue'),
};

const LogSubledgerPath = {
  path: '/log-subledger',
  name: 'log-subledger',
  component: () => import('../views/log-subledger/log-ledger.vue'),
};
module.exports = {
  SubledgerPath,
  SubledgerVendorPath,
  SubledgerReadingDurationsPath,
  SubledgerDetailPath,
  LogSubledgerPath,
};
