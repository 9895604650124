<template>
  <apexchart
    :type="chart"
    :height="height"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
export default {
  props: {
    chart: {
      type: String,
      default:'line',
    },
    height: {
      type: Number,
      default: 300
    },
    series: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  }
}
</script>