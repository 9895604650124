import API from '../plugins/http';
const vendor_revenue = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    itemRevenue: {},
    revenue: [],
    totalRows: 0,
    isLoading: false,
    isError: false,
    successMessage: '',
    errorMessage: '',
  },
  getters: {
    vendor_revenue: (state) => state.vendor_revenue,
  },

  actions: {
    async fetchVendorAggrement({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `vendor-revenue-share/v2?&page=${state.page}&limit=${state.limit}&status=${state.status}&vendor=${state.vendor}`,
        );

        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async search({ commit }, state) {
      try {
        commit('setError', false);
        commit('setLoading', true);
        const response = await API.get(
          `vendor-revenue-share/v2?vendor=${state.q}&status=${state.status}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async findVendorRevenue({ commit }, state) {
      try {
        const response = await API.get(
          `vendor-revenue-share/v2/search/find-name?name=${state.q}`,
        );
        commit('setItems', response.data.data.name);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },

    async findVendorRevenueByID({ commit }, state) {
      try {
        const response = await API.get(
          `vendor-revenue-share/v2/revenueId/${state.id}`,
        );
        return response;
      } catch (error) {
        commit('setError', true);
        return error;
      }
    },

    async createVendorRevenue({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await API.post('vendor-revenue-share/v2', state);
        if (response.status == 201) {
          commit(
            'setSuccessMessage',
            'Vendor Revenue Has Been Successfully Created',
          );
        }
        commit('setLoading', false);
      } catch (error) {
        let errMsg = [];
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },

    async editVendorRevenue({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await API.put(
          'vendor-revenue-share/v2/' + state.id,
          state,
        );
        if (response.status == 200) {
          commit(
            'setSuccessMessage',
            'Vendor Revenue Has Been Successfully Updated',
          );
        }
        commit('setLoading', false);
      } catch (error) {
        let errMsg = [];
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },

    async fetchVendorAggrementById({ commit }, state) {
      try {
        const response = await API.get(`vendor-revenue-share/v2/${state.id}`);
        commit('setItem', response.data.data);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setError', true);
        return error;
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setItemRevenue(state, item) {
      state.itemRevenue = item;
    },
    setRevenue(state, item) {
      state.revenue = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default vendor_revenue;
