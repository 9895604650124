import API from '../plugins/http';
const offers = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMessage: '',
    successMessage: '',
  },
  getters: {
    offers: (state) => state.items,
  },
  actions: {
    async fetchOffers({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `offers?page=${props.page}&limit=${props.limit}`
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async fetchOffersById({ commit }, id) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `offers/${id}`
        );
        const data = response.data.data;
        commit('setItem', data);
        commit('setItems', [data]);
        commit('setLoading', false);
        return data;
      } catch (error) {
        const response = error.response;
        commit('setLoading', false);
        commit('setError', true);
        if (response.status == 404) {
          commit('setErrorMessage', 'single offer not found');
        } else{
          commit('setErrorMessage', 'Internal Server Error');
        }

      }
    },
    async fetchOffersPremium({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(
          `offers-premium?page=${props.page}&limit=${props.limit}`
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async searchOffers({ commit }, props) {
      commit('setError', false);
      try {
        const response = await API.get(
          `offers?name=${props.name}`
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        return response;
      } catch (error) {
        return error;
      }
    },
    async postSingleOffers({ commit }, payload) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.post('offers', payload);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success Created');
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
    async updateSingleOffers({ commit }, payload) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await API.put(`offers/${payload.offer_id}`, payload);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Success Updated');
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = 'Error, please try again';
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default offers;
