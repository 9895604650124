import API from "../plugins/http";
const audioBook = {
  namespaced: true,
  state: {
    items: [],
    narrator: [],
    itemAudio: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    isError: false,
    refresh: false,
  },
  getters: {
  },
  actions: {
    async fetchAudioBooks({ commit }, props) {
      try {
        commit("setError", false);
        commit("setLoading", true);
        const response = await API.get(
          `items?item_type=audio book&page=${props.page}&limit=${props.limit}`
        );
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);

        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },

    async searchAudioBooks({ commit }, props) {
      try {
        const response = await API.get(
          `items-search/audio book?q=${props.query}&page=${props.page}&limit=${props.limit}`
        );
        commit("setLoading", false);
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        return response;
      } catch (error) {
        return error;
      }
    },

    async fetchAudioBookById({ commit }, props) {
      commit("setLoading", true);
      commit("setItemAudio", []);
      commit("setError", false);
      try {
        const response = await API.get(`items/${props.id}`);
        let row = response.data.data;
        let audio = row.audioBooks;
        let narrator = row.narrators.rows;
        commit("setItems", row.rows);
        commit("setItem", row.rows[0]);
        commit("setLoading", false);
        commit("setItemAudio", audio);
        commit("setNarrator", narrator);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },

    async updateStatusItems({ commit }, payload) {
      commit('setError', false);
      const maps = {};
      maps.result = false;
      maps.message = '';
      try {
        const response = await API.put(
          `audiobook/updateItems/${payload.id}`,
        );
        maps.message = response.data.data.message;
        maps.result = response.data.data.result;
      } catch (error) {
        maps.message = 'Internal Server Error When Update Items Status';
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        if (errMsg) {
          maps.message = errMsg;
        }
      }
      return maps;
    },

    async searchParents({ commit }, props) {
      commit("setLoading", false);
      try {
        const response = await API.get(
          `items-search/${props.type}?q=${props.name}&limit=${props.limit}`
        );
        return response;
      } catch (error) {
        return error;
      }
    },

    async searchChild({ commit }, props) {
      commit('setLoading', true);
      try {
        const response = await API.get(
          `items-search/parent/${props.id}`
        );
        commit("setLoading", false);
        return response;
      } catch (error) {
        commit("setLoading", false);
        return error;
      }
    },

    async searchParentsById({ commit }, props) {
      commit('setLoading', true);
      try {
        const response = await API.get(
          `items-search/getItems/${props.id}`
        );
        commit("setLoading", false);
        return response;
      } catch (error) {
        commit("setLoading", false);
        return error;
      }
    },

    async searchAuthorByItemsId({ commit }, props) {
      commit("setLoading", false);
      try {
        const response = await API.get(
          `items-search/searchAuthor/${props.id}`
        );
        return response;
      } catch (error) {
        return error;
      }
    },
    async validateChapter({ commit }, props) {
      commit("setLoading", false);
      try {
        const response = await API.get(
          `audiobook/validateChapter/${props.id}`
        );
        return response;
      } catch (error) {
        return error;
      }
    },
    async postNewAudioBook({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.post(`audiobook`, payload);
        commit('setRefresh', false);
        if (response.status == 201) {
          commit('setSuccessMessage', "Success Created");
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },

    async postNewChapter({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setRefresh', false);
      commit('setLoading', true);
      const maps = {
        result: false,
        message: '',
      };

      try {
        if (!payload.title) {
          maps.message = 'Title is Neccessary';
          return maps;
        }
        const formData = new FormData();
        formData.append('file_chapter', payload.file_chapter);
        formData.append('is_sample', payload.is_sample);
        formData.append('chapter', payload.chapter);
        formData.append('title', payload.title.trim());
        formData.append('minimum_level', payload.minimum_level);
        formData.append('duration_from', payload.time_from);
        formData.append('duration_to', payload.time_to);
        const response = await API.post(`audiobook/${payload.item_id}`, formData);
        commit('setRefresh', false);
        if (response.status == 201 || response.status == 200) {
          commit('setSuccessMessage', "Success Created");
          commit('setRefresh', true);
          maps.result = true;
          maps.message = 'Success Upload';
        }
        commit('setLoading', false);
      } catch (error) {
        console.log('this is error', error);
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        maps.result = false;
        maps.message = errMsg;
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
      return maps;
    },

    async updateChapter({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setRefresh', false);
      commit('setLoading', true);
      const maps = {};
      maps.message = '';
      maps.result = false;
      try {
        const formData = new FormData();
        if (payload.file_chapter) formData.append('file_chapter', payload.file_chapter);
        formData.append('chapter', payload.chapter);
        formData.append('title', payload.title);
        formData.append('is_sample', payload.is_sample);
        formData.append('minimum_level', payload.minimum_level);
        formData.append('duration_from', payload.time_from);
        formData.append('duration_to', payload.time_to);
        const response = await API.put(`audiobook/${payload.item_id}`, formData);
        commit('setRefresh', false);
        if (response.status == 201) {
          let messageSuccess = '';
          maps.result = true;
          if (payload.is_edit) {
            messageSuccess = 'Success Edited';
          } else {
            messageSuccess = 'Success Created';
          }
          maps.message = messageSuccess;
          commit('setSuccessMessage', messageSuccess);
          commit('setRefresh', true);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
        maps.message = errMsg;
      }
      return maps;
    },
    async getCsrfStreamAudio({ commit }, payload) {
      commit('setLoading', true);
      const maps = {};
      maps.result = false;
      try {
        const response = await API.get(`audiobook/streamUrlAudio/${payload.id}/csrfToken`);
        maps.response = response.data.data;
        if (response.status == 200 || response.status == 201) {
          maps.message = 'Success';
          maps.result = true;
        }
      }
      catch (error) {
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.data.data.message;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
        maps.message = errMsg;
      }
      commit('setLoading', false);
      return maps;
    },
    async getUrlStreamAudio({ commit }, payload) {
      commit('setLoading', false);
      try {
        let response = API.getUri({
          url: `stream-audiobook/streamUrlAudio/${payload.id}/csrfToken/${payload.csrfToken}`,
        });
        response = API.defaults.baseURL + response;
        return response;
      }
      catch (error) {
        console.log('this is error', error.message);
      }
      return null;
    },
    async streamAudio({ commit }, payload) {
      commit('setLoading', true);
      const maps = {};
      maps.result = false;
      try {
        const response = await API.get(`audiobook/streamAudio/${payload.id}/chunk`);
        maps.response = response;
        if (response.status == 200 || response.status == 201) {
          maps.message = 'Success';
          maps.result = true;
        }
      }
      catch (error) {
        const response = error.response;
        let errMsg = "Error, please try again";
        if (response.status == 404) errMsg = response.data.data.message;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
        maps.message = errMsg;
      }
      commit('setLoading', false);
      return maps;
    },
    async uploadAsset({ commit }, payload) {
      commit('setRefresh', false);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const formData = new FormData();
        formData.append('file_content', payload.file_content);
        formData.append('id', payload.id);
        const response = await API.post(`upload`, formData);
        if (response.status == 200) {
          commit('setLoading', false);
          commit('setSuccessMessage', "Success Uploaded");
          commit('setRefresh', true);
        }
      } catch (error) {
        const response = error.response;
        let errMsg = "Error, please try again";

        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setLoading', false);
        commit('setError', true);
        commit('setErrorMessage', errMsg);
      }
    },

    async updateAudioBooks({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.put(`items/${payload.item_id}`, payload);
        if (response.status == 201) {
          commit('setSuccessMessage', "Success Updated");
          commit('setRefresh', true);
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response
        let errMsg = "Error, please try again";

        if (response.status == 404) errMsg = response.statusText;
        if (response.status == 422) {
          errMsg = response.statusText;
          errMsg += ' REQUIRED ';
          errMsg += (response.data.errors ?? []).map(item => item.param).join(', ');
        }
        if (response.status == 500) {
          errMsg = response.data.data.message ?? response.statusText;
        }
        commit('setLoading', false);
        commit('setError', true);
        commit('setErrorMessage', errMsg);
        commit('setItems', null);
      }
    },

    async deleteChapter({ commit }, payload) {
      commit('setLoading', true);
      const maps = {};
      maps.result = false;
      maps.message = '';
      try {
        const response = await API.delete(`items/audiobook/${payload.item_id}/${payload.chapter}`);
        if (response.status == 200) {
          // commit('setSuccessMessage', "Success Deleted Chapter");
          maps.result = true;
          maps.message = 'Success Delete Chapter';
        } else {
          maps.message = 'Failed Response Status';
        }
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        maps.message = 'Internal Server Error';
      }
      return maps;
    }

  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRefresh(state, data) {
      state.refresh = data;
    },
    setItemAudio(state, data) {
      state.itemAudio = data;
    },
    setNarrator(state, data) {
      state.narrator = data;
    },
  },
};

export default audioBook;
