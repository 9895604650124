import API from '../plugins/http';
const financeReport = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    ErrorMessage: '',
    SuccessMessage: '',
  },
  actions: {
    async fetchFinanceReport({ commit }, props) {
      commit('setError', false);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setItems', []);
      try {
        commit('setLoading', true);
        const response = await API.get('/report-finance', { params: props });
        commit('setLoading', false);
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
        if (error.response.status == 422) {
          if (error.response.data.errors) {
            error.response.data.errors.map((e) =>
              commit('setErrorMessage', e?.msg),
            );
          }
        }
      }
    },

    async updateStatus({ commit }, payload) {
      commit('setError', false);
      try {
        return API.put('report-finance/update-status', payload);
      } catch (error) {
        commit('setError', true);
        return error;
      }
    },

    async postGenerateInvoice({ commit }, payload) {
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.post('generate-invoice/', payload);
        commit('setLoading', false);

        return response
      } catch (error) {
      commit('setLoading', false);

      return destructErrResponse(error);
      }
    }
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.ErrorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.SuccessMessage = data;
    },
  },
};

const destructErrResponse = (error) => {
  const statusCode = error.status ?? error.response?.status;
  const statusText = error.statusText ?? error.response?.statusText;
  const data = error.data ?? error.response?.data;
  let message = error.data?.message ?? error.response?.data?.message;
  
  return { statusCode, statusText, data, message};
}

export default financeReport;
