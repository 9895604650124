import API from '../plugins/http';
const BASE_URL = 'premium-vendor-dynamic/';
const vendorPremium = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    itemPremium: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    successMessage: '',
    errorMessage: '',
  },
  actions: {
    async fetchVendorPremium({ commit }, props) {
      try {
        commit('setLoading', true);
        const response = await API.get(BASE_URL, { params: props });
        commit('setItems', response.data?.data?.rows);
        commit('setRows', response.data?.data?.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async bulkUploadVendorPremium({ commit }, state) {
      commit('setLoading', true);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      try {
        const response = await API.post(BASE_URL + 'bulk-upload', state);
        const items = response?.data?.data?.stored;
        commit(
          'setSuccessMessage',
          `${items} ${items > 1 ? 'items' : 'item'} successfully uploaded.`,
        );
        commit('setLoading', false);
        return response;
      } catch (error) {
        const response = error?.response;
        if (response?.status == 422) {
          if (response?.data?.errors?.length) {
            response?.data?.errors.map((e) =>
              commit('setErrorMessage', e?.msg),
            );
          } else {
            commit('setErrorMessage', response.data?.message);
          }
        } else {
          commit('setErrorMessage', response.data?.message);
        }
        commit('setLoading', false);
        return error;
      }
    },
  },

  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setItemPremium(state, item) {
      state.itemPremium = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default vendorPremium;
