import API from "../plugins/http";
const campaign = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    setLoadingSearch: false,
    reportCampaign: [],
    totalCampaignRows: 0,
  },
  getters: {
    campaigns: (state) => state.items,
    reportcampaign: (state) => state.reportCampaign,
    totalCampaignRows: (state) => state.totalCampaignRows,
  },
  actions: {
    async fetchCampaign({ commit }, props) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `campaign?page=${props.page}&limit=${props.limit}`
        );
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async create(props) {
      return await API.post('campaign', props);
    },
    async fetchCampaignByid({ commit }, props) {
      try {
        commit("setLoading", true);
        const response = await API.get(`campaign/${props.id}`);
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async searchCampaign({ commit }, props) {
      commit("setLoading", true);
      try {
        const q = encodeURI(props.q);
        const response = await API.get(`search-campaign?q=${q}`);
        commit("setItems", response.data?.data?.rows);
        commit("setRows", response.data?.data?.count);
        commit("setLoading", false);
        return response;
      } catch (error) {
        commit("setLoading", false);
        return error;
      }
    },
    async actionGetReportCampaign({ commit }, props) {
      commit("setLoading", true);
      try {
        commit("setReportCampaign", []);
        const response = await API.get(`report-campaign/${props.id}?page=${props.page}&limit=${props.limit}`);
        commit("setReportCampaign", response.data.data.rows);
        commit("setReportCampaignRow", response.data.data.count);
        commit("setLoading", false);
        return response;
      } catch (error) {
        commit("setLoading", false);
        return error;
      }
    },
    async actionSendReportCampaign({ commit }, payload) {
      commit("setLoading", true);
      try {
        const response = await API.post(`report-campaign/${payload.id}/send`, payload);
        commit("setLoading", false);
        return response;
      } catch (error) {
        const response = error.response;
        commit("setLoading", false);
        return response;
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setLoadingSearch(state, data) {
      state.isLoadingSearch = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setReportCampaign(state, data) {
      state.reportCampaign = data;
    },
    setReportCampaignRow(state, data) {
      state.totalCampaignRows = data;
    },
  },
};

export default campaign;
