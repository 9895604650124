"use strict";
import API from "../plugins/http";
const forgetPassword = {
  namespaced: true,
  state: {
    auth: [],
    isSuccess:false,
    isLoading: false,
    statusCode: null,
    successMessage : null,
    item_id :[],
    isPage:false,
    isErrorPage:false,
    token : null,
    isError:false,
},getters: {
    auth: (state) => state.auth,
},actions: {
  // for forgot password
  async forgetPasswordAdapter({ commit }, state) {
    commit('setLoading', true);
    commit('setSuccessMessage', null);
    commit('setToken',null);
    commit('setSuccess',false);
    commit('setError',false);
    try {
      const response = await API.post("forgetPassword/forget", state);
      let data=response.data.data
      if(data.length>0){
        if(data[0].result==true){
          commit("setSuccess",true);
          commit("setLoading",false);
          commit("setSuccessMessage",data[0].message);
          commit("setToken",data[0].token)
        }else{
          commit("setSuccess",false);
          commit("setSuccessMessage",data[0].message);
          commit("setLoading",false);
          commit("setToken",null);
          commit('setError',true);
        }
      }else{
        commit("setSuccess",false);
        commit("setSuccessMessage","No Account Exist");
        commit("setLoading",false);
        commit("setToken",null);
        commit('setError',true);
      }
    } catch (error) {
      const statusCode = error.status ?? error.response?.status;
      let message = error.data?.message ?? error.response?.data?.message;
      if (statusCode == 422) {
        const attr = error.response?.data.data?.map(val => val.param).join(' ,');
        message += ` (${attr})`;
      }
      if (statusCode == 404) {
        message = error.response?.data.data?.message ?? message;
      }
      if (statusCode == 409) {
        message = error.response?.data.data?.message ?? message;
      }
      commit("setSuccess",false);
      commit('setError',true);
      commit("setSuccessMessage",'Error System: '+ message);
      commit("setLoading",false);
      commit("setToken",null);
    }
  },async checkToken({ commit }, state) {
    commit('setLoading', true);
    commit('setSuccessMessage', null);
    commit('setToken',null);
    commit('setErrorPage',false);
    commit('setPage',false);
    try {
      const response = await API.get(`forgetPassword/checkToken/${state.data}`);
      let data=response.data.data
      if(data.length>0){
        if(data[0].result==true){
          commit("setLoading",false);
          commit("setToken",data[0].token)
          commit('setPage',true);
        }else{
          commit("setSuccessMessage",data[0].message);
          commit("setLoading",false);
          commit("setToken",null);
          commit('setErrorPage',true);
          commit('setPage',false);
        }
      }else{
        commit("setSuccess",false);
        commit('setErrorPage',true);
        commit("setSuccessMessage","Link Not Found");
        commit("setLoading",false);
        commit("setToken",null);
        commit('setPage',false);
      }
    } catch (error) {
      const statusCode = error.status ?? error.response?.status;
      let message = error.data?.message ?? error.response?.data?.message;
      if (statusCode == 422) {
        const attr = error.response?.data.data?.map(val => val.param).join(' ,');
        message += ` (${attr})`;
      }
      if (statusCode == 404) {
        message = error.response?.data.data?.message ?? message;
      }
      if (statusCode == 409) {
        message = error.response?.data.data?.message ?? message;
      }
      commit("setSuccess",false);
      commit('setErrorPage',true);
      commit("setSuccessMessage",'Error System: '+ message);
      commit("setLoading",false);
      commit("setToken",null);
      commit('setPage',false);
    }
  },
  // for reset password
  async resetPassword({ commit }, state) {
    try {
      commit('setLoading', true);
      commit('setSuccessMessage', null);
      commit('setToken',null);
      commit('setSuccess',false);
      commit('setError',false);
      const response = await API.post("forgetPassword/reset", state);
      let data=response.data.data
      if(data.length>0){
        if(data[0].result==true){
          commit("setSuccess",true);
          commit("setLoading",false);
          commit("setSuccessMessage",data[0].message);
          commit("setToken",data[0].token)
        }else{
          commit("setSuccess",false);
          commit("setSuccessMessage",data[0].message);
          commit("setLoading",false);
          commit('setError',true);
        }
      }else{
        commit("setSuccess",false);
        commit("setSuccessMessage","Reset Password Failed");
        commit("setLoading",false);
        commit('setError',true);
      }
    } catch (error) {
      const statusCode = error.status ?? error.response?.status;
      let message = error.data?.message ?? error.response?.data?.message;
      if (statusCode == 422) {
        const attr = error.response?.data.data?.map(val => val.param).join(' ,');
        message += ` (${attr})`;
      }
      if (statusCode == 404) {
        message = error.response?.data.data?.message ?? message;
      }
      if (statusCode == 409) {
        message = error.response?.data.data?.message ?? message;
      }
      commit("setSuccess",false);
      commit("setSuccessMessage",'Error System'+ message);
      commit("setLoading",false);
      commit('setError',true);
      
    }
  },
},mutations: {
    setItem_id(state,data){
      state.item_id = data;
    },setSuccessMessage(state, data) {
      state.successMessage = data;
    },setItems(state, item) {
      state.items = item;
    },setLoading(state, data) {
      state.isLoading = data;
    },setSuccess(state, data) {
      state.isSuccess = data;
    },setStatusCode(state, data) {
      state.statusCode = data;
    },setToken(state,data){
      state.token = data;
    },setError(state, data) {
      state.isError = data;
    },setPage(state, data) {
      state.isPage = data;
    },setErrorPage(state, data) {
      state.isErrorPage = data;
    }



  },
};

export default forgetPassword;
