'use strict';
import API from '../plugins/http';
const login = {
  namespaced: true,
  state: {
    auth: [],
    isSuccess: false,
    isLoading: false,
    statusCode: null,
    successMessage: null,
    item_id: [],
    isPage: false,
    isErrorPage: false,
    token: null,
    isError: false,
    isKey: false,
    key: null,
    qrCode: null,
  },
  getters: {
    auth: (state) => state.auth,
  },
  actions: {
    // for login action
    async loginAction({ commit }, state) {
      const maps = {};
      commit('setLoading', true);
      commit('setSuccess', false);
      commit('setError', false);
      try {
        const response = await API.post('login/action', state);
        maps.message =response.data.data.message;
        maps.resetpassword = response.data.data.resetpassword;
        maps.twoFactor = response.data.data.twoFactor;
        maps.data = response.data.data.data;
        maps.result = response.data.data.result;
        maps.token = response.data.data.token;
        maps.refresh_token = response.data.data.refresh_token;
        maps.username = response.data.data.username;
       
      } catch (error) {
        
        let message = error.response.data.message.message;
        if (!message) {
          message = 'Username Or Password Incorrect';
        }
        
        commit('setSuccess', false);
        commit('setError', true);
        commit('setSuccessMessage', message);
        commit('setLoading', false);
        maps.result = false;
        maps.message = message;
      }
      return maps;
    },
    async validateJWT({ commit }, state) {
      commit('setLoading', true);
      commit('setSuccessMessage', null);
      commit('setToken', null);
      commit('setErrorPage', false);
      commit('setPage', false);
      commit('setKey', null);
      commit('setIsKey', false);
      let data = null;
      let token = null;
      let message = 'Error';
      let isSecretKey = false;
      let secretKey = null;
      let isErrorPage = false;
      let success = false;
      try {
        const response = await API.get(`login/validateJWT/${state.token}`);
        data = response.data.data;
        if (data.length > 0) {
          token = data[0].token;
          isSecretKey = data[0].isSecretKey;
          secretKey = data[0].secretKey;
          isErrorPage = data[0].isErrorPage;
          success = data[0].result;
          message = data[0].message;
          commit('setLoading', false);
          commit('setToken', token);
          commit('setSuccessMessage', message);
          commit('setIsKey', isSecretKey);
          commit('setKey', secretKey);
          commit('setPage', isErrorPage);
          // success massage null to only display doesnt need popup
        } else {
          commit('setSuccess', false);
          commit('setError', true);
          commit('setSuccessMessage', 'Link Not Found');
          commit('setLoading', false);
          commit('setToken', null);
          commit('setPage', false);
          commit('setErrorPage', true);
        }
      } catch (error) {
        data = error.response.data.message[0];
        token = data.token;
        message = data.message;
        isSecretKey = data.isSecretKey;
        secretKey = data.secretKey;
        isErrorPage = data.isErrorPage;
        success = data.result;
        if (!message) {
          message = 'Error Validation';
        }
        commit('setLoading', false);
        commit('setToken', token);
        commit('setSuccessMessage', message);
        commit('setIsKey', isSecretKey);
        commit('setKey', secretKey);
        commit('setErrorPage', isErrorPage);
        commit('setSuccess', success);
      }
    },
    async generateSecretKey({ commit }, state) {
      commit('setLoading', true);
      commit('setSuccessMessage', null);
      commit('setToken', null);
      commit('setPage', true);
      commit('setKey', null);
      commit('setQrCode', null);
      let  data =null;
      let token = null;
      let message = null;
      let secretKey = null;
      let page = null;
      let qrCode = null;


      try {
        const response = await API.get(
          `login/generateSecretKey/${state.token}`,
        );
        data = response.data.data;
        if (data.length > 0) {
          token = data[0].token;
          message = data[0].message;
          secretKey = data[0].secretKey;
          page = data[0].page;
          qrCode = data[0].qrCode;
          commit('setLoading', false);
          commit('setToken',token);
          commit('setSuccessMessage', message);
          commit('setKey', secretKey);
          commit('setPage', page);
          commit('setQrCode', qrCode);
          
        } else {
          commit('setSuccess', false);
          commit('setError', true);
          commit('setSuccessMessage', 'Link Not Found');
          commit('setLoading', false);
          commit('setToken', null);
          commit('setPage', data[0].page);
        }
      } catch (error) {
        data = error.response.data.message[0];
        token = data.token;
        message = data.message;
        secretKey = data.secretKey;
        page = data.page;
        qrCode = data.qrCode;
        if (!message){
          message = 'Error Unknown';
        }
        commit('setLoading', false);
        commit('setToken',token);
        commit('setSuccessMessage', message);
        commit('setKey', secretKey);
        commit('setPage', page);
        commit('setQrCode', qrCode);
        
      }
    },
    async saveSecretKey({ commit }, state) {
      commit('setLoading', true);
      commit('setToken', null);
      commit('setSuccess', false);
      commit('setError', false);
      commit('setSuccessMessage', null);
      commit('setPage', true);
      let data = null;
      let result = null;
      let message = null;
      let page = null;

      try {
        const response = await API.post('login/saveSecretKey', state);
        data = response.data.data;
        if (data.length > 0) {
          result = data[0].result
          message = data[0].message;
          page = data[0].page;
          commit('setSuccess',result);
          commit('setLoading', false);
          commit('setSuccessMessage', message);
          commit('setPage', page);
          commit('setError', !result);
        } else {
          commit('setSuccess', false);
          commit('setSuccessMessage', 'No Account Exist');
          commit('setLoading', false);
          commit('setError', true);
          commit('setPage', data[0].page);
        }
      } catch (error) {
        data = error.response.data.message[0];
        message = data.message;
        if (!message){
          message = 'Error Unknown';
        }
        page = data.page;
        result = data.result;
        commit('setSuccess',result);
        commit('setLoading', false);
        commit('setSuccessMessage', message);
        commit('setPage', page);
        commit('setError', !result);


      }
    },
    async verify2Factor({ commit }, state) {
      commit('setLoading', true);
      commit('setToken', null);
      commit('setSuccess', false);
      commit('setError', false);
      commit('setSuccessMessage', null);
      try {
        const response = await API.post('login/verify2Factor', state);
        return response;
      } catch (error) {
        let message = error.response.data.message[0].message;
        if (!message) {
          message = 'OTP Failed';
        }
        commit('setSuccess', false);
        commit('setError', true);
        commit('setSuccessMessage', message);
        commit('setLoading', false);
        commit('setToken', null);
      }
    },
  },
  mutations: {
    setItem_id(state, data) {
      state.item_id = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setItems(state, item) {
      state.items = item;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setSuccess(state, data) {
      state.isSuccess = data;
    },
    setStatusCode(state, data) {
      state.statusCode = data;
    },
    setToken(state, data) {
      state.token = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setPage(state, data) {
      state.isPage = data;
    },
    setErrorPage(state, data) {
      state.isErrorPage = data;
    },
    setIsKey(state, data) {
      state.isKey = data;
    },
    setKey(state, data) {
      state.key = data;
    },
    setQrCode(state, data) {
      state.qrCode = data;
    },
  },
};

export default login;
