const userAdmin = {
  path: '/user-admin',
  name: 'user-admin',
  component: () => import('../views/users/user-admin.vue'),
};
const createUserAdmin = {
  path: '/user-admin/create',
  name: 'user-admin-create',
  component: () => import('../views/users/user-admin-create.vue'),
};
const editUserAdmin = {
  path: '/user-admin/edit/:id',
  name: 'user-admin-edit',
  component: () => import('../views/users/user-admin-edit.vue'),
};
const displayUserAdmin = {
  path: '/user-admin/display/:id',
  name: 'user-admin-display',
  component: () => import('../views/users/user-admin-display.vue'),
};

module.exports = {
  userAdmin,
  createUserAdmin,
  editUserAdmin,
  displayUserAdmin,
};
