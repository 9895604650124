import moment from "moment";
import Vue from "vue";
import { EventBus } from '../event-bus';
Vue.mixin({
  methods: {
    copyTextToClipboard: function (val) {
      navigator.clipboard.writeText(val);
      EventBus.$emit('show-toast', {
        message: 'Copied success',
        type: 'success',
      });
    },
    datetime24H: function (val, timezone = false) {
      let formatDate = 'YYYY-MM-DD HH:mm';
      formatDate = timezone ? formatDate + ' Z' : formatDate;
      return moment(val).format(formatDate);
    },
    datetime12H: function (val, timezone = false) {
      let formatDate = 'YYYY-MM-DD hh:mm A';
      formatDate = timezone ? formatDate + ' Z' : formatDate;
      return moment(val).format(formatDate);
    },
    dateNowString: function() {
      return moment().toString();
    },
    timezoneString: function() {
      return moment().format('Z');
    },
    alertTopEnd(icon, title, showConfirmButton = true, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        timer: !showConfirmButton ? timer : false,
        showConfirmButton,
        timerProgressBar: !showConfirmButton,
      });
    },
  },
})
