import API from "../plugins/http";
import util from "../plugins/util";
import constant from "./constant";
const ebooks = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    itemAudio :[],
    totalRows: 0,
    isLoading: false,
    errorMessage: '',
    successMessage: '',
    isError: false,
    refresh: false,
  },
  getters: {
    ebooks: (state) => state.items,
    ebooksSales: (state) => {
      return state.items.filter((i) => i.is_internal_content === false);
    },
    ebooksNonSales: (state) => {
      return state.items.filter((i) => i.is_internal_content === true);
    },
  },
  actions: {
    async fetchEbooks({ commit }, props) {
      try {
        commit('setError', false);
        commit("setLoading", true);
        const response = await API.get(
          `items`,
          {
            params: {
              item_type: 'book',
              ...props
            }
          }
        );
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async fetchEbookById({ commit }, props) {
      commit('setError', false);
      commit("setItemAudio", null);
      try {
        commit("setItem", []);
        const response = await API.get(`items/${props.id}`);
        let row = response.data.data;
        commit("setItemAudio", row.audioBooks);
        commit("setItems", row.rows);
        commit("setItem", row.rows[0]);
        commit("setLoading", false);
        return row
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async fetchEbookAndOffersById({ commit }, props) {
      try {
        commit("setItem", []);
        const response = await API.get(`items/${props.id}`);

        let row = response.data.data;
        commit("setItems", row.rows);
        commit("setItem", row.rows[0]);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async searchBooks({ commit }, props) {
      try {
        commit('setError', false);
        commit("setLoading", true);
        const response = await API.get(
          `items-search/book`, {
          params: props,
        }
        );
        commit("setItems", response.data.data.rows);
        commit("setRows", response.data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setLoading", false);
        commit("setError", true);
      }
    },

    async postNewBooks({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        if (payload.vendor_id) {
          const searchBrands = await API.get(`brands`, {
            params: { name: payload.name, vendor_id: payload.vendor_id }
          });
          let brand = searchBrands.data?.data.rows
            .find(val => {
              const checkName = (val.name.toLowerCase() == payload.name.toLowerCase());
              const checkVendor = (val.vendor_id == payload.vendor_id);
              return checkName && checkVendor
            });

          if (!brand?.id) {
            const newBrand = await API.post(`brands`, {
              name: payload.name,
              brand_code: 'BLK_' + util.getFirstLetters(payload.name),
              vendor_id: payload.vendor_id,
              is_active: true,
              slug: util.toSlug(payload.name) + (+new Date()),
              default_item_type: 'book',
              default_item_schedule: constant.BRAND_TYPE.EDITION,
              release_period: constant.BRAND_RELEASE_PERIOD.DAILY,
              default_languages: payload.languages,
              default_countries: payload.countries,
              offers_premium: [],
            });
            brand = newBrand.data?.data;
          }
          payload.brand_id = payload.brand_id ?? brand.id;
        }

        const response = await API.post(`items/book`, payload);
        commit('setRefresh', false);
        if (response.status == 201) {
          commit('setSuccessMessage', "Success Created");
          commit('setItem', response.data?.data?.rows[0]);
        }
        commit('setLoading', false);
      } catch (error) {
        const {
          statusCode,
          statusText,
          message,
          data,
        } = destructErrResponse(error);
        let customMessage = data?.data?.message;

        if (statusCode == 422) {
          customMessage = statusText;
          customMessage += ' REQUIRED ';
          customMessage += (data.errors ?? []).map(item => item.param).join(', ');
        }

        commit('setError', true);
        commit('setErrorMessage', customMessage ?? message);
        commit('setLoading', false);
      }
    },

    async uploadAsset({ commit }, payload) {
      commit('setRefresh', false);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const formData = new FormData();
        formData.append('file_content', payload.file_content);
        formData.append('id', payload.id);
        formData.append('item_status', payload.item_status);
        formData.append('release_schedule', payload.release_schedule);
        const response = await API.post(`upload`, formData);
        if (response.status == 200) {
          commit('setLoading', false);
          commit('setSuccessMessage', "Success Uploaded");
          commit('setRefresh', true);
        }
      } catch (error) {
        const {
          statusCode,
          statusText,
          message,
          data,
        } = destructErrResponse(error);
        let customMessage = data?.data?.message;

        if (statusCode == 422) {
          customMessage = statusText;
          customMessage += ' REQUIRED ';
          customMessage += (data.errors ?? []).map(item => item.param).join(', ');
        }

        commit('setError', true);
        commit('setErrorMessage', customMessage ?? message);
        commit('setLoading', false);
      }
    },

    async updateBooks({ commit }, payload) {
      commit('setRefresh', false);
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      commit('setLoading', true);
      try {
        const response = await API.put(`items/${payload.item_id}`, payload);
        if (response.status == 201) {
          commit('setSuccessMessage', "Success Updated");
          commit('setRefresh', true);
        }
        commit('setLoading', false);
      } catch (error) {
        const {
          statusCode,
          statusText,
          message,
          data,
        } = destructErrResponse(error);
        let customMessage = data?.data?.message;

        if (statusCode == 422) {
          customMessage = statusText;
          customMessage += ' REQUIRED ';
          customMessage += (data.errors ?? []).map(item => item.param).join(', ');
        }

        commit('setError', true);
        commit('setErrorMessage', customMessage ?? message);
        commit('setLoading', false);
      }
    },

    async searchCategories({ commit }, query) {
      commit('setLoading', false);
      try {
        const response = await API.get(`categories?name=${query}`);
        return response
      } catch (error) {
        return error
      }
    },

    async getCountries({ commit }) {
      commit('setLoading', false);
      try {
        const response = await API.get(`countries`);
        return response
      } catch (error) {
        return error
      }
    },

    async getParentalControl({ commit }, payload) {
      commit('setLoading', false);
      return API.get('items/parental-control/' + payload);
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRefresh(state, data) {
      state.refresh = data;
    },
    setItemAudio(state, data) {
      state.itemAudio = data;
    }
  },
};

export default ebooks;

const destructErrResponse = (error) => {
  const statusCode = error.status ?? error.response?.status;
  const statusText = error.statusText ?? error.response?.statusText;
  const data = error.data ?? error.response?.data;
  let message = error.data?.message ?? error.response?.data?.message;

  return { statusCode, statusText, data, message };
}
