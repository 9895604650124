import API from '../plugins/http';
import Utils from '../plugins/util';

const InvalidTiercodeOffer = {
  namespaced: true,
  state: {
    totalRows: 0,
    isLoading: false,
    isError: false,
    items: [],
    item: {},
    errorMessage: '',
    successMessage: '',
  },
  getters: {
  },
  actions: {
    async fetchInvalidTiercodeOffer({ commit }, props) {
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await API.get(
          `invalid-tiercode-offers/${props.platform_id}/?page=${props.page}&limit=${props.limit}`
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
    async searchInvalidTiercodeOffer({ commit }, props) {
      commit('setError', false);
      try {
        const q = Utils.encodeUrlWithBase64(props.q);
        const currentPage = props?.page ?? 1;
        const currentLimit = props?.limit ?? 100;
        const by = props?.by ?? 'DESC';
        const response = await API.get(
          `invalid-tiercode-offers/search/${props.platform_id}/${q}?page=${currentPage}&limit=${currentLimit}&by=${by}`
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
      }
    },
  },
  mutations: {
    setItems(state, data) {
      state.items = data;
    },
    setItem(state, data) {
      state.item = data;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
  },
};

export default InvalidTiercodeOffer;
