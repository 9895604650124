"use strict";
import API from "../plugins/http";
const CorporateDashboard = {
  namespaced: true,
  state: {
    items:[],
    popularItems:[],
    isError: false,
    isLoading: false,
    message: null,
    totalRows: 0,
  },
  getters: {
    items: (state) => state.items,
    popularItems: (state) => state.popularItems,
    isError:(state) => state.isError,
    isLoading: (state) => state.isLoading,
  },

  actions: {
    async fetchItems({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `v2/corporate-dashboard/campaign/${state.id}?from=${state.from}&to=${state.to}`
        );
        let data = response.data.data
        
        commit("setItem", data.data.rows);
        commit("setRows", data.data.count);
        commit("setLoading", false);
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
    async fetchPopularItems({ commit }, state) {
      try {
        commit("setLoading", true);
        const response = await API.get(
          `v2/corporate-dashboard/popular-reading/campaign/${state.id}?from=${state.from}&to=${state.to}`
        );

        commit("setPopulars", response.data.data.rows);
        commit("setLoading", false);
      } catch (error) {
        commit("setRows", 0);
        commit("setLoading", false);
        commit("setError", true);
      }
    },
  },

  mutations: {
    setItem(state, item) {
      state.items = item;
    },
    setPopulars(state, data) {
      state.popularItems = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setMessage(state, data) {
      state.message = data;
    },
    setRows(state, data) {
      state.totalRows = data;
    }
  },
};

export default CorporateDashboard;
