const Organization = {
  path: '/organitation',
  name: 'organizations',
  component: () => import('../views/organizations/organization.vue'),
};

const createOrganization = {
  path: '/organitation/create',
  name: 'organizations-create',
  component: () => import('../views/organizations/createOrganization.vue'),
};

const EditOrganization = {
  path: '/organitation/edit/:id',
  name: 'organizations-edit',
  component: () => import('../views/organizations/editOrganization.vue'),
};

module.exports = {
  Organization,
  createOrganization,
  EditOrganization,
};
